import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, forkJoin, of, throwError } from 'rxjs';
import { finalize, map, switchMap, catchError } from 'rxjs/operators';
import { AppSelectOption } from '../../../../../../../_base-shared/contracts/common.interface';
import { LaravelResourceResponse } from '../../../../../../../_base-shared/contracts/laravel-response.interface';
import { DistributionBatch } from '../../../../../../../_base-shared/models/Distribution/DistributionBatch';
import { DistributionProvider } from '../../../../../../../_base-shared/models/Distribution/DistributionProvider';
import { DistributionBatchService } from '../../distribution-batch.service';
import { DistributionProviderService } from '../../distribution-provider.service';
import { Creditor } from '../../../../../../../_base-shared/models/Entity/Creditor';
import { CreditorService } from '../../../creditor/creditor.service';
import { Product } from '../../../../../../../_base-shared/models/Product';
import { ProductService } from '../../../case/product.service';
import { User } from '../../../../../../../_base-shared/models/User/User';
import { UserService } from '../../../user/user.service';
import { PaymentProcessorService } from '../../../payment/payment-processor.service';
import { PaymentProcessor } from '../../../../../../../_base-shared/models/Payment/PaymentProcessor';


@Component({
  selector:    'app-distribution-batch-editor',
  templateUrl: './distribution-batch-editor.component.html',
  styles:      [],
})
export class DistributionBatchEditorComponent implements OnInit {
  public editorType: 'create' | 'edit';
  public isLoading = 0;
  public isSubmitting: boolean;
  public distributionBatch: DistributionBatch;
  public distributionProviders: Array<DistributionProvider>;
  public batchStatusOptions: Array<AppSelectOption> = [];
  public serverResponse: LaravelResourceResponse;
  public form: UntypedFormGroup;

  public batches: DistributionBatch[] = [];
  public creditors: Array<Creditor>         = [];
  public recoveryCreditors: Array<Creditor> = [];
  public products: Array<Product>  
  public distributionProvider: DistributionProvider;
  public distributionUsers: Array<User>     = [];
  public paymentProcessors: Array<PaymentProcessor> = [];
  public missId: String;
  //public filteredCreditorName: String;
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
    private toastr: ToastrService,
    private distributionBatchService: DistributionBatchService,
    private distributionProviderService: DistributionProviderService,
    private creditorService: CreditorService,
    private productService: ProductService,
    private paymentProcessorService: PaymentProcessorService,
    private userService: UserService,
  ) {
  }


  /*ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.editorType = data.editorType;
      this.buildSelectOptions();
      const distributionProviders$ = this.fetchDistributionProviders();
      this.fetchProducts();
      this.fetchDistributionPaymentProcessors();
      this.fetchDistributionUsers();

      if (this.editorType === 'edit') {
        this.route.paramMap.subscribe(params => {
          const batchId = +params.get('id');
    
          interface CombinedData {
            batchData: DistributionBatch;
            providerData: DistributionProvider;
          }
            
          const batchObservable = this.fetchDistributionBatch(batchId);
          
          batchObservable.pipe(
            switchMap(batchData => {
              const current_provider = this.distributionProviders.find(item => {
                return item.company_name === batchData.name;
              });
              if (current_provider) {
                return this.fetchDistributionProvider(current_provider.id).pipe(
                  map(providerData => ({ batchData, providerData } as CombinedData))
                );
              } else {
                // Handle case where no matching provider is found
                return throwError('No matching provider found');
              }
            })
          ).subscribe(
            ({ batchData, providerData }: CombinedData) => {
              console.log('Batch:', batchData);
              console.log('Provider:', providerData);
              this.distributionProvider = providerData;
              this.distributionBatch = batchData;
              this.buildMergedForm(batchData, providerData);
            },
            error => {
              // Handle error
              console.error('Error fetching data', error);
              this.toastr.error(this.translate.instant('SHARED.went-wrong'));
            }
          );
        });
      } else {
        //this.buildFormBatch(new DistributionBatch());
        //this.buildFormProvider(new DistributionProvider());
        this.buildMergedForm(new DistributionBatch(), new DistributionProvider());
      }
    });

    //this.fetchBatches();
    this.route.queryParams.subscribe(params => {
      this.missId = params['miss-id'];
      this.fetchCreditors();
    });
  }*/

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.editorType = data.editorType;
      this.buildSelectOptions();
  
      forkJoin({
        products: this.fetchProducts(),
        paymentProcessors: this.fetchDistributionPaymentProcessors(),
        distributionUsers: this.fetchDistributionUsers(),
        distributionProviders: this.fetchDistributionProviders()
      }).subscribe(
        ({ products, paymentProcessors, distributionUsers, distributionProviders }) => {
          this.products = products;
          this.paymentProcessors = paymentProcessors;
          this.distributionUsers = distributionUsers;
          this.distributionProviders = distributionProviders;
  
          if (this.editorType === 'edit') {
            this.route.paramMap.subscribe(params => {
              const batchId = +params.get('id');
  
              interface CombinedData {
                batchData: DistributionBatch;
                providerData: DistributionProvider;
              }
  
              const batchObservable = this.fetchDistributionBatch(batchId);
  
              // Once providers are fetched, handle batch fetching
              batchObservable.pipe(
                switchMap(batchData => {
                  const current_provider = this.distributionProviders.find(item => item.company_name === batchData.name);
  
                  if (current_provider) {
                    return this.fetchDistributionProvider(current_provider.id).pipe(
                      map(providerData => ({ batchData, providerData } as CombinedData))
                    );
                  } else {
                    // Handle case where no matching provider is found
                    return throwError('No matching provider found');
                  }
                })
              ).subscribe(
                ({ batchData, providerData }: CombinedData) => {
                  console.log('Batch:', batchData);
                  console.log('Provider:', providerData);
                  this.distributionProvider = providerData;
                  this.distributionBatch = batchData;
                  this.buildMergedForm(batchData, providerData);
                  this.fetchCreditors();
                },
                error => {
                  console.error('Error fetching data', error);
                  this.toastr.error(this.translate.instant('SHARED.went-wrong'));
                }
              );
            });
          } else {
            this.buildMergedForm(new DistributionBatch(), new DistributionProvider());
            this.fetchCreditors();
          }
        },
        error => {
          console.error('Error fetching initial data', error);
        }
      );
    });
  
    this.route.queryParams.subscribe(params => {
      this.missId = params['miss-id'];
      //this.fetchCreditors();
    });
  }

  public submitFormBatch(form: UntypedFormGroup) {
    this.serverResponse = null;
    if (form.invalid) {
      form.markAllAsTouched();
      return;
    }

    let formObserver: Observable<LaravelResourceResponse<DistributionBatch>>;
    if (this.editorType === 'create') {
      formObserver = this.distributionBatchService.store(form.value);
    } else {
      formObserver = this.distributionBatchService.update(this.distributionBatch.id, form.value);
    }

    this.isSubmitting = true;
    formObserver.pipe(finalize(() => this.isSubmitting = false)).subscribe(
      result => {
        this.editorType === 'create' ?
          this.toastr.success(this.translate.instant('SHARED.submit_result.create.success',
            { model: this.translate.instant('DISTRIBUTION.batch.model_name.singular') })) :
          this.toastr.success(this.translate.instant('SHARED.submit_result.update.success',
            { model: this.translate.instant('DISTRIBUTION.batch.model_name.singular') }));
        this.router.navigate(['/distribution', 'batches']);
      },
      error => {
        this.serverResponse = error.error;
        this.editorType === 'create' ?
          this.toastr.error(this.translate.instant('SHARED.submit_result.create.error',
            { model: this.translate.instant('DISTRIBUTION.batch.model_name.singular') })) :
          this.toastr.error(this.translate.instant('SHARED.submit_result.update.error',
            { model: this.translate.instant('DISTRIBUTION.batch.model_name.singular') }));
      },
    );
  }

  public submitFormProvider(form: UntypedFormGroup) {
    this.serverResponse = null;
    if (form.invalid) {
      form.markAllAsTouched();
      return;
    }

    let formObserver: Observable<LaravelResourceResponse<DistributionProvider>>;
    if (this.editorType === 'create') {
      formObserver = this.distributionProviderService.store(form.value);
    } else {
      formObserver = this.distributionProviderService.update(this.distributionProvider.id, form.value);
    }

    this.isSubmitting = true;
    formObserver.pipe(finalize(() => this.isSubmitting = false)).subscribe(
        result => {
          this.editorType === 'create' ?
              this.toastr.success(this.translate.instant('SHARED.submit_result.create.success',
                  {model: this.translate.instant('DISTRIBUTION.provider.model_name.singular')})) :
              this.toastr.success(this.translate.instant('SHARED.submit_result.update.success',
                  {model: this.translate.instant('DISTRIBUTION.provider.model_name.singular')}));
          this.router.navigate(['/distribution', 'providers']);
        },
        error => {
          this.serverResponse = error.error;
          this.editorType === 'create' ?
              this.toastr.error(this.translate.instant('SHARED.submit_result.create.error',
                  {model: this.translate.instant('DISTRIBUTION.provider.model_name.singular')})) :
              this.toastr.error(this.translate.instant('SHARED.submit_result.update.error',
                  {model: this.translate.instant('DISTRIBUTION.provider.model_name.singular')}));
        },
    );
  }


  private logFormErrors(form: UntypedFormGroup): void {
    Object.keys(form.controls).forEach(key => {
        const controlErrors = form.get(key)?.errors;
        if (controlErrors) {
            console.log(`Control: ${key}, Errors:`, controlErrors);
        }
    });
  }


  public submitForm(form: UntypedFormGroup) {
    console.log("submit form");
    this.serverResponse = null;
    if (form.invalid) {
        form.markAllAsTouched();
        this.logFormErrors(form); // Log detailed form errors
        return;
    }
    console.log("submit form2");

    // Extract batch and provider data from the form
    
    let batchData: any = {
      name: form.value.name,
      distribution_provider_id: form.value.distribution_provider_id,
      status: form.value.status,
      total_funded_amount: form.value.total_funded_amount,
      total_batch_contract_amount: form.value.total_batch_contract_amount // Add the missing property
    };
    
    const providerData: any = {
        payment_processor_id: form.value.payment_processor_id,
        product_ids: form.value.product_ids,
        user_ids: form.value.user_ids,
        company_name: form.value.name,
        min_contract_value: form.value.min_contract_value,
        min_monthly_payment: form.value.min_monthly_payment,
        max_installments: form.value.max_installments,
        funding_amount_percentage: form.value.funding_amount_percentage,
        cash_hurdle_percentage: form.value.cash_hurdle_percentage,
        retention_percentage: form.value.retention_percentage,
        client_account_iban: form.value.client_account_iban,
        payment_iban: form.value.payment_iban,
        address: form.value.address,
        contact_person: form.value.contact_person,
        email: form.value.email,
        contact_email: form.value.contact_email,
        phone: form.value.phone
    };

    this.isSubmitting = true;

    if (this.editorType === 'create') {
      this.distributionProviderService.store(providerData).pipe(
        switchMap((providerResponse: LaravelResourceResponse<DistributionProvider>) => {
            // After provider service completes, call batch service
            console.log(providerResponse.data.id);

            batchData = {
              name: form.value.name,
              distribution_provider_id: providerResponse.data.id,
              status: form.value.status,
              total_funded_amount: form.value.total_funded_amount,
              total_batch_contract_amount: form.value.total_batch_contract_amount // Add the missing property
            };
            
            return this.distributionBatchService.store(batchData);
        })
      ).subscribe(
          (batchResponse: LaravelResourceResponse<DistributionBatch>) => {
              // Handle batch service response here
              console.log("works like magic");
              console.log(batchResponse);
              this.isSubmitting = false;
              this.toastr.success(this.translate.instant('SHARED.submit_result.create.success',
                  { model: this.translate.instant('DISTRIBUTION.batch.model_name.singular') }));
              //this.toastr.success(this.translate.instant('SHARED.submit_result.create.success',
              //    { model: this.translate.instant('DISTRIBUTION.provider.model_name.singular') }));
              //this.router.navigate(['/distribution', 'batches']); // or appropriate route
          },
          (errors) => {
              // Handle errors
              this.isSubmitting = false;
              console.error('Error submitting form:', errors);
              this.serverResponse = errors;
              this.toastr.error(this.translate.instant('SHARED.submit_result.create.error',
                  { model: this.translate.instant('DISTRIBUTION.batch.model_name.singular') }));
              //this.toastr.error(this.translate.instant('SHARED.submit_result.create.error',
              //    { model: this.translate.instant('DISTRIBUTION.provider.model_name.singular') }));
          }
      );
    }else{
      let providerObserver: Observable<LaravelResourceResponse<DistributionProvider>>;
      let batchObserver: Observable<LaravelResourceResponse<DistributionBatch>>;
      providerObserver = this.distributionProviderService.update(this.distributionProvider.id, providerData);
      batchObserver = this.distributionBatchService.update(this.distributionBatch.id, batchData);
      forkJoin([batchObserver, providerObserver]).pipe(
        finalize(() => this.isSubmitting = false)
      ).subscribe(
          results => {
              this.toastr.success(this.translate.instant('SHARED.submit_result.create.success',
                  { model: this.translate.instant('DISTRIBUTION.batch.model_name.singular') }));
              //this.toastr.success(this.translate.instant('SHARED.submit_result.create.success',
              //    { model: this.translate.instant('DISTRIBUTION.provider.model_name.singular') }));
              //this.router.navigate(['/distribution', 'batches']); // or appropriate route
          },
          errors => {
              this.serverResponse = errors;
              this.toastr.error(this.translate.instant('SHARED.submit_result.create.error',
                  { model: this.translate.instant('DISTRIBUTION.batch.model_name.singular') }));
              //this.toastr.error(this.translate.instant('SHARED.submit_result.create.error',
              //    { model: this.translate.instant('DISTRIBUTION.provider.model_name.singular') }));
          }
      );
    }

    /*
    // Call provider service
    let providerObserver: Observable<LaravelResourceResponse<DistributionProvider>>;
    if (this.editorType === 'create') {
        providerObserver = this.distributionProviderService.store(providerData);
    } else {
        providerObserver = this.distributionProviderService.update(this.distributionProvider.id, providerData);
    }

    // Call batch service
    let batchObserver: Observable<LaravelResourceResponse<DistributionBatch>>;
    if (this.editorType === 'create') {
        batchObserver = this.distributionBatchService.store(batchData);
    } else {
        batchObserver = this.distributionBatchService.update(this.distributionBatch.id, batchData);
    }

    // Combine both observables and wait for both to complete
    forkJoin([batchObserver, providerObserver]).pipe(
        finalize(() => this.isSubmitting = false)
    ).subscribe(
        results => {
            this.toastr.success(this.translate.instant('SHARED.submit_result.create.success',
                { model: this.translate.instant('DISTRIBUTION.batch.model_name.singular') }));
            this.toastr.success(this.translate.instant('SHARED.submit_result.create.success',
                { model: this.translate.instant('DISTRIBUTION.provider.model_name.singular') }));
            this.router.navigate(['/distribution', 'batches']); // or appropriate route
        },
        errors => {
            this.serverResponse = errors;
            this.toastr.error(this.translate.instant('SHARED.submit_result.create.error',
                { model: this.translate.instant('DISTRIBUTION.batch.model_name.singular') }));
            this.toastr.error(this.translate.instant('SHARED.submit_result.create.error',
                { model: this.translate.instant('DISTRIBUTION.provider.model_name.singular') }));
        }
    );*/
  }

  //DAM can be deleted if not used
  private buildFormBatch(batch: DistributionBatch) {
    console.log("printing batch");
    console.log(this.distributionBatch);
    this.form = this.fb.group({
      name:                     [batch.name, [Validators.required]],
      distribution_provider_id: [batch.distribution_provider_id, [Validators.required]],
      status:                   [batch.status ? batch.status : 'pending', [Validators.required]],
      total_funded_amount:      [batch.total_funded_amount],
      //product_ids:              [distributionProvider.products ? distributionProvider.products.map(p => p.id) : null],
    });
  }

  private buildMergedForm(batch: DistributionBatch, distributionProvider: DistributionProvider) {
    this.form = this.fb.group({
      // Fields from DistributionBatch
      name:                     [batch.name, [Validators.required]],
      distribution_provider_id: [batch.distribution_provider_id],
      status:                   [batch.status ? batch.status : 'pending', [Validators.required]],
      total_funded_amount:      [batch.total_funded_amount],
  
      // Fields from DistributionProvider
      payment_processor_id:      [distributionProvider.payment_processor_id],
      product_ids:               [distributionProvider.products ? distributionProvider.products.map(p => p.id) : null],
      user_ids:                  [distributionProvider.users ? distributionProvider.users.map(u => u.id) : null],
      company_name:              [batch.name],
      min_contract_value:        [distributionProvider.min_contract_value, []],
      min_monthly_payment:       [distributionProvider.min_monthly_payment, []],
      max_installments:          [distributionProvider.max_installments, []],
      funding_amount_percentage: ['0'], //[Validators.required] 
      cash_hurdle_percentage:    ['0'],
      retention_percentage:      ['0'],
      client_account_iban:       [
        distributionProvider.client_account_iban,
        [
          //Validators.minLength(34), Validators.maxLength(34)
        ],
      ],
      payment_iban:              [
        distributionProvider.payment_iban,
        [
          //Validators.minLength(10), Validators.maxLength(34), Validators.required
        ],
      ],
      address:                   [distributionProvider.address, []],
      contact_person:            [distributionProvider.contact_person, []],
      email:                     [distributionProvider.email, [Validators.email, Validators.required]],
      contact_email:             [distributionProvider.contact_email, [Validators.email]],
      phone:                     [
        distributionProvider.phone,
        [
          //Validators.maxLength(9),
          //Validators.minLength(9),
          //Validators.pattern('(6|7|9)([0-9])\\w+'),
        ],
      ],
    });
  }

  /*private fetchDistributionBatch(batchId: number) {
    this.isLoading++;
    this.distributionBatchService.show(batchId).pipe(finalize(() => this.isLoading--)).subscribe(result => {
        this.distributionBatch = result.data;
        //this.buildFormBatch(this.distributionBatch);
      },
      () => this.toastr.error(this.translate.instant('SHARED.went-wrong')),
    );
  }

  private fetchDistributionProvider(providerId: number) {
    this.isLoading++;
    this.distributionProviderService.show(providerId, ['products', 'users'])
        .pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
              this.distributionProvider = result.data;
              //this.buildFormProvider(this.distributionProvider);
            },
            () => this.toastr.error(this.translate.instant('SHARED.went-wrong')),
        );
  }*/

  private fetchDistributionBatch(batchId: number): Observable<DistributionBatch> {
    this.isLoading++;
    return this.distributionBatchService.show(batchId).pipe(
        finalize(() => this.isLoading--),
        map(result => result.data) // Transform the result to return only the data
    );
  }

  private fetchDistributionProvider(providerId: number): Observable<DistributionProvider> {
      this.isLoading++;
      return this.distributionProviderService.show(providerId, ['products', 'users']).pipe(
          finalize(() => this.isLoading--),
          map(result => result.data) // Transform the result to return only the data
      );
  }

  private buildSelectOptions() {
    this.batchStatusOptions = [
      { value: 'pending', label: 'Pending' },
      { value: 'submitted', label: 'Submitted' },
      { value: 'funded', label: 'Funded' },
      { value: 'rejected', label: 'Rejected' },
    ];
  }

  /*private fetchDistributionProviders() {
    this.isLoading++;
    this.distributionProviderService.index({ select_all: 1 }).pipe(finalize(() => this.isLoading--)).subscribe(result => {
        this.distributionProviders = result.data;
      },
      () => this.toastr.error(this.translate.instant('SHARED.went-wrong')),
    );
  }*/

  private fetchDistributionProviders(): Observable<DistributionProvider[]> {
    this.isLoading++;
    return this.distributionProviderService.index({ select_all: 1 }).pipe(
      finalize(() => this.isLoading--),
      map(result => {
        this.distributionProviders = result.data;
        return this.distributionProviders;
      }),
      catchError(() => {
        this.toastr.error(this.translate.instant('SHARED.went-wrong'));
        return of([])
      })
    );
  }

  //CODE HERE
  private fetchBatches() {
    
    this.isLoading++;

    //this.requestFilters.statuses = this.reportGroup === 'funding' ? ['pending', 'submitted'] : ['funded'];
    //this.requestFilters
    
    this.distributionBatchService.index()
        .pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
          this.batches = result.data as DistributionBatch[];
          console.log("printing batches");
          console.log(result.data);
          console.log(this.batches);
        });
  }

  private fetchCreditors() {
    this.isLoading++;
    this.creditorService.index({ all: 1, active: 1 }).pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
          this.creditors         = result.data;
          this.recoveryCreditors = result.data.filter(creditor => creditor.can_be_recovery);
          // this.checkCreditors();
          this.filterCreditorById();
        },
        err => console.error(err)
      );
  }

  private filterCreditorById() {
    if (this.missId) {
      const filteredCreditor = this.creditors.find(creditor => creditor.id === +this.missId);
      const filteredCreditorName = filteredCreditor ? filteredCreditor.name : '';
      const filteredCreditorContactPeople = filteredCreditor ? filteredCreditor.contact_person_1 : '';
      const filteredCreditorAddress = filteredCreditor ? filteredCreditor.address : '';
      const filteredCreditorRemittance = filteredCreditor ? filteredCreditor.email : '';
      const filteredCreditorPhone = filteredCreditor ? filteredCreditor.phone_1 : ''; //.split(' ').join('')
      const filteredCreditorEmail = filteredCreditor ? filteredCreditor.email : '';
      const filteredCreditorPaymentIban = filteredCreditor ? filteredCreditor.payment_account : '';
      
      this.form.get('name')?.setValue(filteredCreditorName);
      this.form.get('contact_person')?.setValue(filteredCreditorContactPeople);
      this.form.get('address')?.setValue(filteredCreditorAddress);
      this.form.get('email')?.setValue(filteredCreditorRemittance);
      this.form.get('phone')?.setValue(filteredCreditorPhone);
      this.form.get('contact_email')?.setValue(filteredCreditorEmail);
      this.form.get('payment_iban')?.setValue(filteredCreditorPaymentIban);
    }
  }

  /*private fetchProducts() {
    this.isLoading++;
    this.productService.index({select_all: 1}).pipe(finalize(() => this.isLoading--)).subscribe(
        result => this.products = result.data,
        () => this.toastr.error(this.translate.instant('SHARED.went-wrong')),
    );
  }*/

  private fetchProducts(): Observable<Product[]> {
    this.isLoading++;
    return this.productService.index({ select_all: 1 }).pipe(
      finalize(() => this.isLoading--),
      map(result => result.data),
      catchError(() => {
        this.toastr.error(this.translate.instant('SHARED.went-wrong'));
        return of([]);
      })
    );
  }

  /*private fetchDistributionPaymentProcessors() {
    this.isLoading++;
    this.paymentProcessorService.index({select_all: 1, distribution_selectable: 1})
        .pipe(finalize(() => this.isLoading--))
        .subscribe(
            result => this.paymentProcessors = result.data,
            () => this.toastr.error(this.translate.instant('SHARED.went-wrong')),
        );
  }*/

  private fetchDistributionPaymentProcessors(): Observable<Product[]> {
    this.isLoading++;
    return this.paymentProcessorService.index({ select_all: 1, distribution_selectable: 1 }).pipe(
      finalize(() => this.isLoading--),
      map(result => result.data),
      catchError(() => {
        this.toastr.error(this.translate.instant('SHARED.went-wrong'));
        return of([]);
      })
    );
  }

  /*private fetchDistributionUsers() {
    this.isLoading++;
    this.userService.index({select_all: 1, role_slugs: ['distribution-provider']})
        .pipe(finalize(() => this.isLoading--))
        .subscribe(
            result => this.distributionUsers = result.data,
            () => this.toastr.error(this.translate.instant('SHARED.went-wrong')),
        );
  }*/

  private fetchDistributionUsers(): Observable<User[]> {
    this.isLoading++;
    return this.userService.index({ select_all: 1, role_slugs: ['distribution-provider'] }).pipe(
      finalize(() => this.isLoading--),
      map(result => result.data),
      catchError(() => {
        this.toastr.error(this.translate.instant('SHARED.went-wrong'));
        return of([]);
      })
    );
  }
}
