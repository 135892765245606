import { Product } from '../Product';
import { PaymentStatus } from '../Status/PaymentStatus';
import { Status } from '../Status/Status';
import { User } from '../User/User';
import { DripNotification } from './DripNotification';

export class DripCampaign {
  id?: number;

  author_id: number;
  statusable_type: 'status' | 'payment_status';
  target: string;
  destination_email?: string;
  statusable_id: number;
  packager_id?: number;

  name: string;
  active: boolean | 0 | 1;
  allow_on_weekend: boolean | 0 | 1;

  created_at: string | Date;
  updated_at: string | Date;

  author?: User;
  statusable?: Status | PaymentStatus;
  drip_notifications?: Array<DripNotification>;
  products?: Array<Product>;
}
