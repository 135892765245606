<mat-card>
  <mat-card-header>
    <mat-card-title class="mb-3">
      <h1>{{ 'DISTRIBUTION.cases.list.heading' | translate }}</h1>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <!-- Filters -->
    <div class="d-flex justify-content-end">
      <app-input type="text" [formControl]="searchFilter" [appearance]="'standard'"
                 [label]="'SHARED.search' | translate" [extraLabel]="false" prefixType="icon" prefix="search">
      </app-input>
    </div>
    <!-- Advanced Filters -->
    <mat-expansion-panel class="mb-3">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ "PAYMENTS.advanced-filters" | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="row">
        <!-- Batch ID -->
        <div class="col-4">
          <app-input type="select" [formControl]="batchesFilter" appearance="standard" multiple fullWidth="false"
                     [label]="'Wallets'" [searchable]="true"
                     [selectOptions]="distributionBatches" [selectLabel]="'name'" [selectValue]="'id'">
                     <!-- 'DISTRIBUTION.batch.selector.distribution_batch_id' | translate -->
          </app-input>
        </div>

        <!-- Cases ID -->
        <!--<div class="col-4">
          <app-input type="select" [formControl]="casesFilter" appearance="standard" multiple fullWidth="false"
                     [searchable]="true"
                     [label]="'Cases'"
                     [selectOptions]="allCases" 
                     [selectLabel]="'ref_number'" 
                     [selectValue]="'id'">
          </app-input>
        </div>-->
        <!-- Batch Status -->
        <div class="col-4">
          <app-input type="select" [formControl]="batchStatusFilter" appearance="standard" fullWidth="false"
                     [label]="'Case Status'"
                     [selectOptions]="batchStatusOptions" [selectLabel]="'label'" [selectValue]="'value'">
                     <!-- 'DISTRIBUTION.cases.list.filters.batch_status' | translate -->
          </app-input>
        </div>
        <!--<div class="col-4">
          <app-input type="select" [formControl]="paymentStatuses" appearance="standard" multiple fullWidth="false"
                     [label]="'CASES.single.general.status_editor.payment_status' | translate"
                     [selectOptions]="paymentStatusOptions" [selectLabel]="'name'" [selectValue]="'id'">
          </app-input>
        </div>-->

        <!-- Case Status -->
        <!--<div class="col-4">
          <app-input type="select" [formControl]="caseStatusFilter" appearance="standard" [fullWidth]="true"
                     [label]="'CONFIG.drip-campaign.editor.statusable_id.label' | translate" multiple
                     [selectOptions]="statusCategories" [optGroupProperty]="'statuses'" [optGroupLabel]="'name'"
                     [selectLabel]="'name'" [selectValue]="'id'">
          </app-input>
        </div>-->
      
      </div>
      <!--<div class="row">
        more lines in advanced filters
      </div>-->
    </mat-expansion-panel>
    <!-- Table List -->
    <mat-card class="mat-elevation-z3">
      <mat-card-content>
        <app-server-response *ngIf="serverResponse" [response]="serverResponse"></app-server-response>
        <!--If all are selected and there is more to select on another page -->
        <div *ngIf="caseListFilter && pageSelected && (totalPages > 1)" class="row mb-3">
          <div class="col-12">
            <div class="global-select-all">
              <p class="m-0" *ngIf="!caseListFilter.select_all">
                Selected <b>{{ selection.selected.length }}</b> cases on this page.
                <button mat-button color="primary" (click)="globalSelectAll($event)">
                  Select all {{ totalResults | number }} cases
                </button>
              </p>
              <p class="m-0" *ngIf="caseListFilter.select_all">
                All <b>{{ totalResults | number }}</b> cases selected.
                <button mat-button color="primary" (click)="globalClearSelectAll($event)">Clear selection</button>
              </p>
            </div>
          </div>
        </div>
        <mat-table [dataSource]="cases" matSort (matSortChange)="sortData($event)">
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <!-- Selection -->
          <ng-container matColumnDef="select">
            <!-- Select All On Page -->
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox color="primary" (change)="$event ? togglePageSelect($event) : null"
                            [checked]="selection.hasValue() && allRowsSelected()"
                            [indeterminate]="selection.hasValue() && !allRowsSelected()">
              </mat-checkbox>
            </th>
            <!-- Select Row -->
            <td mat-cell *matCellDef="let element">
              <mat-checkbox color="primary" [checked]="selection.isSelected(element)"
                            (change)="toggleRow($event, element)">
              </mat-checkbox>
            </td>
          </ng-container>
          <!-- Batch -->
          <ng-container matColumnDef="distribution_batch_name">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'DISTRIBUTION.cases.list.table.status' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <!--<span *ngIf="(element.distribution?.[0]?.distribution_batch_id ?? 0) != 0">-->
                <!--<div>{{ element.distribution.batch?.name }}</div>-->
                <!--<div><span class="badge badge-info">{{ element.distribution?.[0]?.batch?.status }}</span></div>-->
              <!--</span>-->
              <!--<span *ngIf="!(element.distribution?.[0]?.batch)" class="badge badge-info">N/D</span>-->
              <ng-container *ngIf="(element.distribution?.[0]?.distribution_batch_id ?? 0) != 0; else notAssigned">
                <div><span class="badge badge-secondary">Assigned</span></div>
              </ng-container>
              <ng-template #notAssigned>
                <div><span class="badge badge-danger">Pending</span></div>
              </ng-template>
            </td>
          </ng-container>
          <!-- Ref Number -->
          <ng-container matColumnDef="ref_number">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="pl-2">
              {{ "CASES.model.ref_number" | translate }}
            </th>
            <td mat-cell *matCellDef="let element"><a [routerLink]="['/cases', element.id]">{{ element.ref_number }}</a></td>
          </ng-container>
          <!-- First Name -->
          <ng-container matColumnDef="first_name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="pl-2">
              {{ "USERS.model.first_name" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.client.first_name }} </td>
          </ng-container>
          <!-- Last Name -->
          <ng-container matColumnDef="last_name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="pl-2">
              {{ 'USERS.model.last_name' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.client.last_name }}</td>
          </ng-container>
          <!-- Application Type -->
          <ng-container matColumnDef="joint_application">
            <th mat-header-cell *matHeaderCellDef class="text-center">
              {{ "CASES.model.joint_application" | translate }}
            </th>
            <td mat-cell *matCellDef="let element" class="text-center">
              <mat-icon *ngIf="element.joint_application" class="text-success mat-icon">check</mat-icon>
              <mat-icon *ngIf="!element.joint_application" class="text-danger mat-icon">clear</mat-icon>
            </td>
          </ng-container>
          <!-- Product Group Slug -->
          <ng-container matColumnDef="product_group_slug">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="pl-2">
              {{ "PRODUCT.model.group_slug" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.product.group_name_es | uppercase }}</td>
          </ng-container>
          <!-- Product Name -->
          <ng-container matColumnDef="product_name">
            <th mat-header-cell *matHeaderCellDef class="pl-2">
              {{ "PRODUCT.model.name" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.product.name }}</td>
          </ng-container>
          <!-- Status -->
          <ng-container matColumnDef="status">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="pl-2">
              {{ "CASES.case-status" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <div><span class="badge badge-primary">{{ element.status?.name }}</span></div>
              <div class="mb-2"><span class="badge badge-info">{{ element.payment_status?.name }}</span></div>
            </td>
          </ng-container>
          <!-- Phase 2 Monthly Fee -->
          <ng-container matColumnDef="phase_2_installment_amount">
            <th mat-header-cell *matHeaderCellDef class="pl-2">
              {{ 'DISTRIBUTION.cases.list.table.phase_2_installment_amount' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.c_monthly_installment | currency:'EUR' }}</td> <!-- {{ element.debt_payment_plan?.phase_two_monthly_fee }}-->
          </ng-container>
          <!-- Amount Paid -->
          <ng-container matColumnDef="amount_paid">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CASES.single.amount-paid" | translate }}</th>
            <td mat-cell *matCellDef="let element" class="pr-2">{{ element.c_amount_paid | currency:'EUR' }}</td>
          </ng-container>
          <!-- Contract Amount -->
          <ng-container matColumnDef="contract_amount">
            <th mat-header-cell *matHeaderCellDef>{{ 'DISTRIBUTION.distribution-list.original_debt_amount' | translate }}</th>
            <td mat-cell *matCellDef="let element" class="pr-2">
              <span *ngIf="element.debt_payment_plan">
                {{ element.sum_total_debt_amount | currency:'EUR' }}
              </span>
            </td>
          </ng-container>
          <!-- Balance -->
          <ng-container matColumnDef="balance">
            <th mat-header-cell *matHeaderCellDef>{{ 'DISTRIBUTION.distribution-list.plan_balance' | translate }}</th>
            <td mat-cell *matCellDef="let element" class="pr-2">
              <div *ngIf="element.debt_payment_plan">
                {{ element.c_amount_unpaid | currency:'EUR' }}
              </div>
            </td>
          </ng-container>
          <!-- Pay Day -->
          <ng-container matColumnDef="payment_day">
            <th mat-header-cell *matHeaderCellDef class="pl-2">{{ 'CASES.single.payment-day' | translate }}</th>
            <td mat-cell *matCellDef="let element">
              <span *ngIf="element.debt_payment_plan">{{ element.debt_payment_plan.payment_day }}</span>
            </td>
          </ng-container>
          <!-- Entered at -->
          <ng-container matColumnDef="entered_at">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="pl-2">
              {{ 'DISTRIBUTION.cases.list.table.entered_at' | translate }}
            </th>
            <!-- <td mat-cell *matCellDef="let element">{{ element.distribution?.entered_at | date }}</td> -->
            <td mat-cell *matCellDef="let element">{{ element.entered_at | date }}</td>
          </ng-container>
        </mat-table>
        <!-- No Data -->
        <div *ngIf="isLoading < 1 && cases && cases.data.length < 1" style="padding: 50px">
          <h3 class="text-center">{{ "CASES.no-cases" | translate }}</h3>
        </div>
        <div *ngIf="isLoading" class="text-center" style="padding: 50px">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </mat-card-content>
      <mat-card-footer>
        <div class="footer-actions ml-3">
          <button mat-raised-button color="primary" [matMenuTriggerFor]="removeFromDistMenu" class="ml-3"
                  [disabled]="selection.selected.length === 0">
            {{ 'DISTRIBUTION.cases.list.actions.remove_from_distribution.label' | translate }}
            <mat-icon>keyboard_arrow_down</mat-icon>
          </button>
          <mat-menu #removeFromDistMenu="matMenu">
            <div>
              <a mat-menu-item class="dropdown-item" (click)="removeFromDistributionPrompt()">
                {{ 'DISTRIBUTION.cases.list.actions.remove_from_distribution.label' | translate }}
              </a>
              <!--<a mat-menu-item class="dropdown-item" (click)="removeFromDistributionPrompt(1)">
                {{ 'DISTRIBUTION.cases.list.actions.remove_from_distribution.label_mark_nonviable' | translate }}
              </a>-->
            </div>
          </mat-menu>
          <!--<button mat-raised-button color="primary" class="ml-3" (click)="assignToBatchModal()"
                  [disabled]="selection.selected.length === 0">
            {{ 'DISTRIBUTION.cases.list.actions.assign_batch.label' | translate }}
          </button>-->
          <button mat-raised-button color="primary" class="ml-3" (click)="confirmDistributeWallet()"
                  [disabled]="selection.selected.length === 0">
            {{ 'DISTRIBUTION.cases.list.actions.assign_batch.label' | translate }}
          </button>
          <!--<button mat-raised-button color="primary" class="ml-3" (click)="unassignFromBatchesPrompt()"
                  [disabled]="selection.selected.length === 0">
            {{ 'DISTRIBUTION.cases.list.actions.remove_batch.label' | translate }}
          </button>
          <button mat-raised-button color="primary" [matMenuTriggerFor]="reportsMenu" class="ml-3"
                  [disabled]="selection.selected.length === 0">
            {{ 'DISTRIBUTION.cases.list.actions.generate_report.label' | translate }}
            <mat-icon>keyboard_arrow_down</mat-icon>
          </button>-->
          <mat-menu #reportsMenu="matMenu">
            <!--<div>-->
            <!--  <a mat-menu-item class="dropdown-item" (click)="generateReport('scheduled_fee')">-->
            <!--    {{ 'DISTRIBUTION.cases.list.actions.generate_report.options.scheduled_fee' | translate }}-->
            <!--  </a>-->
            <!--</div>-->
            <!--<div>-->
            <!--  <a mat-menu-item class="dropdown-item" (click)="generateReport('payments_received')">-->
            <!--    {{ 'DISTRIBUTION.cases.list.actions.generate_report.options.payments_received' | translate }}-->
            <!--  </a>-->
            <!--</div>-->
            <div>
              <a mat-menu-item class="dropdown-item" (click)="generateReport(caseListFilter, 'claims')">
                {{ 'DISTRIBUTION.cases.list.actions.generate_report.options.claims' | translate }}
              </a>
              <a mat-menu-item class="dropdown-item" (click)="generateReport(caseListFilter, 'pledge')">
                {{ 'DISTRIBUTION.report.pledge_report.label' | translate }}
              </a>
            </div>
          </mat-menu>
        </div>
        <mat-paginator [length]="paginatorConfig.length" [pageSize]="paginatorConfig.pageSize" showFirstLastButtons
                       [pageSizeOptions]="[5, 10, 20, 50, 100]" [pageIndex]="paginatorConfig.pageIndex"
                       (page)="paginatorChange($event)">
        </mat-paginator>
      </mat-card-footer>
    </mat-card>
    <!-- Wallet creation warning -->
    <div class="row mt-3" *ngIf="missingBatches?.length > 0" >
      <div class="alert alert-danger ml-4" role="alert">
        <h4 class="alert-heading"> Wallets that might need to be created</h4>
        <p>Please create the following wallets to be sure all the cases can be distributed</p>
        <hr>
        <div>
          <table class="table table-striped">
            <thead>
            <tr>
              <th>wallet:</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let missWallet of missingBatches">
              <td><a href="distribution/batches/create?miss-id={{ missWallet.id }}">{{ missWallet.name }}</a></td> <!-- target="_blank" -->
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>


