<h1 *ngIf="!userId">{{"USERS.add-user" | translate}}</h1>
<h1 *ngIf="userId">{{"USERS.edit-user" | translate}}</h1>
<div *ngIf="isLoading" class="row">
  <div class="col-12 pt-5 text-center">
    <mat-spinner class="m-0 m-auto"></mat-spinner>
  </div>
</div>
<app-server-response [response]="serverResponse"></app-server-response>
<form *ngIf="form" [formGroup]="form" [hidden]="isLoading" (ngSubmit)="submit(form)" class="form">
  <div class="card shadow mb-5">
    <div class="card-body">
      <div class="row">
        <div class="col">
          <!-- Role -->
          <app-input *ngIf="roles?.length" type="select" formControlName="role_id" [label]="'USERS.role' | translate"
                     [selectOptions]="roles" [selectLabel]="'name'" [selectValue]="'id'" [extraLabel]="true"
                     [fullWidth]="true" (ngModelChange)="selectedRoleChanged($event)">
          </app-input>
          <!-- Creditor Name -->
          <app-input *ngIf="user.role?.slug === 'creditor'" formControlName="creditor_name"
                     [label]="'REPORTS.creditor' | translate" [fullWidth]="true" [extraLabel]="true" readonly>
          </app-input>
          <!-- Creditor dropdown -->
          <app-input *ngIf="selectedRole?.slug === 'creditor'" type="select" formControlName="creditor_id"
                     [label]="'REPORTS.creditor' | translate" [selectOptions]="creditors" [searchable]="true"
                     [selectLabel]="'name'" [selectValue]="'id'" [extraLabel]="true" [fullWidth]="true" [hidden]="!isCreatingCreditor">
          </app-input>
          <!--Affiliate list-->
          <app-input *ngIf="selectedRole?.slug === 'affiliate'" type="select" formControlName="affiliate_ids"
                     appearance="outline" [label]="'CONFIG.misc.affiliate' | translate" showLabel="true"
                     fullWidth="true" searchable="true" [extraLabel]="true" [multiple]="true"
                     [selectOptions]="allAffiliates" [selectLabel]="'name'" [selectValue]="'id'">
          </app-input>
          <!--Send notification to affiliate-->
          <app-input type="checkbox" formControlName="send_affiliate_login_notification"
                     [label]="'CONFIG.misc.send_affiliate_login_notification' | translate"
                     [extraLabel]="true" [fullWidth]="true"
                     *ngIf="selectedRole?.slug === 'affiliate' && editorType === 'create'">
          </app-input>
          <!-- Distribution Providers -->
          <app-input *ngIf="selectedRole?.slug === 'distribution-provider'" type="select" multiple
                     formControlName="distribution_provider_ids"
                     [label]="'DISTRIBUTION.provider.model_name.plural' | translate"
                     [selectOptions]="distributionProviders" [selectLabel]="'company_name'" [selectValue]="'id'"
                     [extraLabel]="true" [fullWidth]="true">
          </app-input>
          <!-- First Name-->
          <app-input formControlName="first_name" [label]="'USERS.editor.first_name' | translate" [fullWidth]="true"
                     [extraLabel]="true" [submitted]="formSubmitted">
          </app-input>
          <!-- Last Name -->
          <app-input formControlName="last_name" [label]="'USERS.editor.last_name' | translate" [fullWidth]="true"
                     [extraLabel]="true" [submitted]="formSubmitted">
          </app-input>
          <!-- Email -->
          <app-input type="email" formControlName="email" [label]="'USERS.model.email' | translate" [fullWidth]="true"
                     [extraLabel]="true">
          </app-input>
          <!-- Id Card -->
          <app-input formControlName="id_card" [label]="'USERS.model.id_card' | translate" [fullWidth]="true"
                     [extraLabel]="true">
          </app-input>
          <!-- Phone -->
          <app-input type="number" formControlName="phone" [label]="'USERS.editor.phone' | translate" [fullWidth]="true"
                     [extraLabel]="true">
          </app-input>
          <!-- User Departments -->
          <app-input *ngIf="categoryDepartments?.length" type="select" formControlName="department_ids"
                     [label]="'USERS.editor.departments' | translate" (ngModelChange)="onDepartmentChange($event)"
                     [multiple]="true" [selectOptions]="categoryDepartments" [selectLabel]="'name'" [selectValue]="'id'"
                     [optGroupProperty]="'departments'" [extraLabel]="true" [fullWidth]="true" [selectAll]="true">
          </app-input>
          <!-- Password -->
          <app-input *ngIf="!userId" formControlName="password" [label]="'USERS.password' | translate"
                     [extraLabel]="true" [fullWidth]="true">
          </app-input>
          <!-- User Product Round Robin Departments -->
          <div formArrayName="product_round_robin_pools">
            <div *ngFor="let productRoundRobinPool of userRoundRobinArray.controls; let i = index">
              <div [formGroupName]="i">
                <app-input *ngIf="categoryDepartments?.length" type="select" formControlName="department_ids" multiple
                           [label]="productRoundRobinPool.get('name').value +' Round Robin'"
                           [selectOptions]="categoryDepartments" [selectLabel]="'name'" [selectValue]="'id'"
                           [optGroupProperty]="'departments'" [extraLabel]="true" [fullWidth]="true">
                </app-input>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <!-- Diary URL -->
          <app-input type="text" formControlName="diary_url" [label]="'USERS.editor.diary_url' | translate"
                     [extraLabel]="true" [fullWidth]="true">
          </app-input>
          <!-- Contact Agent Diary URL -->
          <app-input type="text" formControlName="customer_contact_diary_url"
                     [label]="'USERS.editor.customer_contact_diary_url' | translate"
                     [extraLabel]="true" [fullWidth]="true">
          </app-input>
          <!-- Connex user id -->
          <app-input type="text" formControlName="connex_username" [label]="'Connex User ID'"
                     [extraLabel]="true" [fullWidth]="true">
          </app-input>
          <!-- Paired lawyer -->
          <app-input *ngIf="(authUser.role?.name === 'SuperAdmin' && (user.role?.slug === 'manager')) ||
            (authUser.role?.name === 'SuperAdmin' && isCaseManagerSelected)"
                     type="select" formControlName="paired_lawyer_id" [label]="'CONFIG.misc.lawyer' | translate"
                     [selectOptions]="lawyers" [selectLabel]="'name'" [selectValue]="'id'"
                     [extraLabel]="true" [fullWidth]="true">
          </app-input>
          <app-input type="select" formControlName="packager_id" appearance="outline" class="mt-2" [extraLabel]="true"
                     [label]="'PACKAGER.model_name.singular' | translate" showLabel="true" fullWidth="true"
                     searchable="true"
                     [selectOptions]="packagers" [selectLabel]="['name_en']" [selectValue]="'id'">
          </app-input>
          <!-- Active -->
          <app-input type="checkbox" formControlName="active" [label]="'CONFIG.misc.active' | translate"
                     [extraLabel]="true" [fullWidth]="true" style="width: 528px;display: block;">
          </app-input>
          <!-- Show transfer case button -->
          <app-input type="checkbox" formControlName="show_transfer_button"
                     [label]="'USERS.editor.show_transfer_button' | translate"
                     [extraLabel]="true" [fullWidth]="true" style="width: 605px;display: block;">
          </app-input>
        </div>
      </div>
      <!-- Change Password -->
      <div *ngIf="form.get('change_password')">
        <h3 class="pt-5 pb-1">Change user password</h3>
        <div>
          <app-input formControlName="change_password" [fullWidth]="true"
                     [label]="'USERS.editor.new_password' | translate" [extraLabel]="true">
          </app-input>
          <div *ngIf="form.get('change_password').hasError('mustMatch') &&
            (form.get('change_password').touched || formSubmitted)">
            <mat-error>{{ "USERS.editor.change-password-error" | translate }}</mat-error>
          </div>
        </div>
        <div>
          <app-input formControlName="repeat_change_password" [fullWidth]="true"
                     [label]="'USERS.editor.repeat_new_password' | translate" [extraLabel]="true">
          </app-input>
          <div *ngIf="form.get('repeat_change_password').hasError('mustMatch') &&
            (form.get('repeat_change_password').touched || formSubmitted)">
            <mat-error>{{ "USERS.editor.change-password-error" | translate }}</mat-error>
          </div>
        </div>
      </div>
      <div class="d-flex mt-3 justify-content-end">
        <app-spinner-btn [loading]="submitting" [name]="'SHARED.submit' | translate"></app-spinner-btn>
      </div>
    </div>
  </div>
</form>
