import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { ReportService } from '../../report.service';

@Component({
  selector: 'app-dm-reconciliation-report',
  templateUrl: './dm-reconciliation-report.component.html',
  styles: []
})
export class DmReconciliationReportComponent implements OnInit, OnDestroy{
  public form: UntypedFormGroup;
  public isLoading = 0;

  constructor(
    private toast: ToastrService,
    private reportService: ReportService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<DmReconciliationReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm(): void {
    this.form = this.fb.group({
      start_date: [null], //, Validators.required
      end_date:   [null], //, Validators.required
    });
  }

  sendReportData(): void {
    if (!this.form.valid) {
      return;
    }

    const formatDate = (date: Date): string => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    const requestData: any = {};
    if (this.form.value.start_date) {
      requestData.start_date = formatDate(this.form.value.start_date);
    }
    if (this.form.value.end_date) {
      requestData.end_date = formatDate(this.form.value.end_date);
    }
    
    this.isLoading++;

     this.reportService.generateDMReconciliationExportReport(requestData).pipe(finalize(() => this.isLoading--))
        .subscribe(res => {
              this.dialogRef.close(true);
              this.toast.success(res.message);
            },
            err => console.error(err),
        );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
  }
}
