import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { CallHistory } from '../../../../../_base-shared/models/Notification/CallHistory';
import { DatabaseNotification } from '../../../../../_base-shared/models/Notification/DatabaseNotification';
import { EmailHistory } from '../../../../../_base-shared/models/Notification/EmailHistory';
import { NotificationChannel, NotificationChannelSlug } from '../../../../../_base-shared/models/Notification/NotificationChannel';
import { SmsHistory } from '../../../../../_base-shared/models/Notification/SmsHistory';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService extends MainBaseApiService {

  public index(params = {}): Observable<LaravelResourceResponse<Array<DatabaseNotification>>> {
    return this.http.get<LaravelResourceResponse<Array<DatabaseNotification>>>(
        this.apiUrl + '/notifications', {params},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public show(notificationUuid: string): Observable<LaravelResourceResponse<DatabaseNotification>> {
    return this.http.get<LaravelResourceResponse<DatabaseNotification>>(this.apiUrl + '/notifications/' + notificationUuid)
        .pipe(catchError(response => this.handleError(response)));
  }

  public store(notification): Observable<LaravelResourceResponse<DatabaseNotification>> {
    return this.http.post<LaravelResourceResponse<DatabaseNotification>>(this.apiUrl + '/notifications', notification)
        .pipe(catchError(response => this.handleError(response)));
  }

  public update(notificationUuid: string, notification): Observable<LaravelResourceResponse<DatabaseNotification>> {
    return this.http.patch<LaravelResourceResponse<DatabaseNotification>>(
        this.apiUrl + '/notifications/' + notificationUuid, notification,
    ).pipe(catchError(response => this.handleError(response)));
  }

  public delete(notificationUuid: string): Observable<LaravelResourceResponse<DatabaseNotification>> {
    return this.http.delete<LaravelResourceResponse<DatabaseNotification>>(this.apiUrl + '/notifications/' + notificationUuid)
        .pipe(catchError(response => this.handleError(response)));
  }

  public indexChannels(): Observable<LaravelResourceResponse<Array<NotificationChannel>>> {
    return this.http.get<LaravelResourceResponse<Array<NotificationChannel>>>(this.apiUrl + '/notification-channels')
        .pipe(catchError(response => this.handleError(response)));
  }

  public markAsRead(params: { notification_ids?: Array<string> } = {}): Observable<LaravelResourceResponse<Array<DatabaseNotification>>> {
    return this.http.patch<LaravelResourceResponse<Array<DatabaseNotification>>>(
        this.apiUrl + '/notifications/mark-as-read', params,
    ).pipe(catchError(response => this.handleError(response)));
  }

  public indexNotifications(caseId: number, channelSlug: NotificationChannelSlug, recipientType: string)
    : Observable<LaravelResourceResponse<any>> {
    const data   = {case_id: caseId, channel_slug: channelSlug, recipient_type: recipientType};
    const params = MainBaseApiService.convertFiltersForRequest(data, 'get');
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/cases/' + caseId + '/notifications', {params})
        .pipe(catchError(response => this.handleError(response)));
  }

  public indexCaseNotifications(caseId: number, params): Observable<LaravelResourceResponse<Array<EmailHistory | SmsHistory>>> {
    return this.http.get<LaravelResourceResponse<Array<EmailHistory | SmsHistory>>>(
        this.apiUrl + '/cases/' + caseId + '/notification-history', {params},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getCallLogs(caseId: number): Observable<LaravelResourceResponse<Array<CallHistory>>> {
    return this.http.get<LaravelResourceResponse<Array<CallHistory>>>(
        this.apiUrl + '/cases/' + caseId + '/call-logs',
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getdmacceptedcount(): Observable<LaravelResourceResponse<any>> {
    return this.http.get<LaravelResourceResponse<any>>(this.apiUrl + '/getdmacceptedcount')
        .pipe(catchError(response => this.handleError(response)));
  }
}
