<div class="center">
    <div class="container">
        <div class="row d-flex flex-column flex-lg-row justify-content-center align-items-center">
            <div class="col-12 col-lg-6 verticalalign flex-grow-0">
                <h1 class="welcome">Bienvenido a Unifye</h1>
            </div>
            <div class="col-12 col-lg-6 flex-grow-0">
              <!-- Server Errors -->
              <div class="m-4" *ngIf="serverResponse">
                <app-server-errors fieldName="email" [errorResponse]="serverResponse"></app-server-errors>
              </div>
                <div class="formback">
                    <form [formGroup]="form" (ngSubmit)="submitForm(form)">
                        <h1 style="color: #333; text-align: center;">Inicio de sesión</h1>
                        <hr>
                        <!-- ID Card -->
                        <app-input type="text" formControlName="username" label="Usuario"
                                    [showLabel]="true" [extraLabel]="false" appearance="outline" [fullWidth]="true">
                        </app-input>
                        <!-- Password -->
                        <app-input type="password" formControlName="password" label="Contraseña"
                                    [showLabel]="true" [extraLabel]="false" appearance="outline" [fullWidth]="true">
                        </app-input>
                        <!-- Submit -->
                        <div class="text-center pb-3">
                            <button class="ml-2 btn client-button-correct" style="padding-left: 40px; padding-right: 40px;" type="submit">
                                INICIAR SESIÓN
                            </button>
                        </div>
                        <!-- Links -->
                        <div class="text-center">
                            <a routerLink="/forgot-password" style="color: rgb(49, 143, 243)">
                            <!-- {{ 'AUTH.login.links.forgot-password' | translate }} -->
                            ¿Olvidaste tu contraseña?
                            </a>
                        </div>
                        <app-input type="select" appearance="standard" [formControl]="selectedLanguage" [showLabel]="true"
                                   [showClear]="false" [selectOptions]="languages" [selectLabel]="'label'" [selectValue]="'value'"
                                   [fullWidth]="false" (ngModelChange)="changeLanguage($event)" [hidden]="true">
                        </app-input>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
