<h1 mat-dialog-title>Remittance report</h1>
<div class="pt-3">
    <form [formGroup]="form" (ngSubmit)="sendReportData()">

        <p style="color:blue">*Leave creditors blank for all creditors</p>
        <mat-form-field appearance="fill" class="full-width">
            <mat-label>
              Select Creditor
            </mat-label><input matInput style="display:none;">
            <app-input matInput type="select" *ngIf="creditors?.length" formControlName="creditor_id" appearance="outline"
              [searchable]="true" [label]="'CASE_CREDITOR.model.relations.creditor' | translate"
              class="full-width" [selectOptions]="creditors" [selectLabel]="['name']"
              [selectValue]="'id'" [showClear]="false" [fullWidth]="true" [showLabel]="true" [extraLabel]="true">
            </app-input>
        </mat-form-field>
          
        <mat-form-field appearance="fill" class="mr-1">
            <mat-label>
                {{ "REPORTS.financial-reports.list.payment-received-report.fields.start-date" | translate }}
            </mat-label>
            <input matInput [matDatepicker]="start_date" formControlName="start_date">
            <mat-datepicker-toggle matSuffix [for]="start_date"></mat-datepicker-toggle>
            <mat-datepicker #start_date></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>
                {{ "REPORTS.financial-reports.list.sales-report.fields.end-date" | translate }}
            </mat-label>
            <input matInput [matDatepicker]="end_date" formControlName="end_date">
            <mat-datepicker-toggle matSuffix [for]="end_date"></mat-datepicker-toggle>
            <mat-datepicker #end_date></mat-datepicker>
        </mat-form-field>

        <div class='pt-2 text-right'>
            <button type="button" mat-button color="primary" class="mt-3 ml-2" (click)="dialogRef.close()">
                {{ "REPORTS.financial-reports.list.sales-report.actions.close" | translate }}
            </button>
            <button type="submit" mat-raised-button color="primary" class="mt-3">
                {{ "REPORTS.financial-reports.list.sales-report.actions.submit" | translate }}
                <span *ngIf="isLoading" class="spinner-border spinner-border-sm text-white" role="status">
                    <span class="sr-only">Loading...</span>
                </span>
            </button>
        </div>
    </form>
</div>
