import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AppSelectOption } from '../../../../../../_base-shared/contracts/common.interface';
import { LaravelResourceResponse } from '../../../../../../_base-shared/contracts/laravel-response.interface';
import { Affiliate } from '../../../../../../_base-shared/models/Affiliate';
import { DepartmentCategory } from '../../../../../../_base-shared/models/Department/DepartmentCategory';
import { DistributionProvider } from '../../../../../../_base-shared/models/Distribution/DistributionProvider';
import { Packager } from '../../../../../../_base-shared/models/Packager/Packager';
import { Product } from '../../../../../../_base-shared/models/Product';
import { Role } from '../../../../../../_base-shared/models/Role';
import { RoundRobinPool } from '../../../../../../_base-shared/models/User/RoundRobinPool';
import { User } from '../../../../../../_base-shared/models/User/User';
import { UserSaleTarget } from '../../../../../../_base-shared/models/User/UserSaleTarget';
import { SnakeCaseToCamelPipe } from '../../../../../../_base-shared/pipes/snake-case-to-camel.pipe';
import { CustomValidators, mustMatch } from '../../../../../../_base-shared/validators/custom.validators';
import { environment } from '../../../../environments/environment';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';
import { AdminPackagerService } from '../../admin-packager/admin-packager.service';
import { AffiliateService } from '../../affiliate/affiliate.service';
import { ProductService } from '../../case/product.service';
import { DepartmentService } from '../../department/department.service';
import { DistributionProviderService } from '../../distribution/distribution-provider.service';
import { StatusService } from '../../status/status.service';
import { UserService } from '../user.service';
import { CreditorService } from '../../creditor/creditor.service';
import { Creditor } from 'projects/_base-shared/models/Entity/Creditor';

@Component({
  selector:    'app-user-editor',
  templateUrl: './user-editor.component.html',
  styleUrls:   ['./user-editor.component.scss'],
})
export class UserEditorComponent implements OnInit, OnDestroy {
  public authUser: User;
  public userId: number                                     = null;
  public user: User;
  public isLoading                                          = 0;
  public submitting: boolean;
  public fetchingUser: boolean;
  public form: UntypedFormGroup;
  public formSubmitted: boolean;
  public serverResponse: LaravelResourceResponse;
  public roles: Array<Role>                                 = [];
  public products: Array<Product>                           = [];
  public categoryDepartments: Array<DepartmentCategory>     = [];
  public lawyers: Array<User>                               = [];
  public userRoundRobinPools: Array<RoundRobinPool>         = [];
  public distributionProviders: Array<DistributionProvider> = [];
  public allAffiliates: Array<Affiliate>                    = [];
  public packagers: Array<Packager>                         = [];
  public creditors: Array<Creditor>                         = [];
  public selectedRole: Role;
  public isLegalAdvisorSelected                             = false;
  public isCaseManagerSelected                              = false;
  public isCreatingCreditor                                 = false;

  private subscriptions: Array<Subscription> = [];
  private emailValidationTimeout: any;
  private isProductionEnv: boolean;
  public editorType: string;

  constructor(private fb: UntypedFormBuilder,
              public userService: UserService,
              public statusService: StatusService,
              private route: ActivatedRoute,
              private router: Router,
              private snakeCaseToCamelPipe: SnakeCaseToCamelPipe,
              public translateService: TranslateService,
              private globalEventsService: MainGlobalEventService,
              private productService: ProductService,
              private departmentService: DepartmentService,
              private distributionProviderService: DistributionProviderService,
              private affiliateService: AffiliateService,
              private packagerService: AdminPackagerService,
              private creditorService: CreditorService) {
  }

  ngOnInit(): void {
    this.isProductionEnv      = environment.production;
    this.globalEventsService.authUser$.subscribe(user => {
      this.authUser = user;
      if (this.authUser) {
        this.translateService.onLangChange.subscribe(lang => {
          this.fetchRoles();
          this.fetchDepartments();
        });

        this.userId = +this.route.snapshot.paramMap.get('id');
        if (this.userId) {
          this.editorType = 'edit';
          this.getUser(this.userId);
        } else {
          this.editorType = 'create';
          this.userId     = null;
          this.user       = this.getUserDefaultData();
          this.buildForm(this.user);
        }
        this.fetchRoles();
        this.fetchDepartments();
        this.fetchDistributionProviders();
      }
    });
    this.fetchAffiliates();
    this.fetchPackagers();
    this.fetchCreditors();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  get userRoundRobinArray() {
    return this.form.get('product_round_robin_pools') as UntypedFormArray;
  }

  public selectedRoleChanged(roleId: number | null) {
    this.selectedRole = this.roles.find(role => role.id === roleId);
    if (this.selectedRole.is_staff) {
      this.form.get('phone').setValidators([
        Validators.pattern('(6|7|9)([0-9])\\w+'),
        Validators.minLength(9),
        Validators.maxLength(9)]);
    } else if(this.selectedRole.slug === 'creditor') {
      this.form.get('id_card').clearValidators();
      this.form.get('id_card').updateValueAndValidity();
    } else {
      this.form.get('phone').setValidators([
        Validators.required,
        Validators.pattern('(6|7|9)([0-9])\\w+'),
        Validators.minLength(9),
        Validators.maxLength(9)]);
    }
    this.form.get('phone').updateValueAndValidity();
  }

  private getUserDefaultData(): User {
    const user                                        = new User();
    user.phone_country_code                           = '34';
    user.show_transfer_button                         = false;
    user.sale_target                                  = new UserSaleTarget();

    return user;
  }

  private getUser(userId: number) {
    this.isLoading++;
    this.userService.get(userId, {},
      [
        'affiliates',
        'role',
        'sale_target',
        'department_assignments',
        'address',
        'departments',
        'distribution_providers',
        'creditor',
        'paired_lawyer', 'packager'])
      .pipe(finalize(() => this.isLoading--))
      .subscribe(user => {
        this.user         = user.data;
        this.selectedRole = this.user.role;
        this.buildForm(this.user);
      });
  }

  private fetchRoles() {
    this.isLoading++;
    this.userService.indexRoles().pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
        if (this.authUser?.packager?.master) {
          this.roles = result.data;
        } else {
          this.roles = result.data.filter(role => role.slug === 'packager');
        }
      });
  }

  private fetchDepartments() {
    this.isLoading++;
    this.subscriptions.push(
      this.departmentService.categoryIndex(['departments', 'departments.users']).pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
          this.categoryDepartments = result.data;
          this.categoryDepartments.forEach(department => {
            if (department.type === 'drafting-and-judical') {
              if (department.departments[1].type === 'lawyer') {
                this.lawyers = department.departments[1].users;
              }
            }
          });
        }),
    );
  }

  private fetchDistributionProviders() {
    this.isLoading++;
    this.distributionProviderService.index().pipe(finalize(() => this.isLoading--))
      .subscribe(result => this.distributionProviders = result.data);
  }

  private fetchProducts() {
    this.isLoading++;
    this.subscriptions.push(
      this.productService.index({select_all: 1}).pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
          this.products = result.data;
          this.products.forEach(product => {
            const departmentIds = [];
            this.userRoundRobinPools.forEach(userRoundRobinPool => {
              if (+userRoundRobinPool.product_id === +product.id) {
                departmentIds.push(userRoundRobinPool.department_id);
              }
            });
            this.userRoundRobinArray.push(this.fb.group({
              name:           product.name,
              product_id:     product.id,
              department_ids: [departmentIds],
            }));
          });
        }),
    );
  }

  private fetchUserRoundRobinPools() {
    this.isLoading++;
    this.subscriptions.push(
      this.departmentService.getUserRoundRobinPools(+this.userId).pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
          this.userRoundRobinPools = result.data;
          this.fetchProducts();
        }),
    );
  }

  private buildForm(user: User) {
    this.form = this.fb.group({
      role_id:                           [user.role_id, [Validators.required]],
      distribution_provider_ids:         [
        user.distribution_providers ? user.distribution_providers.map(dProvider => dProvider.id) : null,
      ],
      first_name:                        [user.first_name, [Validators.required]],
      last_name:                         [user.last_name],
      password:                          [null, [Validators.minLength(8)]],
      email:                             [
        user.email,
        [Validators.required, Validators.email],
        this.validateEmail.bind(this)],
      id_card:                           [
        user.id_card,
        [Validators.required, Validators.maxLength(9), CustomValidators.idCardPattern()]],
      phone_country_code:                [
        user.phone_country_code ? user.phone_country_code : '34',
        [Validators.required]],
      phone:                             [
        user.phone,
        [
          Validators.required,
          Validators.pattern('(6|7|9)([0-9])\\w+'),
          Validators.minLength(9),
          Validators.maxLength(9),
        ],
      ],
      customer_contact_diary_url:        [user.customer_contact_diary_url],
      diary_url:                         [user.diary_url],
      department_ids:                    [
        user.department_assignments?.map(assignment => assignment.department_id) || []],
      product_round_robin_pools:         this.fb.array([]),
      connex_username:                   [user.connex_username],
      active:                            [user.active],
      show_transfer_button:              [user.show_transfer_button],
      affiliate_ids:                     [user.affiliates?.map(affiliate => affiliate.id)],
      paired_lawyer_id:                  [user.paired_lawyer?.id],
      send_affiliate_login_notification: [false],
      packager_id:                       [user.packager_id],
      creditor_id:                       [user.creditor_id],
      creditor_name:                     [user.creditor?.name],
    });

    if (user.id) {
      this.fetchUserRoundRobinPools();
    }

    // tslint:disable-next-line:max-line-length
    if (!this.isProductionEnv || this.authUser.id === 1 || this.authUser.id === 36 || this.authUser.id === 27 ||
      this.authUser.id === 2403 || this.authUser.id === 34 || this.authUser.id === 22) {
      this.addAdminOnlyFields();
    }
    if (this.form.get('department_ids').value.find(val => val === 2)) {
      this.isLegalAdvisorSelected = true;
    }

    if (this.form.get('department_ids').value.find(val => val === 3)) {
      this.isCaseManagerSelected = true;
    }

    this.form.get('role_id').valueChanges.subscribe(roleId => {
      this.isCreatingCreditor = !this.isCreatingCreditor;
    });

    if(this.user.role?.slug === 'creditor') {
      this.form.get('id_card').clearValidators();
    }

    this.form.get('creditor_id').valueChanges.subscribe(creditorId => {
      const creditor = this.creditors.find(creditor => creditor.id === creditorId);
      if(creditor) {
        this.form.get('first_name').setValue(creditor.name);
        this.form.get('last_name').setValue(creditor.name);
        this.form.get('active').setValue(1);
        this.form.get('phone').setValue(creditor.phone_1 ?? creditor.phone_2);
        this.form.get('email').setValue(creditor.email_negotiations);
      }
    });
  }

  public addAdminOnlyFields() {
    //  Add inputs that allow admins to change password of users
    this.form.addControl('change_password', this.fb.control(''));
    this.form.addControl('repeat_change_password', this.fb.control(''));

    //  Set validators that requires given fields to match in value
    this.form.setValidators(mustMatch('change_password', 'repeat_change_password'));
  }

  public submit(form: UntypedFormGroup) {
    this.serverResponse = null;
    this.formSubmitted  = true;
    const distributionProviderRole = this.roles.find(role => role.slug === 'distribution-provider');
    if (form.get('role_id').value === distributionProviderRole?.id) {
      this.form.controls.distribution_provider_ids.setValidators([Validators.required]);
      this.form.controls.distribution_provider_ids.updateValueAndValidity();
    }

    if (form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const data = form.value;

    form.get('active').patchValue(form.get('active').value === false ? 0 : 1);

    this.submitting = true;
    let observer: Observable<LaravelResourceResponse<User>>;
    if (this.userId) {
      observer = this.userService.update(this.userId, data).pipe(finalize(() => this.submitting = false));
    } else {
      observer = this.userService.store(data).pipe(finalize(() => this.submitting = false));
    }

    observer.subscribe(
      res => this.router.navigate(['/users']),
      error => this.serverResponse = error.error,
    );
  }

  private validateEmail(control: UntypedFormControl): Promise<any> | Observable<any> {
    if (this.emailValidationTimeout) {
      clearTimeout(this.emailValidationTimeout);
    }
    return new Promise<any>((resolve, reject) => {
      this.emailValidationTimeout = setTimeout(() => {
        this.userService.validateField('email', control.value, this.userId).subscribe(
          result => resolve(null),
          err => resolve({unique: err.error.data?.existing_model}),
        );
      }, 500);
    });
  }

  public onDepartmentChange($event) {
    this.isCaseManagerSelected = !!$event.find(val => val === 3) ? true : false;
    if ($event.find(val => val === 2)) {
      this.isLegalAdvisorSelected = true;
    } else {
      this.isLegalAdvisorSelected = false;
    }
  }

  private fetchAffiliates() {
    this.affiliateService.index({all: 1}).subscribe(res => {
      this.allAffiliates = res.data;
    });
  }

  private fetchPackagers() {
    this.isLoading++;
    this.packagerService.index({select_all: 1}).pipe(finalize(() => this.isLoading--))
      .subscribe(
        result => this.packagers = result.data,
      );
  }

  private fetchCreditors() {
    this.isLoading++;
    const requestData = {
      all: 1,
      active: 1,
    }
    this.creditorService.index(requestData).pipe(finalize(() => this.isLoading--))
      .subscribe(
        result => this.creditors = result.data,
      );
  }
}
