<div>
  <div class="d-flex justify-content-between">
    <div class="col-12" *ngIf="form" [formGroup]="form">
      <!-- filters -->
      <div class="d-flex justify-content-between">
        <h2>Cases by Creditor</h2>
      </div>
      <div>
        <div class="row">
          <div class="col-4">
            <app-input type="select" formControlName="creditors" appearance="standard"
                      fullWidth="false" [multiple]="false" [showClear]="true" [selectAll]="false"
                      [label]="'CASES.single.creditors.heading' | translate" [searchable]="true"
                      [selectOptions]="creditors" [selectLabel]="'name'" [selectValue]="'id'">
            </app-input>
          </div>
          <div class="col-8 text-right">
            <button mat-stroked-button color="primary" class="mr-2" (click)="clearFilters()">
              {{"SHARED.reset-filters" | translate}}
            </button>
            <button mat-raised-button color="primary" (click)="searchCases()" type="button">
              Search
            </button>
          </div>
        </div>
      </div>
      <div>
        <div class="row">
          <!-- Case Status -->
          <div class=" col-2">
            <app-input *ngIf="filteredStatusCategories?.length" type="select"
                      [formControlName]="statusFormControlName"
                      appearance="standard" [searchable]="true" [multiple]="statusMultipleSelection"
                      [label]="'CASES.single.general.status_editor.status' | translate"
                      [selectOptions]="filteredStatusCategories" [optGroupProperty]="'statuses'"
                      [optGroupLabel]="'name'"
                      [selectLabel]="'name'" [selectValue]="'id'" class="full-width"
                      [showClear]="false" [fullWidth]="true">
            </app-input>
          </div>
          <!-- Case Creditor Status -->
          <div class=" col-2">
            <app-input type="select" formControlName="case_creditor_statuses" appearance="standard" [searchable]="true"
                    [label]="'CONFIG.drip-campaign.list.table-data.statusable_type.case_creditor_status' | translate" [multiple]="true" [selectAll]="true" [fullWidth]="true" [selectAll]="true"
                    [selectOptions]="caseCreditorStatuses" [selectLabel]="authUser.locale === 'es' ? 'name_es' : 'name_en'" [selectValue]="'id'">
            </app-input>
          </div>
          <div class=" col-2">
            <app-input type="number" formControlName="dm_percent_accepted_greater" [label]="'CASES.list.filters.dm_percent_accepted.greater' | translate" appearance="standard"
                      [fullWidth]="true">
            </app-input>
          </div>
          <div class=" col-2">
            <app-input type="number" formControlName="dm_percent_accepted_less" [label]="'CASES.list.filters.dm_percent_accepted.less' | translate" appearance="standard"
                      [fullWidth]="true">
            </app-input>
          </div>
          <!-- <div class=" col-2">
            <app-input type="number" formControlName="number_of_creditors" [label]="'NAVBAR.creditors' | translate" appearance="standard"
                      [fullWidth]="true">
            </app-input>
          </div> -->
          <!-- Amount Paid Greater Then-->
          <div class=" col-2">
            <app-input type="number" formControlName="amount_paid" [label]="'CASES.single.amount-paid' | translate" appearance="standard"
                      [fullWidth]="true">
            </app-input>
          </div>
          <!-- Micro Lenders -->
          <div class=" col-2">
            <app-input type="select" appearance="standard" [label]="'CASES.list.filters.has_microlenders.label' | translate"
                       formControlName="has_microlenders" [showClear]="true" [showLabel]="true" [fullWidth]="true"
                       [selectOptions]="hasYesNoOptions" [selectLabel]="'label'" [selectValue]="'value'">
            </app-input>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--If all are selected and there is more to select on another page -->
  <div *ngIf="filtersReady && caseListFilter && pageSelected && (totalPages > 1)" class="row mb-3">
    <div class="col-12">
      <div class="global-select-all">
        <p class="m-0" *ngIf="!caseListFilter.select_all">
          Selected <b>{{ selection.selected.length }}</b> cases on this page.
          <button mat-button color="primary" (click)="globalSelectAll($event)">
            Select all {{ totalResults | number }} cases
          </button>
        </p>
        <p class="m-0" *ngIf="caseListFilter.select_all">
          All <b>{{ totalResults | number }}</b> cases selected.
          <button mat-button color="primary" (click)="globalClearSelectAll($event)">Clear selection</button>
        </p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="mat-elevation-z8">
        <table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="dataSource" width="100%">
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="['/cases', row.id, 'creditors']"></tr>
          <!-- Selection -->
          <ng-container matColumnDef="select">
            <!-- Select All On Page -->
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox color="primary" (change)="$event ? togglePageSelect($event) : null"
                            [checked]="selection.hasValue() && allRowsSelected()"
                            [indeterminate]="selection.hasValue() && !allRowsSelected()">
              </mat-checkbox>
            </th>
            <!-- Select Row -->
            <td mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()" color="primary" [checked]="selection.isSelected(row)"
                            (change)="toggleRow($event, row)">
              </mat-checkbox>
            </td>
          </ng-container>
          <!-- Ref Number -->
          <ng-container matColumnDef="ref_number">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CASES.model.ref_number" | translate }}</th>
            <td mat-cell *matCellDef="let element">
              <a class="pr-3" (click)="$event.stopPropagation()" [routerLink]="['/cases', element.id, 'general']">
                {{ element.ref_number }}</a></td>
          </ng-container>
          <!-- Payment Status -->
          <ng-container matColumnDef="payment_status">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              {{ 'CASES.single.general.status_editor.payment_status' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <div>
                <span class="badge badge-info">{{ element?.payment_status?.name }}</span>
              </div>
            </td>
          </ng-container>
          <!-- Microlenders -->
          <ng-container matColumnDef="microlenders">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'CASES.microlenders' | translate}}</th>
            <td mat-cell *matCellDef="let element">
                <mat-icon *ngIf="element.microlenders" style="color: rgb(0, 80, 0)" class="mat-icon">check</mat-icon>
                <!-- <mat-icon *ngIf="!element.microlenders" style="color: rgb(98, 0, 0)" class="mat-icon">clear</mat-icon> -->
            </td>
          </ng-container>
          <!-- Case Creditor Status -->
          <ng-container matColumnDef="case_creditor_status">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              {{ 'CONFIG.drip-campaign.list.table-data.statusable_type.case_creditor_status' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <div *ngFor="let casecreditor of element.creditors; let i = index">
                <div *ngIf="caseListFilter.creditors?.[0] == casecreditor.pivot.creditor_id">
                  <span class="badge badge-info">{{ casecreditor.pivot?.status?.name_es }}</span>
                </div>
              </div>
            </td>
          </ng-container>
          <!-- Reduction -->
          <ng-container matColumnDef="dm_percent_accepted">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CASE_CREDITOR.model.percent_accepted" | translate }}</th>
            <td mat-cell *matCellDef="let element">
              <div>
                <p class="m-0">{{(element.dm_accepted).toFixed(2)}} %</p>
              </div>
            </td>
          </ng-container>
          <!-- Amount of creditors -->
          <ng-container matColumnDef="no_of_creditors">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'DASHBOARD.number-of-creditors' | translate }}</th>
            <td mat-cell *matCellDef="let element">
              {{creditorCount(element.creditors)}}
            </td>
          </ng-container>
          <!-- Amount Paid -->
          <ng-container matColumnDef="amount_paid">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CASES.amount_paid" | translate }}</th>
            <td mat-cell *matCellDef="let element" class="pr-2">
              {{ element.amount_paid | currency: "EUR" }}
            </td>
          </ng-container>
        </table>
        <!-- Pagination -->
        <mat-paginator [length]="paginatorConfig.length" [pageSize]="paginatorConfig.pageSize" showFirstLastButtons
                      [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="paginatorChange($event)">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>

<!-- Footer Actions -->
<div class="mt-2 pb-3 pl-1"
     *ngIf="authUser.role_id === 1 || authUser.role_id === 5 || authUser.role_id === 7
      || authUser.id === 678731 || authUser.id === 2597 || authUser.id === 704999">
  <!-- Export Customer Contact-->
  <button
    *ngIf="authUser.role_id === 1 || authUser.role_id === 2 || authUser.role_id === 5 || authUser.role_id === 7"
    mat-raised-button color="primary"
    (click)="exportRecordsCustomerContact(caseListFilter)" class="mr-3">
    {{ "CASES.list.export.button-name" | translate }}
  </button>
  <!-- Send extrajudicial claims -->
  <button
    *ngIf="allowedUsers.includes(authUser.id)" mat-raised-button color="primary"
    (click)="sendExtrajudicialClaims(caseListFilter)" class="mr-3">
    {{ "CASES.single.creditors.actions.extrajudicial_claim" | translate }}
  </button>
</div>