import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { AppSelectOption } from '../../../../../../../_base-shared/contracts/common.interface';
import { Affiliate } from '../../../../../../../_base-shared/models/Affiliate';
import { CaseListFilter } from '../../../../../../../_base-shared/models/Case/CaseListFilter';
import { DepartmentCategory } from '../../../../../../../_base-shared/models/Department/DepartmentCategory';
import { Court } from '../../../../../../../_base-shared/models/Entity/Court';
import { Creditor } from '../../../../../../../_base-shared/models/Entity/Creditor';
import { EntityAdministrator } from '../../../../../../../_base-shared/models/Entity/EntityAdministrator';
import { Notary } from '../../../../../../../_base-shared/models/Entity/Notary';
import { Solicitor } from '../../../../../../../_base-shared/models/Entity/Solicitor';
import { Packager } from '../../../../../../../_base-shared/models/Packager/Packager';
import { Product } from '../../../../../../../_base-shared/models/Product';
import { CallStatus } from '../../../../../../../_base-shared/models/Status/CallStatus';
import { CaseInvoiceStatus } from '../../../../../../../_base-shared/models/Status/CaseInvoiceStatus';
import { PackagerStatus } from '../../../../../../../_base-shared/models/Status/PackagerStatus';
import { PaymentStatus } from '../../../../../../../_base-shared/models/Status/PaymentStatus';
import { Status } from '../../../../../../../_base-shared/models/Status/Status';
import { StatusCategory } from '../../../../../../../_base-shared/models/Status/StatusCategory';
import { Team } from '../../../../../../../_base-shared/models/User/Team';
import { User } from '../../../../../../../_base-shared/models/User/User';
import { environment } from '../../../../../environments/environment';
import { MainGlobalEventService } from '../../../../_shared/services/main-global-event.service';
import { PackagerService } from '../../../../packager/packager.service';
import { AdministratorsService } from '../../../address-book/administrators/administrators.service';
import { CourtService } from '../../../address-book/court/court.service';
import { NotariesService } from '../../../address-book/notaries/notaries.service';
import { SolicitorsService } from '../../../address-book/solicitors/solicitors.service';
import { AdminPackagerService } from '../../../admin-packager/admin-packager.service';
import { AffiliateService } from '../../../affiliate/affiliate.service';
import { CallStatusService } from '../../../call-status/call-status.service';
import { CreditorService } from '../../../creditor/creditor.service';
import { DepartmentService } from '../../../department/department.service';
import { PaymentStatusService } from '../../../payment-status/payment-status.service';
import { StatusPickerTrait } from '../../../status/status-picker.trait';
import { StatusService } from '../../../status/status.service';
import { TeamService } from '../../../team/team.service';
import { CaseCreditorService } from '../../case-creditor.service';
import { ProductService } from '../../product.service';
import { el } from 'date-fns/locale';

@Component({
  selector:    'app-case-list-filters',
  templateUrl: './case-list-filters.component.html',
  styleUrls:   ['./case-list-filters.component.scss'],
})
export class CaseListFiltersComponent extends StatusPickerTrait implements OnInit, OnDestroy {
  @Input() onlyRelated: boolean                                                                                                = null;
  @Input() type: 'case' | 'legal' | 'customer_contact' | 'affiliate_case' | 'packager_non_transferred' | 'creditor_negotiator' = null;

  @Output() filtersReady  = new EventEmitter<boolean>();
  @Output() submitFilters = new EventEmitter<any>();

  @Output("parentFilter") parentFilter: EventEmitter<any>             = new EventEmitter();
  @Output("parentNewCase") parentNewCase: EventEmitter<any>           = new EventEmitter();
  @Output("parentClearFilters") parentClearFilters: EventEmitter<any> = new EventEmitter();
  @Output("acceptPlans") acceptPlans: EventEmitter<any>               = new EventEmitter();

  public isLoading                                 = 0;
  public form: UntypedFormGroup;
  public statusFormControlName                     = 'statuses';
  public docStatusFormControlName                  = 'doc_statuses';
  public dateRadioControl: UntypedFormControl      = new UntypedFormControl();
  public statusCategoryControl: UntypedFormControl = new UntypedFormControl([]);
  public statusControl: UntypedFormControl         = new UntypedFormControl([]);

  public caseListFilter: CaseListFilter;
  public users: Array<User>                              = [];
  public products: Array<Product>                        = [];
  public affiliates: Array<Affiliate>                    = [];
  public creditors: Array<Creditor>                      = [];
  public allStatuses: Array<Status>                      = [];
  public statusCategories: Array<StatusCategory>         = [];
  public filteredStatusCategories: Array<StatusCategory> = [];
  public paymentStatuses: Array<PaymentStatus>           = [];
  public callStatuses: Array<CallStatus>                 = [];
  public invoiceStatuses: Array<CaseInvoiceStatus>       = [];
  public notaries: Array<Notary>                         = [];
  public administrators: Array<EntityAdministrator>      = [];
  public courts: Array<Court>                            = [];
  public solicitors: Array<Solicitor>                    = [];
  public teams: Array<Team>                              = [];
  public departmentCategories: Array<DepartmentCategory> = [];

  public notificationChannels: Array<AppSelectOption>                = [];
  public productTypeGroups: Array<AppSelectOption>                   = [];
  public affiliateUsers: Array<User>                                 = [];
  public debtStatusOptions: Array<AppSelectOption>                   = [];
  public caseCreditorStatuses: Array<any>                            = [];
  public docStatusOptions: Array<AppSelectOption>                    = [];
  public packagers: Array<Packager>                                  = [];
  public packagerStatuses: Array<PackagerStatus>                     = [];
  public digitalSignatureOptions: Array<AppSelectOption>             = [];
  public hasPropertyOptions: Array<{ label: string, value: number }> = [];
  public hasSoldDebtOptions: Array<{ label: string, value: number }> = [];
  public hasYesNoOptions: Array<{ label: string, value: number }>    = [];
  public isUserCreditorNegotiator: boolean                           = false;

  public caseCreditorProducts: Array<Product> = [];

  private formChangeSubscriber: Subscription;
  private localStorageName: string;
  public authUser: User;

  constructor(private fb: UntypedFormBuilder,
              private globalEventsService: MainGlobalEventService,
              private translate: TranslateService,
              private statusService: StatusService,
              private creditorService: CreditorService,
              private notaryService: NotariesService,
              private administratorsService: AdministratorsService,
              private courtService: CourtService,
              private solicitorsService: SolicitorsService,
              private paymentStatusService: PaymentStatusService,
              private affiliateService: AffiliateService,
              private departmentService: DepartmentService,
              private productService: ProductService,
              private teamService: TeamService,
              private callStatusService: CallStatusService,
              private packagerService: AdminPackagerService,
              private generalPackagerService: PackagerService,
              private caseCreditorService: CaseCreditorService) {
    super('statuses', true, true);
  }

  ngOnInit(): void {
    if (this.type === 'legal') {
      this.localStorageName = 'case-legal-list-filters_v5.0.0';
    } else if (this.type === 'customer_contact') {
      this.localStorageName = 'case-customer_contact-list-filters_v5.0.0';
    } else if (this.type === 'affiliate_case') {
      this.localStorageName = 'affiliate-case-list-filters_v5.0.0';
    } else if (this.type === 'packager_non_transferred') {
      this.localStorageName = 'packager_non_transferred-case-list-filters_v5.0.0';
    } else {
      this.localStorageName = 'case-list-filters_v5.0.0';
    }
    this.debtStatusOptions = this.getDebtStatusOptions();
    // todo: get filters from query string
    this.caseListFilter    = this.getFiltersFromStorage();
    this.filtersReady.emit(false);
    this.subscriptions.push(this.globalEventsService.authUser$.subscribe(user => {
      this.authUser = user;
      if (this.authUser.department_assignments.find(
        departmentAssignment => departmentAssignment.department.name === 'Creditor Negotiator')
      ) {
        this.onlyRelated              = false;
        this.isUserCreditorNegotiator = true;
      }
      // this.caseListFilter.only_related = this.onlyRelated !== null ?
      //   this.onlyRelated :
      //   this.caseListFilter.only_related;
      // if (this.caseListFilter.only_related) {
      //   this.caseListFilter.user_department_assignments = this.authUser.department_assignments.map(
      //     departmentAssignment => departmentAssignment.department_id,
      //   );
      // }
      this.buildForm(this.caseListFilter, this.authUser);
    }));

    this.fetchDepartmentCategories();
    this.fetchProducts();
    this.fetchAffiliates();
    this.fetchCreditors();
    this.fetchStatuses();
    this.fetchPaymentStatuses();
    this.fetchCallStatuses();
    this.fetchCaseInvoiceStatuses();
    this.fetchNotaries();
    this.fetchAdministrators();
    this.fetchCourt();
    this.fetchSolicitors();
    this.fetchTeams();
    this.buildSelectOptions();
    this.getAffiliateUsers();
    this.productTypeGroups = this.getProductTypeGroups();
    this.fetchPackagers();
    this.fetchPackagerStatuses();
    this.buildHasPropertyOptions();
    this.buildHasSoldDebtOptions();
    this.buildHasYesNoOptions();
    this.getCreditorStatusOptions();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    this.formChangeSubscriber.unsubscribe();
  }

  private getFiltersFromStorage(): CaseListFilter {
    const filter                             = new CaseListFilter();
    let data                                 = JSON.parse(localStorage.getItem(this.localStorageName));
    data                                     = data ? data : {};
    filter.search                            = data.search ? data.search : null;
    filter.activity_status                   = data.activity_status !== undefined ? data.activity_status :
      (this.type === 'customer_contact' || 'creditor_negotiator' ? 'active' : 'all');
    filter.start_date                        = data.start_date ? new Date(data.start_date) : null;
    filter.end_date                          = data.end_date ? new Date(data.end_date) : null;
    filter.status_date_type                  = data.status_date_type ? data.status_date_type : 'sign_up';
    filter.amount_paid_greater_then          = data.amount_paid_greater_then ? data.amount_paid_greater_then : null;
    filter.percentage_reduction_less_then    = data.percentage_reduction_less_then ? data.percentage_reduction_less_then : null;
    filter.percentage_reduction_greater_then = data.percentage_reduction_greater_then ? data.percentage_reduction_greater_then : null;
    filter.amount_paid_less_then             = data.amount_paid_less_then ? data.amount_paid_less_then : null;
    filter.debt_level_greater_then           = data.debt_level_greater_then ? data.debt_level_greater_then : null;
    filter.debt_level_less_then              = data.debt_level_less_then ? data.debt_level_less_then : null;
    filter.outstanding_balance               = data.outstanding_balance ? data.outstanding_balance : null;
    filter.has_property                      = data.has_property !== undefined ? data.has_property : null;
    filter.has_sold_debt                     = data.has_sold_debt !== undefined ? data.has_sold_debt : null;
    filter.has_microlenders                  = data.has_microlenders !== undefined ? data.has_microlenders : 0;
    filter.seventy_percent_reduction         = data.seventy_percent_reduction !== undefined ? data.seventy_percent_reduction : 0;
    filter.dm_percent_accepted_less          = data.dm_percent_accepted_less !== undefined ? data.dm_percent_accepted_less : null;
    filter.dm_percent_accepted_greater       = data.dm_percent_accepted_greater !== undefined ? data.dm_percent_accepted_greater : null;
    filter.has_imported_creditors            = data.has_imported_creditors !== undefined ? data.has_imported_creditors : 0;
    filter.case_creditor_statuses            = data.case_creditor_statuses ? data.case_creditor_statuses : [];
    filter.region                            = data.region ? data.region : null;
    filter.city                              = data.city ? data.city : null;
    filter.select_all                        = data.select_all !== undefined ? data.select_all : 0;
    // filter.only_related                       = data.only_related !== undefined ? data.only_related : 0;
    // Case General Relations
    filter.product                            = data.product !== undefined ? data.product : 'all';
    filter.affiliates                         = data.affiliates ? data.affiliates : [];
    filter.statuses                           = data.statuses ? data.statuses : [];
    filter.payment_statuses                   = data.payment_statuses ? data.payment_statuses : [];
    filter.call_statuses                      = data.call_statuses ? data.call_statuses : [];
    filter.creditors                          = data.creditors ? data.creditors : [];
    filter.doc_statuses                       = data.doc_statuses ? data.doc_statuses : [];
    // Case Roles
    filter.user_department_assignments        = data.user_department_assignments ? data.user_department_assignments : [];
    filter.verifier                           = data.verifier ? data.verifier : [];
    filter['legal-advisor']                   = data['legal-advisor'] ? data['legal-advisor'] : [];
    filter['case-manager']                    = data['case-manager'] ? data['case-manager'] : [];
    filter['customer-contact']                = data['customer-contact'] ? data['customer-contact'] : [];
    filter['creditor-negotiator']             = data['creditor-negotiator'] ? data['creditor-negotiator'] : [];
    filter['notary-manager']                  = data['notary-manager'] ? data['notary-manager'] : [];
    filter.lawyer                             = data.lawyer ? data.lawyer : [];
    filter['draft-manager']                   = data['draft-manager'] ? data['draft-manager'] : [];
    filter['collections-agent']               = data['collections-agent'] ? data['collections-agent'] : [];
    filter['unifye-creditor-negotiator']      = data['unifye-creditor-negotiator'] ?
      data['unifye-creditor-negotiator'] :
      [];
    filter['unifye-finance-advisor']          = data['unifye-finance-advisor'] ? data['unifye-finance-advisor'] : [];
    filter['unifye-personal-finance-manager'] = data['unifye-personal-finance-manager'] ?
      data['unifye-personal-finance-manager'] :
      [];
    // Case Entities
    filter.notaries                           = data.notaries ? data.notaries : [];
    filter.administrators                     = data.administrators ? data.administrators : [];
    filter.courts                             = data.courts ? data.courts : [];
    filter.solicitors                         = data.solicitors ? data.solicitors : [];
    filter.notary_appointed                   = data.notary_appointed !== undefined ? data.notary_appointed : null;
    // Extra
    filter.days_no_contact                    = data.days_no_contact ? data.days_no_contact : null;
    filter.days_no_contact_channels           = data.days_no_contact_channels ? data.days_no_contact_channels : [];
    filter.product_group                      = data.product_group ? data.product_group : [];
    filter.packagers                          = data.packagers ? data.packagers : [];
    filter.packager_statuses                  = data.packager_statuses ? data.packager_statuses : [];
    filter.product_ids                        = data.product_ids ? data.product_ids : [];
    filter.has_redsys_has_no_cashflows_token  = data.has_redsys_has_no_cashflows_token !== undefined ? data.has_redsys_has_no_cashflows_token : 0
    filter.days_since_last_neg_note           = data.days_since_last_neg_note ? data.days_since_last_neg_note : null;
    if (this.type === 'case' || this.type === 'affiliate_case' || this.type === 'packager_non_transferred') {
      filter.page_type = this.type;
    }

    return filter;
  }

  private buildForm(caseListFilter: CaseListFilter, authUser: User): void {
    if (this.formChangeSubscriber) {
      this.formChangeSubscriber.unsubscribe();
    }

    this.form = this.fb.group({
      search:                            [caseListFilter.search],
      start_date:                        [caseListFilter.start_date],
      end_date:                          [caseListFilter.end_date],
      activity_status:                   [caseListFilter.activity_status],
      status_date_type:                  [caseListFilter.status_date_type],
      amount_paid_greater_then:          [caseListFilter.amount_paid_greater_then],
      percentage_reduction_less_then:    [caseListFilter.percentage_reduction_less_then],
      percentage_reduction_greater_then: [caseListFilter.percentage_reduction_greater_then],
      amount_paid_less_then:             [caseListFilter.amount_paid_less_then],
      debt_level_greater_then:           [caseListFilter.debt_level_greater_then],
      debt_level_less_then:              [caseListFilter.debt_level_less_then],
      outstanding_balance:               [caseListFilter.outstanding_balance],
      has_property:                      [caseListFilter.has_property],
      has_sold_debt:                     [caseListFilter.has_sold_debt],
      region:                            [caseListFilter.region],
      city:                              [caseListFilter.city],
      affiliates:                        [caseListFilter.affiliates],
      case_creditor_statuses:            [caseListFilter.case_creditor_statuses],
      product:                           [caseListFilter.product],
      creditors:                         [caseListFilter.creditors],
      doc_statuses:                      [caseListFilter.doc_statuses],
      statuses:                          [caseListFilter.statuses],
      payment_statuses:                  [caseListFilter.payment_statuses],
      call_statuses:                     [caseListFilter.call_statuses],
      has_microlenders:                  [caseListFilter.has_microlenders],
      seventy_percent_reduction:         [caseListFilter.seventy_percent_reduction],
      dm_percent_accepted_less:          [caseListFilter.dm_percent_accepted_less],
      dm_percent_accepted_greater:       [caseListFilter.dm_percent_accepted_greater],
      has_imported_creditors:            [caseListFilter.has_imported_creditors],
      select_all:                        [caseListFilter.select_all],
      // only_related:                [caseListFilter.only_related],
      // Case Roles
      user_department_assignments:       [caseListFilter.user_department_assignments],
      verifier:                          [caseListFilter.verifier],
      'legal-advisor':                   [caseListFilter['legal-advisor']],
      'case-manager':                    [caseListFilter['case-manager']],
      'customer-contact':                [caseListFilter['customer-contact']],
      'creditor-negotiator':             [caseListFilter['creditor-negotiator']],
      'notary-manager':                  [caseListFilter['notary-manager']],
      lawyer:                            [caseListFilter.lawyer],
      'draft-manager':                   [caseListFilter['draft-manager']],
      'collections-agent':               [caseListFilter['collections-agent']],
      'unifye-creditor-negotiator':      [caseListFilter['unifye-creditor-negotiator']],
      'unifye-finance-advisor':          [caseListFilter['unifye-finance-advisor']],
      'unifye-personal-finance-manager': [caseListFilter['unifye-personal-finance-manager']],
      // Case Entities
      notaries:         [caseListFilter.notaries],
      administrators:   [caseListFilter.administrators],
      courts:           [caseListFilter.courts],
      solicitors:       [caseListFilter.solicitors],
      notary_appointed: [caseListFilter.notary_appointed],
      // Extra
      days_no_contact:                   [caseListFilter.days_no_contact],
      days_no_contact_channels:          [caseListFilter.days_no_contact_channels],
      page_type:                         [this.type],
      product_group:                     [caseListFilter.product_group],
      packagers:                         [caseListFilter.packagers],
      packager_statuses:                 [caseListFilter.packager_statuses],
      product_ids:                       [caseListFilter.product_ids],
      new_partner:                       [caseListFilter.new_partner],
      has_redsys_has_no_cashflows_token: [caseListFilter.has_redsys_has_no_cashflows_token],
      days_since_last_neg_note:          [caseListFilter.days_since_last_neg_note],
    });

    this.filtersReady.emit(true);
    this.submitFilters.emit(this.caseListFilter);
    this.subscribeToFormChanges();
  }

  private subscribeToFormChanges() {
    this.formChangeSubscriber = this.form.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
    ).subscribe(res => {
      if (this.form.invalid) {
        return;
      }
      this.caseListFilter = this.form.value;
      // TODO: update filters in query string
      this.storeFiltersToStorage(this.caseListFilter);
      this.submitFilters.emit(this.caseListFilter);
    });
  }

  private fetchNotaries() {
    this.notaryService.index({select_all: 1}).subscribe(next => {
      this.notaries = next.data;
    });
  }

  private fetchAdministrators() {
    this.administratorsService.index({select_all: 1}).subscribe(next => {
      this.administrators = next.data;
    });
  }

  private fetchCourt() {
    this.courtService.index({select_all: 1}).subscribe(next => {
      this.courts = next.data;
    });
  }

  private fetchSolicitors() {
    this.solicitorsService.index({select_all: 1}).subscribe(next => {
      this.solicitors = next.data;
    });
  }

  private fetchDepartmentCategories() {
    this.subscriptions.push(
      this.departmentService.categoryIndex(['departments.users']).pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
          this.departmentCategories = result.data.filter(item => {
            item.departments = item.departments.filter(department => department.type !== 'verifier' && department.type !== 'financial-controller' && department.type !== 'collections-agent');

            return item.departments.length > 0;
          });
        }),
    );
  }

  private fetchProducts(): void {
    this.isLoading++;
    this.subscriptions.push(
      this.productService.index({select_all: 1}).pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.products = result.data),
    );
  }

  private fetchAffiliates(): void {
    this.isLoading++;
    this.subscriptions.push(
      this.affiliateService.index({all: 1}).pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.affiliates = result.data),
    );
  }

  private fetchCreditors(): void {
    this.isLoading++;
    this.subscriptions.push(
      this.creditorService.index({all: 1, active: 1}).pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.creditors = result.data),
    );
  }

  private fetchStatuses(): void {
    this.isLoading++;
    this.subscriptions.push(
      this.statusService.indexCategoriesWithStatuses().pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
            this.statusCategories         = result.data
            // if status_id is 2 add to docs status options
            this.docStatusOptions         = this.statusCategories.find(category => category.id === 405).statuses.map(status => {
              return {label: status.name, value: status.id};
            });
            this.statusCategories         = this.statusCategories.filter(category => category.id !== 405);
            this.filteredStatusCategories = result.data;
            this.statusCategories.forEach(category => {
              this.allStatuses.push(category);
              category.statuses.forEach(status => this.allStatuses.push(status));
            });
            this.setStatusControls(this.form.get(this.statusFormControlName).value);
          },
        ),
    );
  }

  private fetchPaymentStatuses(): void {
    this.isLoading++;
    this.subscriptions.push(
      this.paymentStatusService.index({all: 1}).pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.paymentStatuses = result.data),
    );
  }

  private fetchCallStatuses() {
    this.isLoading++;
    this.subscriptions.push(this.callStatusService.index().pipe(finalize(() => this.isLoading--))
      .subscribe(result => this.callStatuses = result.data),
    );
  }

  private fetchCaseInvoiceStatuses() {
    this.isLoading++;
    this.subscriptions.push(this.statusService.indexInvoiceStatuses().pipe(finalize(() => this.isLoading--))
      .subscribe(result => this.invoiceStatuses = result.data),
    );
  }

  private fetchTeams() {
    this.isLoading++;
    this.subscriptions.push(
      this.teamService.index({select_all: 1}).pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.teams = result.data),
    );
  }

  private buildSelectOptions(): void {
    this.notificationChannels = [
      {label: 'Email', value: 'email'},
      {label: 'SMS', value: 'sms'},
      {label: 'Call', value: 'call'},
      {label: 'WhatsApp', value: 'whatsapp'},
    ];
  }

  public clearFilters(): void {
    localStorage.removeItem(this.localStorageName);
    this.caseListFilter = this.getFiltersFromStorage();
    this.filtersReady.emit(false);
    this.buildForm(this.caseListFilter, this.authUser);
    this.dateRadioControl.patchValue(null);
    this.statusCategoryControl.patchValue([]);
    this.statusControl.patchValue([]);
  }

  public patchFilter(name: string, value, options = {}): void {
    this.form.get(name).patchValue(value, options);
  }

  private storeFiltersToStorage(filter: CaseListFilter): void {
    return localStorage.setItem(this.localStorageName, JSON.stringify(filter));
  }

  public clearFormControl($event, name) {
    $event.preventDefault();
    $event.stopPropagation();
    this.form.get(name).patchValue(null);
  }

  public clearMultiSelect($event, name: string) {
    $event.stopPropagation();
    this.form.get(name).patchValue([]);
  }

  public dateModifierChange($event) {
    this.form.get('start_date').setValue(DateTime.local().startOf($event.value).toJSDate());
    this.form.get('end_date').setValue(DateTime.local().endOf($event.value).toJSDate());
  }

  // public onlyRelatedChange($event: any): void {
  //   const myDepartmentAssignments = $event ?
  //     this.authUser.department_assignments.map(departmentAssignment => departmentAssignment.department_id) :
  //     [];
  //   this.form.get('user_department_assignments').patchValue(myDepartmentAssignments);
  //   if ($event) {
  //     this.form.get('user_department_assignments').disable({onlySelf: true, emitEvent: false});
  //   } else {
  //     this.form.get('user_department_assignments').enable({onlySelf: true, emitEvent: false});
  //   }
  // }

  public dateChanged($event: MatDatepickerInputEvent<any>, formControlName, endDay = false) {
    if (endDay) {
      const inputValue = $event.value.toString();
      const dt         = DateTime.fromJSDate(new Date(inputValue));
      const endOfDay   = dt.endOf('day');
      const date       = endOfDay.toJSDate();
      this.form.get(formControlName).patchValue(date);
    }
  }

  public selectStatuses(active: boolean) {
    const selectedStatusIds = [];
    this.allStatuses.forEach(status => {
      if (((active && status.flag_case_active) || ( ! active && ! status.flag_case_active))) {
        selectedStatusIds.push(status.id);
      }
    });
    this.setStatusControls(selectedStatusIds);
    this.form.get(this.statusFormControlName).updateValueAndValidity();
  }

  public userIsAMami(): boolean {
    return environment.DISTRIBUTION_USER_IDS.includes(this.authUser.id);
  }

  private getAffiliateUsers(): void {
    this.isLoading++;
    this.subscriptions.push(
      this.affiliateService.getAffiliateUsers({select_all: 1}).pipe(finalize(() => this.isLoading--)).subscribe(
        result => this.affiliateUsers = result.data,
        error => console.log(error),
      ),
    );
  }

  private fetchPackagers(): void {
    this.isLoading++;
    this.subscriptions.push(
      this.packagerService.index({select_all: 1}).pipe(finalize(() => this.isLoading--))
        .subscribe(
          result => this.packagers = result.data,
        ),
    );
  }

  private fetchPackagerStatuses(): void {
    this.isLoading++;
    this.subscriptions.push(
      this.generalPackagerService.getPackagerStatuses().pipe(finalize(() => this.isLoading--))
        .subscribe(
          result => {
            this.packagerStatuses = result.data;
          },
        ),
    );
  }

  public buildHasPropertyOptions(): void {
    this.hasPropertyOptions = [
      {
        label: this.translate.instant('CASES.list.filters.has_property.options.has_property'),
        value: 2
      },
      {
        label: this.translate.instant('CASES.list.filters.has_property.options.doesnt_have_property'),
        value: 1
      }
    ];
  }

  public buildHasSoldDebtOptions(): void {
    this.hasSoldDebtOptions = [
      {
        label: this.translate.instant('CASES.list.filters.has_sold_debt.options.has_sold_debt'),
        value: 2
      },
      {
        label: this.translate.instant('CASES.list.filters.has_sold_debt.options.doesnt_have_sold_debt'),
        value: 1
      }
    ];
  }

  public buildHasYesNoOptions(): void {
    this.hasYesNoOptions = [
      {
        label: this.translate.instant('SHARED.yes'),
        value: 2
      },
      {
        label: this.translate.instant('SHARED.no'),
        value: 1
      }
    ];
  }

  public getDebtStatusOptions(): Array<AppSelectOption> {
    return [
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.mandate_sent'),
        value: 'mandate_sent',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.chase_required'),
        value: 'chase_required',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.mandate_rejected'),
        value: 'mandate_rejected',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.balance_received'),
        value: 'balance_received',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.balance_outstanding'),
        value: 'balance_outstanding',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.proposal_sent'),
        value: 'proposal_sent',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.proposal_rejected'),
        value: 'proposal_rejected',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.modification_required'),
        value: 'modification_required',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.proposal_accepted'),
        value: 'proposal_accepted',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.extrajudicial_claim_sent'),
        value: 'extrajudicial_claim_sent',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.extrajudicial_claim_accepted'),
        value: 'extrajudicial_claim_accepted',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.extrajudicial_claim_rejected'),
        value: 'extrajudicial_claim_rejected',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.extrajudicial_claim_unanswered'),
        value: 'extrajudicial_claim_unanswered',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.claim_filed'),
        value: 'claim_filed',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.claim_approved'),
        value: 'claim_approve',
      },
      {
        label: this.translate.instant('CASE_CREDITOR.model.response_received.options.claim_rejected'),
        value: 'claim_rejected',
      },
    ];
  }

  private getCreditorStatusOptions(): void {
    this.caseCreditorService.getCaseCreditorStatuses({select_all: 1})
      .pipe(finalize(() => this.isLoading--))
      .subscribe(
        res => {
          if(this.authUser.locale === 'en') {
            this.caseCreditorStatuses = res.data.sort((a,b) => a.name_en.localeCompare(b.name_en));
          } else {
            this.caseCreditorStatuses = res.data.sort((a,b) => a.name_es.localeCompare(b.name_es));
          }
      });
  }

  private getProductTypeGroups(): Array<AppSelectOption> {
    return [
      {label: 'LSO', value: 'lso'},
      {label: 'DM', value: 'dm'},
      {label: 'DGS', value: 'dgs'},
    ];
  }

  public productGroupUpdated(productGroupSlugs: Array<string>): void {
    const productIds = [];
    productGroupSlugs.forEach(groupSlug => {
      this.products.forEach(product => {
        if (product.group_slug === groupSlug) {
          productIds.push(product.id);
        }
      });
    });
    this.form.get('product_ids').patchValue(productIds);
  }
}
