<!-- BEGIN Page Header -->
<header class="page-header" role="banner">
  <div class="ml-auto d-flex">
    <!-- app user menu -->
    <div class="dm_aceptada" *ngIf="authUser && authUser.role?.is_staff">
      {{ 'NAVBAR.dm_accepted' | translate }}&nbsp;&nbsp;<b>{{cases}}</b>
    </div>

    <div>
      <button mat-icon-button [matMenuTriggerFor]="userMenu" title="{{ authUser.email }}"
              class="header-icon d-flex align-items-center justify-content-center">
        <img src="assets/img/avatars/avatar-admin.png" class="profile-image rounded-circle" alt="User">
      </button>
      <mat-menu #userMenu="matMenu" class="userMenu">
        <ng-container *ngIf="authUser; else notLoggedIn">
          <div class="dropdown-header bg-trans-gradient d-flex flex-row px-2 px-md-4 py-4 rounded-top">
            <div class="d-flex flex-column flex-md-row align-items-center mt-1 mb-1 color-white">
              <div class="mr-0 mr-md-2">
                <img src="assets/img/avatars/avatar-admin.png" class="rounded-circle profile-image" alt="User">
              </div>
              <div class="info-card-text">
                <div class="fs-lg text-truncate text-truncate-lg">{{ authUser.name }}</div>
                <span class="text-truncate text-truncate-md opacity-80">{{ authUser.email }}</span>
              </div>
            </div>
          </div>

          <mat-divider></mat-divider>

          <ng-container *ngIf="authUser.role.is_staff; else regularUser">
            <a mat-menu-item [routerLink]="['user-settings']">
              <span>Profile Settings</span>
            </a>
          </ng-container>

          <ng-template #regularUser>
            <a mat-menu-item [routerLink]="['/profile']">
              <span>Profile Settings</span>
            </a>
          </ng-template>

          <mat-divider></mat-divider>

          <a mat-menu-item (click)="logout()">
            <span>Log Out</span>
          </a>

          <div mat-menu-item>
            <a class="pr-3" href="#" (click)="changeLanguage($event, 'en')">
              <img src="assets/img/uk.png" alt="UK" style="width: 40px">
            </a>
            <a href="#" (click)="changeLanguage($event, 'es')">
              <img src="assets/img/es.png" alt="ES" style="width: 40px">
            </a>
          </div>
        </ng-container>

        <ng-template #notLoggedIn>
          <!-- Handle not logged in state -->
        </ng-template>
      </mat-menu>
    </div>

    <app-notification-bell [notifications]="notifications"></app-notification-bell>
  </div>
</header>
<!-- END Page Header -->
