import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { interval, Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { User } from '../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../_shared/services/main-global-event.service';
import { UserService } from '../../admin/user/user.service';
import { AuthService } from '../../auth/auth.service';
import { ClientService } from '../../client/client.service';
import { NotificationService } from '../../admin/notification/notification.service';
import { DatabaseNotification } from '../../../../../_base-shared/models/Notification/DatabaseNotification';

@Component({
  selector:    'app-header',
  templateUrl: './header.component.html',
  styleUrls:   ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() notifications;
  public authUser: User;
  private refreshSource: Subscription;

  public cases = 0;
  public goal = 0;

  constructor(private router: Router,
              private cookieService: CookieService,
              private translate: TranslateService,
              private toastr: ToastrService,
              private globalEventsService: MainGlobalEventService,
              private authService: AuthService,
              private userService: UserService,
              private clientService: ClientService,
              private notificationService: NotificationService) {
  }

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(user => this.authUser = user);
    this.updateDmAcceptedCount();
    this.notificationService.getdmacceptedcount().subscribe(
      (result: LaravelResourceResponse<any>) => {
        // parse the result
        this.cases = result.data?.info.cases;
        this.goal = result.data?.info.goal;
      },
    );
  }

  public logout() {
    this.authService.logout().subscribe(res => {
      this.globalEventsService.setAuthUser(null);
      this.router.navigateByUrl('/login');
    });
  }

  public changeLanguage($event, language: 'en' | 'es') {
    $event.preventDefault();
    this.translate.use(language);
    this.cookieService.set('lang', language, 365, '/');
    if (this.authUser) {
      let subscription: Observable<LaravelResourceResponse>;
      if (this.authUser.role.name === 'User') {
        subscription = this.clientService.updateUser(this.authUser.uuid, {locale: language});
      } else {
        subscription = this.userService.update(this.authUser.id, {locale: language});
      }
      subscription.subscribe(result => {
        const message = language === 'en' ? 'Language changed to english' : 'Idioma cambiado a español';
        this.toastr.success(message);
      });
    }
  }

  private updateDmAcceptedCount() {
    this.refreshSource = interval(300000).subscribe(iteration => {
      this.notificationService.getdmacceptedcount().subscribe(
        (result: LaravelResourceResponse<any>) => {
          this.cases = result.data?.info.cases;
          this.goal = result.data?.info.goal;
        },
      );
    });
  }
}
