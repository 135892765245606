import { NgModule } from '@angular/core';
import { SharedModule } from '../../_shared/shared.module';
import { CreditorBookValueComponent } from './creditor-book-value/creditor-book-value.component';
import { FinancialReportListComponent } from './financial-report-list/financial-report-list.component';
import { HeadlineReportsComponent } from './headline-reports/headline-reports.component';
import { MissingDocumentsComponent } from './missing-documents/missing-documents.component';
import { ReportsRoutingModule } from './reports-routing.module';
import { PaymentReceivedModalComponent } from './financial-report-list/payment-received-modal/payment-received-modal.component';
import { SalesReportModalComponent } from './financial-report-list/sales-report-modal/sales-report-modal.component';
import { PackagerSalesReportComponent } from './financial-report-list/packager-sales-report/packager-sales-report.component';
import { AutomatedCreditorReportComponent } from './financial-report-list/automated-creditor-report/automated-creditor-report.component';
import { NegotiationReportComponent } from './financial-report-list/negotiation-report/negotiation-report.component';
import { CreditorImportComponent } from './creditor-import/creditor-import.component';
import { BulkDmModalComponent } from './financial-report-list/bulk-dm-report/bulkdm-report-modal.component';
import { CreditorNegotiationModalComponent } from './financial-report-list/creditor-negotiator-report/creditor-negotiation-modal.component';
import { CreditorDebtModalComponent } from './financial-report-list/creditor-debt-report/creditor-debt-modal.component';
import { DataInsightsReportListComponent } from './data-insights-report-list/data-insights-report-list.component';
import { DmReconciliationReportComponent } from './data-insights-report-list/dm-reconciliation-report/dm-reconciliation-report.component';
import { CreditorDMAcceptedModalComponent } from './financial-report-list/creditor-dm-accepted-report/creditor-dm-accepted-modal.component';

@NgModule({
  imports:      [
    SharedModule,
    ReportsRoutingModule,
  ],
  declarations: [
    CreditorBookValueComponent,
    HeadlineReportsComponent,
    MissingDocumentsComponent,
    FinancialReportListComponent,
    PaymentReceivedModalComponent,
    SalesReportModalComponent,
    PackagerSalesReportComponent,
    AutomatedCreditorReportComponent,
    NegotiationReportComponent,
    CreditorImportComponent,
    BulkDmModalComponent,
    CreditorNegotiationModalComponent,
    CreditorDebtModalComponent,
    DataInsightsReportListComponent,
    DmReconciliationReportComponent,
    CreditorDMAcceptedModalComponent
  ],
})
export class ReportsModule {
}
