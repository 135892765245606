import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacyCheckboxChange } from '@angular/material/legacy-checkbox';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { DocumentType } from '../../../../../../../../_base-shared/models/DocumentType';
import { CaseDocumentService } from '../../../case-document.service';

@Component({
  selector:    'app-required-v2',
  templateUrl: './required-v2.component.html',
  styleUrls:   ['./required-v2.component.scss']
})
export class RequiredV2Component implements OnInit {
  @Input() case: Case;
  @Input() documentRequests: Array<any>;
  @Input() clientType: 'client' | 'partner';
  @Output() closeAccordion: EventEmitter<any> = new EventEmitter<any>();
  @Input() disabled: boolean = false;

  public caseVisibleDocumentTypes: Array<DocumentType>;
  public basicDocuments: Array<any>;
  public circumstanceDocuments: Array<any>;
  public publicDebts: Array<object>;
  public bankAccounts: Array<object>;
  public caseCreditorDocs: Array<object>;
  public publicDebtDocs: Array<object>;
  public expiryDocuments     = {
    custom: [],
  };
  public requestingDocuments = false;
  public customDocName       = new FormControl('');

  constructor(private documentService: CaseDocumentService,
              private toastr: ToastrService,
              private translate: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.documentRequests.map(documentRequest => {
      return {
        ...documentRequest,
        type: this.getDocumentRequestType(documentRequest),
      }
    })
    this.expiryDocuments = {
      custom: this.documentRequests.filter(doc => (doc.custom && !doc.case_creditor_id && !doc.bank_asset_id)),
    };

    this.documentService.getCaseDocumentTypes(this.case.id).subscribe(result => {
      this.caseVisibleDocumentTypes = result.data.filter(docType => !!docType.visible);
      this.basicDocuments           = result.data.filter(docType => docType.document_type_category_id === 1);
      this.circumstanceDocuments    = result.data.filter(docType => docType.document_type_category_id === 2);
      this.publicDebtDocs           = result.data.filter(document => {
        return document.name === 'public-debt-estate' || document.name === 'public-debt-social-security' ||
          document.name === 'public-debt-town-hall';
      }).map(docType => {
        return {...docType, type: 'case-creditor-docs'}
      });
    });

    if (this.disabled) {
      this.customDocName.disable();
    } 
   
    this.mapCaseCreditorRequests();
    this.mapPublicDebts();
    this.mapBankAccounts();
  }

  private getDocumentRequestType(documentRequest): string {
    let type = null;
    if (documentRequest.document_type_category_id === 1) {
      type = 'basic';
    } else if(documentRequest.document_type_category_id === 2) {
      type = 'circumstantial';
    } else if(documentRequest.document_type_category_id === 3) {
      type = 'case-creditor-docs';
    } else if(documentRequest.document_type_category_id === 4) {
      type = 'case-bank-docs';
    }

    return type;
  }

  addOrRemoveDocument(docType): void {
    if (this.documentRequests?.length > 0 && this.documentRequests.find(type => type.name === docType.name)) {
      this.documentRequests = this.documentRequests.filter(type => type.name !== docType.name);
    } else {
      this.documentRequests.push(docType);
    }
  }

  addCustomDocument($event): void {
    $event.preventDefault();

    this.expiryDocuments.custom.push({name: this.customDocName.value, type: 'new-custom'});
    this.documentRequests.push({name: this.customDocName.value, type: 'new-custom'});
    this.customDocName.setValue('');
  }

  shouldBeChecked(type): boolean {
    if (type.type === 'new-custom') {
      return true;
    }

    if(type.document_type_category_id === 1) {
      return true;
    }

    if (type.type === 'case-creditor-docs') {
      return this.documentRequests?.length > 0 && !!this.documentRequests.find(t => t?.case_creditor_id === type.id);
    }

    if (type.name === 'bank-account-docs') {
      return this.documentRequests?.length > 0 && !!this.documentRequests.find(t => t.pivot?.bank_account_id === type.id);
    }

    return this.documentRequests?.length > 0 && !!this.documentRequests.find(t => t.name === type.name);
  }

  public requestDocuments($event, channel: 'email' | 'sms' | 'all' | 'none'): void {
    $event.preventDefault();
    this.requestingDocuments = true;

    if (this.documentRequests.length === 0) {
      this.closeAccordion.emit(false);
      return;
    }
    const data: any = {
      requested_types: this.documentRequests,
      type:            channel,
      client_type:     this.clientType,
    };
    this.documentService.requestNew(this.case.id, data)
      .pipe(finalize(() => this.requestingDocuments = false))
      .subscribe(res => {
          this.closeAccordion.emit(false);
          this.toastr.success(this.translate.instant('CASES.single.request-sent-success'),
            this.translate.instant('SHARED.success'));
        },
        error => {
          this.closeAccordion.emit(false);
          this.toastr.error(this.translate.instant('CASES.single.request-sent-error'),
            this.translate.instant('SHARED.error'));
        });
  }

  private mapCaseCreditorRequests(): void {
    this.caseCreditorDocs = this.case.unsecured_creditors.concat(this.case.secured_creditors)
      .filter(caseCreditor => {
        if (this.clientType === 'client') {
          return caseCreditor.pivot.ownership === 'applicant' || caseCreditor.pivot.ownership === 'joint'
        } else {
          return caseCreditor.pivot.ownership === 'partner' || caseCreditor.pivot.ownership === 'joint'
        }
      })
      .map(caseCreditor => ({
        id:               caseCreditor.pivot.id,
        name:             caseCreditor.pivot.id + '-' + caseCreditor.name,
        label:            caseCreditor.name,
        type:             'case-creditor-docs',
        case_creditor_id: caseCreditor.pivot.id
      }));
    console.log(this.caseCreditorDocs);
  }

  mapPublicDebts(): void {
    this.publicDebts = this.case.public_debts;
  }

  mapBankAccounts(): void {
    this.bankAccounts = this.case.assets.filter(asset => {
      if (this.clientType === 'client') {
        return asset.type === 'bank_accounts' && (asset.ownership === 'applicant' || asset.ownership === 'joint')
      } else {
        return asset.type === 'bank_accounts' && (asset.ownership === 'partner' || asset.ownership === 'joint')
      }
    }).map(bankAccount => {
      return {
        id:            bankAccount.id,
        name:          bankAccount.id + '-' + bankAccount.entity.name,
        label:         bankAccount.entity.name,
        type:          'case-bank-docs',
        bank_asset_id: bankAccount.id
      };
    });
  }

  public toggleAllDocTypes(event: MatLegacyCheckboxChange, type: 'basic' | 'circumstantial' | 'case_creditor' | 'bank_account') {
    let searchDocuments = [];
    if (type === 'basic') {
      searchDocuments = this.basicDocuments;
    } else if (type === 'circumstantial') {
      searchDocuments = this.circumstanceDocuments;
    } else if (type === 'case_creditor') {
      searchDocuments = this.caseCreditorDocs;
    } else if (type === 'bank_account') {
      searchDocuments = this.bankAccounts;
    }
    searchDocuments.forEach(document => {
      const selectedDocIndex = this.documentRequests.findIndex(type => type.name === document.name);
      if (selectedDocIndex >= 0) {
        if (!event.checked) {
          this.documentRequests.splice(selectedDocIndex, 1);
        }
      } else {
        if (event.checked) {
          this.documentRequests.push(document);
        }
      }
    });
  }

}
