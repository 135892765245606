import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import { ToastrService } from 'ngx-toastr';
import { finalize, first } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Case } from '../../../../../../../_base-shared/models/Case/Case';
import { CaseCreditor } from '../../../../../../../_base-shared/models/Case/CaseCreditor';
import { CasePublicDebt } from '../../../../../../../_base-shared/models/Case/CasePublicDebt';
import { Creditor } from '../../../../../../../_base-shared/models/Entity/Creditor';
import { Status } from '../../../../../../../_base-shared/models/Status/Status';
import { User } from '../../../../../../../_base-shared/models/User/User';
import { environment } from '../../../../../environments/environment';
import {
  ChangeCreditorStatusComponent,
} from '../../../../_shared/components/change-creditor-status/change-creditor-status.component';
import { VerifySignatureComponent } from '../../../../_shared/components/verify-signature/verify-signature.component';
import { MainGlobalEventService } from '../../../../_shared/services/main-global-event.service';
import { EmailPreviewComponent } from '../../../app-document/email-preview/email-preview.component';
import { CreditorService } from '../../../creditor/creditor.service';
import { StatusService } from '../../../status/status.service';
import { CaseCreditorService } from '../../case-creditor.service';
import { CaseDocumentService } from '../../case-document.service';
import { CaseService } from '../../case.service';
import { ProposalModalComponent } from '../case-draft/proposal-modal/proposal-modal.component';
import { AdditionalInputComponent } from './additional-input/additional-input.component';
import { ProductService } from '../../product.service';
import { Product } from '../../../../../../../_base-shared/models/Product';
import { CaseCreditorInfoComponent } from './creditor-dialog/case-creditor-info.component';
import { FinancialOverviewService } from '../../../payment/financial-overview.service';
import { CasePaymentPlanGeneratorComponent } from '../case-payment/case-payment-plan-generator/case-payment-plan-generator.component';
import { QuickWinInfoComponent } from './quick-win-info/quick-win-info.component';
import { CreditorRefinanced } from './creditor-refinanced/creditor-refinanced.component';

@Component({
  selector:    'app-case-creditor-list',
  templateUrl: './case-creditor-list.component.html',
  styles:      [
    `
    .dvl-push-btn {
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        line-height: 36px;
        padding: 0 16px;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 500;
    }

    .mat-icon-size {
        font-size: 25px;
        width: 25px;
        height: 25px;
    }

    .mat-form-field {
        width: 100%;
    }

    .p-r-10 {
        padding-right: 10px !important;
    }
    /* th{
        min-width: 107px !important;
    }*/
    .amount-box-item {
        float: right;
        border: 2px solid #0a2540;
        border-radius: 10px;
        padding: 5px 6px;
        font-weight: bold;
        flex-shrink: 0;
        width: fit-content;
    }
    `],
})
export class CaseCreditorListComponent implements OnInit {
  public authUser: User;
  public case: Case;
  public form: UntypedFormGroup;
  public excludedForm: UntypedFormGroup;
  public loanCreditorsForm: UntypedFormGroup;
  public securedCreditors: MatTableDataSource<CaseCreditor>;
  public unsecuredCreditors: MatTableDataSource<CaseCreditor>;
  public excludedCreditors: MatTableDataSource<CaseCreditor>;
  public claimCreditors: MatTableDataSource<CaseCreditor>;
  public publicDebts: MatTableDataSource<CasePublicDebt>;
  public loanCreditors: MatTableDataSource<CaseCreditor>;
  public originalClaimCreditors: Array<CaseCreditor>;
  public originalUnsecuredCreditors: Array<CaseCreditor>;
  public originalSecuredCreditors: Array<CaseCreditor>;
  public originalExcludedCreditors: Array<CaseCreditor>;
  public originalLoanCreditors: Array<CaseCreditor>;
  public displayedColumns: string[]          = [];
  public displayedColumnsPD: string[]        = [];
  public displayedColumnsUnsecured: string[] = [];
  public displayedColumnsExcluded: string[]  = [];
  public displayedColumnsLoanCreditors: string[] = [];
  public isLoading                           = 0;
  public isFetching                          = 0;
  public isFetchingPublicDebt                = 0;
  public isLoadingIds: Array<number>         = []; //  User to determinate in which row to show spinner
  public isPublicLoading                     = 0;
  public isPublicLoadingId: string[]         = [];
  public statuses: Array<Status>             = [];
  public latestPropuestaPdfLocation;
  public latestPropuestaClientPdfLocation;

  public totalDebt: number                   = 0;

  public allCreditors: Array<CaseCreditor> = [];

  public loadRelations = ['contracts', 'client_files', 'partner_files', 'client', 'partner'];
  selectionSecured        = new SelectionModel<CaseCreditor>(true, []);
  selectionUnsecured      = new SelectionModel<CaseCreditor>(true, []);
  selectionLoanCreditors  = new SelectionModel<CaseCreditor>(true, []);
  selectionClaim          = new SelectionModel<CaseCreditor>(true, []);
  selectionExcluded       = new SelectionModel<CaseCreditor>(true, []);

  selectionPublicDebts = new SelectionModel<CasePublicDebt>(true, []);
  //  Enable/Disabled flags
  public isSigned      = false;

  public missingAepFiles               = true;
  private propuestoModelo: any;
  public publicDebtsVerifyDisabled     = true;
  public publicDebtsResendDisabled     = true;
  public securedVerifyDisabled         = true;
  public securedResendDisabled         = true;
  public securedRecoveryDisabled       = true;
  public securedSendAepDisabled        = true;
  public securedResendAepDisabled      = true;
  public securedProposalAccordDisabled = true;

  public unsecuredSendAepDisabled        = true;
  public excludedSendAepDisabled         = true;
  public unsecuredResendAepDisabled      = true;
  public excludedResendAepDisabled       = true;
  public unsecuredVerifyDisabled         = true;
  public excludedVerifyDisabled          = true;
  public unsecuredResendDisabled         = true;
  public excludedResendDisabled          = true;
  public unsecuredRecoveryDisabled       = true;
  public excludedRecoveryDisabled        = true;
  public unsecuredProposalAccordDisabled = true;
  public excludedProposalAccordDisabled  = true;
  public claimSendAepDisabled            = true;
  public claimResendAepDisabled          = true;
  public claimVerifyDisabled             = true;
  public claimResendDisabled             = true;
  public claimRecoveryDisabled           = true;
  public claimProposalAccordDisabled     = true;
  public isSubmitting                    = false;
  public isProductDm: boolean;
  public isProductLso: boolean;
  public storageUrl                      = environment.STORAGE_URL + '/';
  public isExcluded                      = 0;
  public isEditing                       = false;
  public currentRow: number;
  public isEmailEnabled                  = false;
  public isGetRightOfAccessClientEnabled = false;
  public useCreditorLoanFields = true;
  public allCreditorsHaveProposalEmails = true;
  public creditorWithoutProposalEmails  = [];
  public products: Array<Product>       = [];
  public selectedProduct: Product = null;
  public disposableIncome: number;
  public targetDisposable: number;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private toast: ToastrService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private globalEventService: MainGlobalEventService,
    private caseService: CaseService,
    private creditorService: CreditorService,
    private caseCreditorService: CaseCreditorService,
    private caseDocumentService: CaseDocumentService,
    private statusService: StatusService,
    private el: ElementRef,
    private productService: ProductService,
    private financialOverviewService: FinancialOverviewService,
  ) {
  }

  ngOnInit(): void {
    this.globalEventService.authUser$.subscribe(user => this.authUser = user);
    this.fetchCreditorsNoFilter();
    this.route.parent.paramMap.subscribe(params => {
      const caseId = +params.get('id');
      this.isLoading++;
      this.fetchCase(caseId);
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(selection, dataSource) {
    const numSelected = selection.selected.length;
    const numRows     = dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(selection, dataSource) {
    if (this.isAllSelected(selection, dataSource)) {
      selection.clear();
      return;
    }

    selection.select(...dataSource.data);
  }

  private buildDisplayColumns() {
    this.displayedColumns = [
      'select',
      'name',
      'product',
      'debt_type',
      'initial_balance',
      'current_balance',
      'monthly_payments',
      'reference_number',
      'response_received',
      'additional_partner',
      'making_payments',
      'azcarate',
      'actions',
      'loader',
    ];

    this.displayedColumnsUnsecured = !!(this.case.product.group_slug === 'dmp' || this.case.product.group_slug ===
      'dgs' ||
      this.case.product.group_slug === 'cajaplus') ? [
      'select',
      'name',
      'debt_type',
      'initial_balance',
      'current_balance',
      'amount_repaid',
      'monthly_payments',
      'reference_number',
      'response_received',
      'additional_partner',
      'making_payments',
      'debt_agreed',
      'debt_reduction',
      'monthly_repayment',
      'term',
      'status',
      'reference',
      'actions',
      'loader',
    ] : [
      'select',
      'name',
      'product',
      'debt_type',
      'initial_balance',
      'current_balance',
      'amount_repaid',
      'monthly_payments',
      'reference_number',
      'response_received',
      'additional_partner',
      'making_payments',
      'actions',
      'loader',
    ];

    if (!this.displayedColumnsUnsecured.includes('ownership') && this.case.joint_application &&
      this.case.product.group_slug === 'dmp') {
      this.displayedColumnsUnsecured.splice(2, 0, 'ownership');
    }

    this.displayedColumnsExcluded = [
      'select',
      'name',
      'ownership',
      'debt_type',
      'initial_balance',
      'current_balance',
      'amount_repaid',
      'monthly_payments',
      'reference_number',
      'response_received',
      'additional_partner',
      'making_payments',
      'actions',
      'loader',
    ];

    this.displayedColumnsPD = [
      'select', 'name', 'product', 'debt_type', 'initial_balance', 'current_balance', 'monthly_payments',
      'reference_number', 'response_received', 'additional_partner', 'making_payments', 'actions', 'loader',
    ];

    this.displayedColumnsLoanCreditors = [
      // , 'total_reduction', 'current_name', 'loan_id', 'last_paid'
      'select', 'name', 'last_paid', 'product', 'initial_balance', 'debt_amount', 'negotiated_amount', 'percent_reduction', 'percent_accepted',
      'response_received', 'days_in_status', 'amount_updated_at', 'actions', 'loader'
    ];
  }

  buildForm() {
    this.form = this.fb.group({
      ownership:         new UntypedFormControl(''),
      verified:          new UntypedFormControl(''),
      response_received: new UntypedFormControl(''),
    });
  }

  buildDMForm() {
    this.excludedForm = this.fb.group({
      debt_agreed:       new UntypedFormControl(''),
      monthly_repayment: new UntypedFormControl(''),
      reference:         new UntypedFormControl(''),
      status:            new UntypedFormControl(''),
    });
  }

  buildLoanCreditorsForm(): void {
    this.loanCreditorsForm = this.fb.group({
      creditor_id: new UntypedFormControl(''),
      initial_balance: new UntypedFormControl(''),
      last_paid: new UntypedFormControl(''),
      debt_amount: new UntypedFormControl(''),
      loan_id: new UntypedFormControl(''),
      negotiated_amount: new UntypedFormControl(''),
      total_reduction: new UntypedFormControl(''),
      percent_reduction: new UntypedFormControl(''),
      percent_accepted: new UntypedFormControl(''),
      sum_total_reduction: new UntypedFormControl(''),
      debt_reduction: new UntypedFormControl(''),
      sum_total_debt_amount: new UntypedFormControl(''),
      product_id: new UntypedFormControl(''),
      current_creditor_id: new UntypedFormControl(''),
      amount_updated_at: new UntypedFormControl(''),
    });
  }

  public toggleEditMode(index: number, element) {
    this.currentRow = index;
    this.isEditing  = !this.isEditing;
    if (this.isEditing) {
      this.el.nativeElement.querySelector('td').classList.add('.p-r-10');
    }
    if (!this.isEditing) {
      this.onExclude(element, this.excludedForm.getRawValue());
    }
  }

  public toggleLoanEditMode(index: number, element): void {
    this.currentRow = index;
    this.isEditing = !this.isEditing;

    if (this.isEditing) {
      const buttons = document.querySelectorAll('.edit-button');
      buttons.forEach(button => {
        if (button.id !== index.toString()) {
          button.setAttribute('disabled', 'true');
        }
      });

      this.loanCreditorsForm.get('initial_balance').patchValue(element.pivot.initial_balance ?? element.pivot.debt_amount);
      this.loanCreditorsForm.get('last_paid').patchValue(element.pivot.last_paid);
      this.loanCreditorsForm.get('amount_updated_at').patchValue(element.pivot.amount_updated_at);
      this.loanCreditorsForm.get('debt_amount').patchValue(element.pivot.debt_amount);
      this.loanCreditorsForm.get('loan_id').patchValue(element.pivot.loan_id);
      this.loanCreditorsForm.get('product_id').patchValue(element.pivot.product_id);
      this.loanCreditorsForm.get('negotiated_amount').patchValue(element.pivot.negotiated_amount);
      this.loanCreditorsForm.get('current_creditor_id').patchValue(element.pivot.current_creditor_id);
      this.loanCreditorsForm.get('creditor_id').patchValue(element.pivot.creditor_id);
      this.el.nativeElement.querySelector('td').classList.add('.p-r-10');
    }

    if (!this.isEditing) {
      this.saveLoanCreditorData(element, this.loanCreditorsForm.getRawValue());
      this.loanCreditorsForm.get('creditor_id').patchValue(element.pivot._creditor_id);
      this.loanCreditorsForm.get('total_reduction').patchValue(element.pivot.total_reduction);
      this.loanCreditorsForm.get('percent_reduction').patchValue(element.pivot.percent_reduction);
      this.loanCreditorsForm.get('percent_accepted').patchValue(element.pivot.percent_accepted);
      this.loanCreditorsForm.get('product_id').patchValue(this.selectedProduct.id);
      this.loanCreditorsForm.get('current_creditor_id').patchValue(element.pivot.current_creditor_id);
      this.loanCreditorsForm.get('amount_updated_at').patchValue(element.pivot.amount_updated_at);

      const loanCreditors = [...this.loanCreditors.data];
      loanCreditors[index].pivot.initial_balance = this.loanCreditorsForm.get('initial_balance').value;
      loanCreditors[index].pivot.last_paid = this.loanCreditorsForm.get('last_paid').value;
      loanCreditors[index].pivot.debt_amount = this.loanCreditorsForm.get('debt_amount').value;
      loanCreditors[index].pivot.loan_id = this.loanCreditorsForm.get('loan_id').value;
      loanCreditors[index].pivot.product_id =  this.loanCreditorsForm.get('product_id').value;
      loanCreditors[index].pivot.current_creditor_id = this.loanCreditorsForm.get('current_creditor_id').value;
      loanCreditors[index].pivot.creditor_id = this.loanCreditorsForm.get('creditor_id').value;
      loanCreditors[index].pivot.negotiated_amount = this.loanCreditorsForm.get('negotiated_amount').value;
      loanCreditors[index].pivot.amount_updated_at = this.loanCreditorsForm.get('amount_updated_at').value;
      this.loanCreditors = new MatTableDataSource<Creditor>(loanCreditors);

      this.loanCreditorsForm.get('debt_reduction').patchValue(this.getTotal('percent_reduction').toFixed(2));
      this.loanCreditorsForm.get('sum_total_debt_amount').patchValue(this.getTotal('debt_amount').toFixed(2));
      this.loanCreditorsForm.get('debt_amount').patchValue(null);
      this.loanCreditorsForm.get('initial_balance').patchValue(null);
      this.loanCreditorsForm.get('last_paid').patchValue(null);
      this.loanCreditorsForm.get('loan_id').patchValue(null);
      this.loanCreditorsForm.get('product_id').patchValue(null);
      this.loanCreditorsForm.get('negotiated_amount').patchValue(null);
      this.loanCreditorsForm.get('total_reduction').patchValue(null);
      this.loanCreditorsForm.get('percent_reduction').patchValue(null);
      this.loanCreditorsForm.get('percent_accepted').patchValue(null);
      this.loanCreditorsForm.get('current_creditor_id').patchValue(null);
      this.loanCreditorsForm.get('creditor_id').patchValue(null);
      this.loanCreditorsForm.get('amount_updated_at').patchValue(null);
      this.loanCreditorsForm.updateValueAndValidity();
    }
  }

  getFilters() {
    const {ownership, verified, response_received} = this.form.value;
    const data                                     = {};
    if (ownership) {
      data['ownership[]'] = ownership;
    }
    if (verified) {
      data['verified[]'] = verified;
    }
    if (response_received) {
      data['response_received[]'] = response_received;
    }
    return data;
  }

  private fetchData(data = {}) {
    this.fetchCreditors(data);
    this.fetchPublicDebts(data);
  }

  private fetchCreditors(data) {
    this.isFetching++;
    this.securedCreditors   = new MatTableDataSource<CaseCreditor>([]);
    this.unsecuredCreditors = new MatTableDataSource<CaseCreditor>([]);
    this.claimCreditors     = new MatTableDataSource<CaseCreditor>([]);
    this.excludedCreditors  = new MatTableDataSource<CaseCreditor>([]);
    this.loanCreditors      = new MatTableDataSource<CaseCreditor>([]);

    this.caseCreditorService.indexCaseCreditors(this.case.id, data)
      .pipe(finalize(() => this.isFetching--))
      .subscribe(value => {
        const notExcluded = value.data.unsecured?.filter(creditor => 
          creditor.pivot.excluded !== true && creditor.pivot.case_creditor_status_id !== 11 && creditor.pivot.product_id !== 18
        );
        const excluded = value.data.unsecured?.filter(creditor => 
          creditor.pivot.excluded === true || creditor.pivot.case_creditor_status_id === 11
        );

        this.totalDebt = notExcluded.reduce((acc, creditor) => acc + +creditor.pivot.debt_amount, 0);

        this.securedCreditors           = new MatTableDataSource<Creditor>(value.data.secured);
        this.originalSecuredCreditors   = value.data.secured ? value.data.secured : [];
        this.claimCreditors             = new MatTableDataSource<Creditor>(value.data.claim);
        this.originalClaimCreditors     = value.data.claim ? value.data.claim : [];
        this.unsecuredCreditors         = new MatTableDataSource<Creditor>(notExcluded);
        this.originalUnsecuredCreditors = notExcluded;
        this.excludedCreditors          = new MatTableDataSource<Creditor>(excluded);
        this.originalExcludedCreditors  = excluded ? excluded : [];

        if (this.useCreditorLoanFields) {
          const loanCreditors = value.data.unsecured ? value.data.unsecured.map(loanCreditor => {
            loanCreditor.pivot.debt_amount         = loanCreditor.pivot.debt_amount ?? loanCreditor.pivot.current_balance;
            loanCreditor.pivot.negotiated_amount   = loanCreditor.pivot.negotiated_amount ?? loanCreditor.pivot.debt_amount;
            loanCreditor.pivot.initial_balance     = loanCreditor.pivot.initial_balance;
            loanCreditor.pivot.last_paid           = loanCreditor.pivot.last_paid;
            loanCreditor.pivot.amount_updated_at   = loanCreditor.pivot.amount_updated_at;
            loanCreditor.pivot.loan_id             = loanCreditor.pivot.loan_id ?? loanCreditor.pivot.loan_id;
            loanCreditor.pivot.product_id          = loanCreditor.pivot.product_id;
            loanCreditor.pivot.current_creditor_id = loanCreditor.pivot.current_creditor_id;
            loanCreditor.pivot.creditor_id         = loanCreditor.pivot.creditor_id;
            return loanCreditor;
        }) : [];
        console.log('loanCreditors', loanCreditors);
          this.originalLoanCreditors = loanCreditors;
          this.loanCreditors = new MatTableDataSource<Creditor>(loanCreditors);
        }
        this.allCreditorsHaveProposalEmails = this.unsecuredCreditors.data.every(creditor => !!creditor.email_proposals);
        this.creditorWithoutProposalEmails = this.unsecuredCreditors.data.filter(creditor => !creditor.email_proposals);
        this.getResponseReceivedStatus(this.unsecuredCreditors.data);
      });
  }

  private fetchPublicDebts(data) {
    this.isFetchingPublicDebt++;
    this.publicDebts = new MatTableDataSource<CasePublicDebt>([]);
    this.caseCreditorService.indexCasePublicDebts(this.case.id, data)
      .pipe(finalize(() => this.isFetchingPublicDebt--))
      .subscribe(value => {
        this.publicDebts = new MatTableDataSource<CasePublicDebt>(value.data);
      });
  }

  public clearFilters() {
    this.form.setValue({
      ownership:         '',
      verified:          '',
      response_received: '',
    });
    this.fetchData({});
  }

  public clearMultiSelect($event, name) {
    $event.stopPropagation();
    this.form.patchValue({[name]: []});
  }

  verifyCreditor($event, caseCreditorId, creditorType, caseDetails: Case = null) {
    $event.preventDefault();
    console.log('caseDetails', caseDetails);
    let failure = false;
    caseDetails.contracts.forEach(contract => {
      if (contract.mandate_location == null || contract.pdf_location == null) {
        failure = true;
        Swal.fire({
          title: 'File Problem', // this.translate.instant('CASES.details.verify-creditor-error'),
          text: 'Mandate or Contract PDF is not found, please regenerate!', // this.translate.instant('CASES.details.verify-creditor-error-message'),
          icon: 'error',
          confirmButtonText: this.translate.instant('SHARED.close'),
        });
      }
    });
    if (failure) {
      return;
    }
    const caseCreditorIds = caseCreditorId.selected ? caseCreditorId.selected.map(cred => cred.pivot.id) : caseCreditorId;
    if (!!this.case.client.signature_verified_at) {
      if (creditorType === 'public_debts') {
        this.additionalInput(caseCreditorIds, creditorType);
      } else {
        this.verifySendMandates(caseCreditorIds, creditorType);
      }
    } else {
      this.verifySignature(caseCreditorIds, creditorType);
    }
  }

  public sendAep($event, caseCreditorId, creditorType) {
    $event.preventDefault();
    const ids = caseCreditorId.selected ? caseCreditorId.selected.map(cred => cred.pivot.id) : caseCreditorId;
    this.isLoading++;
    this.isLoadingIds = ids;
    this.creditorService.sendAepEmails(this.case.id, {selected_ids: ids})
      .pipe(finalize(() => {
        this.isLoading--;
        this.isLoadingIds = [];
      }))
      .subscribe(
        value => {
          this.toast.success(this.translate.instant('CASES.details.send-aep-success'));
          ids.map(id => {
            //  Mark that AEP is sent for all creditors with same name
            if (creditorType === 'secured_creditors') {
              //  Find creditor
              // @ts-ignore
              const item = this.securedCreditors.data.find(i => i.pivot.id === id);
              this.securedCreditors.data.map(cred => { // Find all other creditor creditors with same id
                if (cred.id === item.id) {
                  // @ts-ignore
                  cred.pivot.aep_sent = 1;
                }
              });
            } else if (creditorType === 'unsecured_creditors') {
              //  Find creditor
              // @ts-ignore
              const item = this.unsecuredCreditors.data.find(i => i.pivot.id === id);
              this.unsecuredCreditors.data.map(cred => {  // Find all other creditor creditors with same id
                if (cred.id === item.id) {
                  // @ts-ignore
                  cred.pivot.aep_sent = 1;
                }
              });
            } else if (creditorType === 'excluded_creditors') {
              //  Find creditor
              // @ts-ignore
              const item = this.excludedCreditors.data.find(i => i.pivot.id === id);
              this.excludedCreditors.data.map(cred => {  // Find all other creditor creditors with same id
                if (cred.id === item.id) {
                  // @ts-ignore
                  cred.pivot.aep_sent = 1;
                }
              });
            } else {
              //  Find creditor
              // @ts-ignore
              const item = this.claimCreditors.data.find(i => i.pivot.id === id);
              this.claimCreditors.data.map(cred => {  // Find all other creditor creditors with same id
                if (cred.id === item.id) {
                  // @ts-ignore
                  cred.pivot.aep_sent = 1;
                }
              });
            }
          });
          if (caseCreditorId.selected) {
            caseCreditorId.clear(); //  Remove selections
          }
        },
        error => {
          if (caseCreditorId.selected) {
            caseCreditorId.clear(); //  Remove selections
          }
          if (error.error.errors.case.errors && error.error.errors.case.errors.length) {
            this.throwMultipleErrors(error.error.errors.case.errors);
          } else {
            this.toast.error(this.translate.instant('CASES.details.send-aep-error'));
          }
        });

  }

  public sendProposalAndAccordSelection(selection: SelectionModel<CaseCreditor>): void {
    return this.sendProposalAndAccord(selection.selected.map(cred => cred.pivot.id), selection);
  }

  public sendProposalAndAccord(caseCreditorIds: Array<number>, selection: SelectionModel<CaseCreditor> = null) {
    this.isLoading++;
    this.isLoadingIds = caseCreditorIds;
    this.creditorService.sendProposalAndAcoord(this.case.id, {selected_ids: caseCreditorIds})
      .pipe(finalize(() => {
        this.isLoading--;
        this.isLoadingIds = [];
      }))
      .subscribe(
        value => {
          this.toast.success(this.translate.instant('CASES.details.send-proposal-accord-success'));
          if (selection.selected) {
            selection.clear();
          }
        },
        error => {
          if (selection.selected) {
            selection.clear();
          }
          if (error.error.errors.case.errors && error.error.errors.case.errors.length) {
            this.throwMultipleErrors(error.error.errors.case.errors);
          } else {
            this.toast.error(this.translate.instant('CASES.details.send-proposal-accord-error'));
          }
        });
  }

  public sendRightOfAccess(selectedCaseCreditors) {
    const selectedIds = selectedCaseCreditors.selected ?
      selectedCaseCreditors.selected.map(cred => cred.pivot.id) :
      selectedCaseCreditors;

    this.isLoading++;
    this.isLoadingIds = selectedIds;
    this.creditorService.sendRightOfAccess(this.case.id, {selected_ids: selectedIds})
      .pipe(finalize(() => {
        this.isLoading--;
        this.isLoadingIds = [];
        if (selectedCaseCreditors.selected) {
          selectedCaseCreditors.clear(); //  Remove selections
        }
      }))
      .subscribe(
        value => {
          this.toast.success(this.translate.instant('SHARED.submit_result.sent_email.success',
            {subject: this.translate.instant('CASES.single.creditors.actions.right_of_access')}));
        },
        error => {
          if (error.error.errors.case.errors && error.error.errors.case.errors.length) {
            this.throwMultipleErrors(error.error.errors.case.errors);
          } else {
            this.toast.error(this.translate.instant('SHARED.submit_result.sent_email.error',
              {subject: this.translate.instant('CASES.single.creditors.actions.right_of_access')}));
          }
        });
  }

  public sendRightOfAccessPpi(selectedCaseCreditors) {
    const selectedIds = selectedCaseCreditors.selected ?
      selectedCaseCreditors.selected.map(cred => cred.pivot.id) :
      selectedCaseCreditors;

    this.isLoading++;
    this.isLoadingIds = selectedIds;
    this.creditorService.sendRightOfAccessPpi(this.case.id, {selected_ids: selectedIds})
      .pipe(finalize(() => {
        this.isLoading--;
        this.isLoadingIds = [];
        if (selectedCaseCreditors.selected) {
          selectedCaseCreditors.clear(); //  Remove selections
        }
      }))
      .subscribe(
        value => {
          this.toast.success(this.translate.instant('SHARED.submit_result.sent_email.success',
            {subject: this.translate.instant('CASES.single.creditors.actions.right_of_access_ppi')}));
        },
        error => {
          if (error.error.errors.case.errors && error.error.errors.case.errors.length) {
            this.throwMultipleErrors(error.error.errors.case.errors);
          } else {
            this.toast.error(this.translate.instant('SHARED.submit_result.sent_email.error',
              {subject: this.translate.instant('CASES.single.creditors.actions.right_of_access_ppi')}));
          }
        });
  }

  public sendPpiClaim(selectedCaseCreditors) {
    const selectedIds = selectedCaseCreditors.selected ?
      selectedCaseCreditors.selected.map(cred => cred.pivot.id) :
      selectedCaseCreditors;

    this.isLoading++;
    this.isLoadingIds = selectedIds;
    this.creditorService.sendPpiClaim(this.case.id, {selected_ids: selectedIds})
      .pipe(finalize(() => {
        this.isLoading--;
        this.isLoadingIds = [];
        if (selectedCaseCreditors.selected) {
          selectedCaseCreditors.clear(); //  Remove selections
        }
      }))
      .subscribe(
        value => {
          this.toast.success(this.translate.instant('SHARED.submit_result.sent_email.success',
            {subject: this.translate.instant('CASES.single.creditors.actions.ppi_claim')}));
        },
        error => {
          if (error.error.errors.case.errors && error.error.errors.case.errors.length) {
            this.throwMultipleErrors(error.error.errors.case.errors);
          } else {
            this.toast.error(this.translate.instant('SHARED.submit_result.sent_email.error',
              {subject: this.translate.instant('CASES.single.creditors.actions.ppi_claim')}));
          }
        });
  }

  public sendAzcarateLoanLetter(selectedCaseCreditors) {
    const selectedIds = selectedCaseCreditors.selected ?
      selectedCaseCreditors.selected.map(cred => cred.pivot.id) :
      selectedCaseCreditors;

    this.isLoading++;
    this.isLoadingIds = selectedIds;
    this.creditorService.sendAzcarateLoanLetter(this.case.id, {selected_ids: selectedIds})
      .pipe(finalize(() => {
        this.isLoading--;
        this.isLoadingIds = [];
        if (selectedCaseCreditors.selected) {
          selectedCaseCreditors.clear(); //  Remove selections
        }
      }))
      .subscribe(
        value => {
          this.toast.success(this.translate.instant('SHARED.submit_result.sent_email.success',
            {subject: this.translate.instant('CASES.single.creditors.actions.azcarate_loan_letter')}));
        },
        error => {
          if (error.error.errors.case.errors && error.error.errors.case.errors.length) {
            this.throwMultipleErrors(error.error.errors.case.errors);
          } else {
            this.toast.error(this.translate.instant('SHARED.submit_result.sent_email.error',
              {subject: this.translate.instant('CASES.single.creditors.actions.azcarate_loan_letter')}));
          }
        });
  }

  public sendAzcarateRevolvingLetter(selectedCaseCreditors) {
    const selectedIds = selectedCaseCreditors.selected ?
      selectedCaseCreditors.selected.map(cred => cred.pivot.id) :
      selectedCaseCreditors;

    this.isLoading++;
    this.isLoadingIds = selectedIds;
    this.creditorService.sendAzcarateRevolvingLetter(this.case.id, {selected_ids: selectedIds})
      .pipe(finalize(() => {
        this.isLoading--;
        this.isLoadingIds = [];
        if (selectedCaseCreditors.selected) {
          selectedCaseCreditors.clear(); //  Remove selections
        }
      }))
      .subscribe(
        value => {
          this.toast.success(this.translate.instant('SHARED.submit_result.sent_email.success',
            {subject: this.translate.instant('CASES.single.creditors.actions.azcarate_revolving_letter')}));
        },
        error => {
          if (error.error.errors.case.errors && error.error.errors.case.errors.length) {
            this.throwMultipleErrors(error.error.errors.case.errors);
          } else {
            this.toast.error(this.translate.instant('SHARED.submit_result.sent_email.error',
              {subject: this.translate.instant('CASES.single.creditors.actions.azcarate_revolving_letter')}));
          }
        });
  }

  public markAsVerified(id, type) {
    if (type === 'secured_creditors') {
      // @ts-ignore
      const item          = this.securedCreditors.data.find(i => i.pivot.id === id);
      // @ts-ignore
      item.pivot.verified = 1;
    }
    if (type === 'unsecured_creditors') {
      // @ts-ignore
      const item          = this.unsecuredCreditors.data.find(i => i.pivot.id === id);
      // @ts-ignore
      item.pivot.verified = 1;
    }
    if (type === 'claim_creditors') {
      // @ts-ignore
      const item          = this.claimCreditors.data.find(i => i.pivot.id === id);
      // @ts-ignore
      item.pivot.verified = 1;
    }
    if (type === 'public_debts') {
      // @ts-ignore
      const item    = this.publicDebts.data.find(i => i.id === id);
      // @ts-ignore
      item.verified = 1;
    }
    if (type === 'excluded_creditors') {
      // @ts-ignore
      const item          = this.excludedCreditors.data.find(i => i.pivot.id === id);
      // @ts-ignore
      item.pivot.verified = 1;
    }
  }

  public updateStatus(id, type, value) {
    if (type === 'secured_creditors') {
      // @ts-ignore
      const item                   = this.securedCreditors.data.find(i => i.pivot.id === id);
      // @ts-ignore
      item.pivot.response_received = value;
    }
    if (type === 'unsecured_creditors') {
      // @ts-ignore
      const item                   = this.unsecuredCreditors.data.find(i => i.pivot.id === id);
      // @ts-ignore
      item.pivot.response_received = value;
    }
    if (type === 'public_debts') {
      // @ts-ignore
      const item             = this.publicDebts.data.find(i => i.id === id);
      // @ts-ignore
      item.response_received = value;
    }
    if (type === 'excluded_creditors') {
      // @ts-ignore
      const item             = this.publicDebts.data.find(i => i.id === id);
      // @ts-ignore
      item.response_received = value;
    }
  }

  verifySendMandates(caseCreditorId, creditorType) {
    const ids = caseCreditorId.selected ? caseCreditorId.selected.map(cred => cred.pivot.id) : caseCreditorId;
    this.isLoading++;
    this.isLoadingIds = ids;
    this.creditorService.verifySendMandates(ids[0], ids.length > 1 ? {selected_ids: ids} : null)
      .pipe(finalize(() => {
        this.isLoading--;
        this.isLoadingIds = [];
      }))
      .subscribe(
        value => {
          // @ts-ignore
          value.data.messages.map(message => {
            this.toast.success(message.message);
          });

          ids.map(id => {
            this.markAsVerified(id, creditorType);
            this.updateStatus(id, creditorType, 'mandate_sent');
          });
          if (caseCreditorId.selected) {
            caseCreditorId.clear(); //  Remove selections
          }
        },
        error => {
          if (caseCreditorId.selected) {
            caseCreditorId.clear(); //  Remove selections
          }
          if (error.error.errors.case.errors && error.error.errors.case.errors.length) {
            this.throwMultipleErrors(error.error.errors.case.errors);
          } else {
            this.toast.error(this.translate.instant('CASES.details.resend-mandates-error'));
          }
        });
  }

  sendMandatesRecoveryAgent($event, caseCreditorId): void {
    $event.preventDefault();
    const ids = caseCreditorId.selected ? caseCreditorId.selected.map(cred => cred.pivot.id) : caseCreditorId;
    this.isLoading++;
    this.isLoadingIds = ids;
    this.creditorService.sendMandatesRecoveryAgent(ids[0], ids.length > 1 ? {selected_ids: ids} : null)
      .pipe(finalize(() => {
        this.isLoading--;
        this.isLoadingIds = [];
      }))
      .subscribe(
        value => {
          // @ts-ignore
          value.data.messages.map(message => {
            this.toast.success(message.message);
          });
          if (caseCreditorId.selected) {
            caseCreditorId.clear(); //  Remove selections
          }
        },
        error => {
          if (caseCreditorId.selected) {
            caseCreditorId.clear(); //  Remove selections
          }
          if (error.error.errors.case.errors && error.error.errors.case.errors.length) {
            this.throwMultipleErrors(error.error.errors.case.errors);
          } else {
            this.toast.error(this.translate.instant('CASES.details.email-sent-error'));
          }
        });
  }

  verifySignature(caseCreditorId, creditorType) {
    const dialogRef = this.dialog.open(VerifySignatureComponent, {
      width:  '50%',
      height: '50%',
      data:   {
        case:           this.case,
        verifyCreditor: true,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.data.message) {
          if (creditorType === 'public_debts') {
            this.additionalInput(caseCreditorId, creditorType);
          } else {
            this.verifySendMandates(caseCreditorId, creditorType);
          }
          // this.updateSignatureVerification.emit(1);
        }
      }
    });
  }

  additionalInput(debtIds, creditorType) {
    const ids              = debtIds.selected ? debtIds.selected.map(cred => cred.id) : debtIds;
    this.isPublicLoading   = 1;
    this.isPublicLoadingId = ids;

    const dialogRef = this.dialog.open(AdditionalInputComponent, {
      width:  '50%',
      height: '50%',
      data:   {
        case: this.case,
        debtIds,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.data.message) {
          ids.map(id => {
            this.markAsVerified(id, creditorType);
          });
          this.isPublicLoading   = 0;
          this.isPublicLoadingId = [];
          if (debtIds.selected) {
            debtIds.clear(); //  Remove selections
          }
        }
      } else {
        this.isPublicLoading   = 0;
        this.isPublicLoadingId = [];
        if (debtIds.selected) {
          debtIds.clear(); //  Remove selections
        }
      }
    });
  }

  sendDeleteNumber($event: MouseEvent, allIds?, creditorType?: string) {
    $event.preventDefault();
    let ids = allIds;
    if (creditorType === 'unsecured_creditors') {
      ids = this.returnUnsecuredCreditorsIds();
    }

    this.isLoading++;
    this.isLoadingIds = ids;

    const dialogRef = this.dialog.open(AdditionalInputComponent, {
      width:  '50%',
      height: '50%',
      data:   {
        type:       'number',
        case:       this.case,
        creditorId: ids[0],
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.isLoading    = 0;
      this.isLoadingIds = [];
    });
  }

  sendAntiHarassment($event: MouseEvent, allIds: any[], creditorType?: string) {
    $event.preventDefault();

    let ids = allIds;
    if (creditorType === 'unsecured_creditors') {
      ids = this.returnUnsecuredCreditorsIds();
    }

    this.isLoading++;
    this.isLoadingIds = ids;

    const observable = creditorType === 'unsecured_creditors' ?
      this.creditorService.sendAntiHarassmentUnsecured(this.case.id, ids)
      :
      this.creditorService.sendAntiHarassmentEmail(ids[0]);
    observable.pipe(finalize(() => {
      this.isLoading--;
      this.isLoadingIds = [];
    }))
      .subscribe(
        value => {
          this.toast.success(this.translate.instant('CASES.details.email-sent'));
        }, error => {
          this.toast.error(this.translate.instant('CASES.details.email-sent-error'));
        });
  }

  sendLoanCancellationEmail($event: MouseEvent, allIds: any[], creditorType?: string) {
    $event.preventDefault();

    let ids = allIds;
    if (creditorType === 'unsecured_creditors') {
      ids = this.returnUnsecuredCreditorsIds();
    }

    this.isLoading++;
    this.isLoadingIds = ids;
    const observable  = creditorType === 'unsecured_creditors' ?
      this.creditorService.sendLoanCancellationEmailUnsecured(this.case.id, ids) :
      this.creditorService.sendLoanCancellationEmail(ids[0]);
    observable.pipe(finalize(() => {
      this.isLoading--;
      this.isLoadingIds = [];
    }))
      .subscribe(
        value => {
          this.toast.success(this.translate.instant('CASES.details.email-sent'));
        }, error => {
          this.toast.error(this.translate.instant('CASES.details.email-sent-error'));
        });
  }

  public getRightOfAccess(clientRole: 'client' | 'partner', creditorId: number, $event, creditorType?: string) {
    $event.preventDefault();
    let ids = creditorId;
    if (creditorType === 'unsecured_creditors') {
      ids = this.returnUnsecuredCreditorsIds();
    }

    this.caseDocumentService.generateRightOfAccessLetter(clientRole, ids)
      .pipe(finalize(() => this.isSubmitting = false))
      .subscribe(result => {
        if (result.type === 4) {
          const fileName = 'right_of_access_' + DateTime.local().toFormat('yyyy-LL-dd_HH-mm') + 'pdf';
          saveAs(result.body, fileName);
          this.toast.success('Downloaded right of access letter');
        }
      }, err => {
        this.toast.error('Failed to generate Demanda document');
      });
  }

  changeStatus($event: MouseEvent, selection = null, type: string) {
    $event.preventDefault();
    let idsArr = this.selectionLoanCreditors.selected.map(cred => cred.pivot.id);
    let ids;
    let changeAll = 0;
    if (type === 'public_debts') {
      ids = selection.selected ? selection.selected.map(cred => cred.id) : selection;
    } else {
      ids = selection.selected ? selection.selected.map(cred => cred.pivot.id) : selection;
    }

    if(idsArr.length > 0) {
      changeAll = 1;
      ids = idsArr;
    }

    const dialogRef = this.dialog.open(ChangeCreditorStatusComponent, {
      width:  '30%',
      height: 'fit-content',
      data:   {
        case: this.case,
        ids,
        type,
        changeAll,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (selection.selected) {
        selection.clear(); //  Remove selections
      }
      if (result) {
        ids.map(id => {
          this.updateStatus(id, type, result);
        });
      }
      //this.fetchCreditors({}); makes selectors go crazy TODO
    });
  }

  checkIfDisabled(selection, type: string) {
    if (type === 'public_debts') {
      //  Used to Disable/Enable `verify and send mandate` action
      //  Check if there is selection and if selected items are verified
      //  If there are verified debt selected we disable action
      this.publicDebtsVerifyDisabled = !!(selection.source.selected.length === 0 ||
        selection.source.selected.filter(cred => cred.verified === 1).length);

      //  Used to Disable/Enable `resend mandate` action
      //  Check if there is selection and if selected items are not verified
      this.publicDebtsResendDisabled = !!(selection.source.selected.length === 0 ||
        selection.source.selected.filter(cred => cred.verified !== 1).length);
    }
    if (type === 'secured') {
      //  Used to Disable/Enable `verify and send mandate` action
      //  Check if there is selection and if selected items are verified
      //  If there are verified debt selected we disable action
      this.securedVerifyDisabled = !!(selection.source.selected.length === 0 ||
        selection.source.selected.filter(cred => cred.pivot.verified === 1).length);

      //  Used to Disable/Enable `resend mandate` action
      //  Check if there is selection and if selected items are not verified
      this.securedResendDisabled = !!(selection.source.selected.length === 0 ||
        selection.source.selected.filter(cred => cred.pivot.verified !== 1).length);

      //  Used to Disable/Enable `send mandate recovery` action
      this.securedRecoveryDisabled = !!(selection.source.selected.length === 0 ||
        selection.source.selected.filter(cred => cred.pivot.recovery_creditor_id).length);

      //  Used to Disable/Enable `send aep` action
      //  Check if there is selection and if can send aep email
      this.securedSendAepDisabled = !!(selection.source.selected.length === 0 || // No selection
        (this.case.legal_documents.filter(file => file.document_type === 'aep').length === 0 && // No `aep` files
          // No `aep-reg` files uploaded for client
          (this.case.legal_documents.filter(
              file => file.document_type === 'aep-reg' && file.uploaded_by === 'client').length === 0 ||
            // No `aep-reg` files uploaded for partner
            this.case.legal_documents.filter(
              file => file.document_type === 'aep-reg' && file.uploaded_by === 'partner').length === 0)) ||
        selection.source.selected.filter(cred => cred.pivot.aep_sent === 1).length);

      //  Used to Disable/Enable `resend aep` action
      //  Check if there is selection and if can send aep email
      this.securedResendAepDisabled = !!(selection.source.selected.length === 0 || // No selection
        (this.case.legal_documents.filter(file => file.document_type === 'aep').length === 0 && // No `aep` files
          // No `aep-reg` files uploaded for client
          (this.case.legal_documents.filter(
              file => file.document_type === 'aep-reg' && file.uploaded_by === 'client').length === 0 ||
            // No `aep-reg` files uploaded for partner
            this.case.legal_documents.filter(
              file => file.document_type === 'aep-reg' && file.uploaded_by === 'partner').length === 0)) ||
        selection.source.selected.filter(cred => cred.pivot.aep_sent !== 1).length);

      //  Used to Disable/Enable `send proposal and accord` action
      this.securedProposalAccordDisabled = !!(selection.source.selected.length === 0 || !this.isSigned ||
        selection.source.selected.filter(cred => !cred.pivot.proposal_location).length);
    }
    if (type === 'unsecured') {
      //  Used to Disable/Enable `verify and send mandate` action
      //  Check if there is selection and if selected items are verified
      //  If there are verified debt selected we disable action
      this.unsecuredVerifyDisabled = !!(selection.source.selected.length === 0 ||
        selection.source.selected.filter(cred => cred.pivot.verified === 1).length);

      //  Used to Disable/Enable `send mandate recovery` action
      this.unsecuredRecoveryDisabled = !!(selection.source.selected.length === 0 ||
        selection.source.selected.filter(cred => cred.pivot.recovery_creditor_id).length);

      //  Used to Disable/Enable `resend mandate` action
      //  Check if there is selection and if selected items are not verified
      this.unsecuredResendDisabled = !!(selection.source.selected.length === 0 ||
        selection.source.selected.filter(cred => cred.pivot.verified !== 1).length);

      //  Used to Disable/Enable `send aep` action
      //  Check if there is selection and if can send aep email
      this.unsecuredSendAepDisabled = !!(selection.source.selected.length === 0 ||
        (this.case.legal_documents.filter(file => file.document_type === 'aep').length === 0 && // No `aep` files
          // No `aep-reg` files uploaded for client
          (this.case.legal_documents.filter(
              file => file.document_type === 'aep-reg' && file.uploaded_by === 'client').length === 0 ||
            // No `aep-reg` files uploaded for partner
            this.case.legal_documents.filter(
              file => file.document_type === 'aep-reg' && file.uploaded_by === 'partner').length === 0)) ||
        selection.source.selected.filter(cred => cred.pivot.aep_sent === 1).length);

      //  Used to Disable/Enable `resend aep` action
      //  Check if there is selection and if can send aep email
      this.unsecuredResendAepDisabled = !!(selection.source.selected.length === 0 ||
        (this.case.legal_documents.filter(file => file.document_type === 'aep').length === 0 && // No `aep` files
          // No `aep-reg` files uploaded for client
          (this.case.legal_documents.filter(
              file => file.document_type === 'aep-reg' && file.uploaded_by === 'client').length === 0 ||
            // No `aep-reg` files uploaded for partner
            this.case.legal_documents.filter(
              file => file.document_type === 'aep-reg' && file.uploaded_by === 'partner').length === 0)) ||
        selection.source.selected.filter(cred => cred.pivot.aep_sent !== 1).length);

      //  Used to Disable/Enable `send proposal and accord` action
      this.unsecuredProposalAccordDisabled = !!(selection.source.selected.length === 0 || !this.isSigned ||
        selection.source.selected.filter(cred => !cred.pivot.proposal_location).length);

      this.isEmailEnabled                  = this.selectionUnsecured.selected.every(
        creditor => creditor.pivot.total_repaid > creditor.pivot.initial_balance);
      this.isGetRightOfAccessClientEnabled = this.selectionUnsecured.selected.every(
        creditor => (creditor.pivot.ownership === 'applicant' ||
          creditor.pivot.ownership ===
          'joint'));
    }

    if (type === 'excluded') {
      //  Used to Disable/Enable `verify and send mandate` action
      //  Check if there is selection and if selected items are verified
      //  If there are verified debt selected we disable action
      this.excludedVerifyDisabled = !!(selection.source.selected.length === 0 ||
        selection.source.selected.filter(cred => cred.pivot.verified === 1).length);

      //  Used to Disable/Enable `send mandate recovery` action
      this.excludedRecoveryDisabled = !!(selection.source.selected.length === 0 ||
        selection.source.selected.filter(cred => cred.pivot.recovery_creditor_id).length);

      //  Used to Disable/Enable `resend mandate` action
      //  Check if there is selection and if selected items are not verified
      this.excludedResendDisabled = !!(selection.source.selected.length === 0 ||
        selection.source.selected.filter(cred => cred.pivot.verified !== 1).length);

      //  Used to Disable/Enable `send aep` action
      //  Check if there is selection and if can send aep email
      this.excludedSendAepDisabled = !!(selection.source.selected.length === 0 ||
        (this.case.legal_documents.filter(file => file.document_type === 'aep').length === 0 && // No `aep` files
          // No `aep-reg` files uploaded for client
          (this.case.legal_documents.filter(
              file => file.document_type === 'aep-reg' && file.uploaded_by === 'client').length === 0 ||
            // No `aep-reg` files uploaded for partner
            this.case.legal_documents.filter(
              file => file.document_type === 'aep-reg' && file.uploaded_by === 'partner').length === 0)) ||
        selection.source.selected.filter(cred => cred.pivot.aep_sent === 1).length);

      //  Used to Disable/Enable `resend aep` action
      //  Check if there is selection and if can send aep email
      this.excludedResendAepDisabled = !!(selection.source.selected.length === 0 ||
        (this.case.legal_documents.filter(file => file.document_type === 'aep').length === 0 && // No `aep` files
          // No `aep-reg` files uploaded for client
          (this.case.legal_documents.filter(
              file => file.document_type === 'aep-reg' && file.uploaded_by === 'client').length === 0 ||
            // No `aep-reg` files uploaded for partner
            this.case.legal_documents.filter(
              file => file.document_type === 'aep-reg' && file.uploaded_by === 'partner').length === 0)) ||
        selection.source.selected.filter(cred => cred.pivot.aep_sent !== 1).length);

      //  Used to Disable/Enable `send proposal and accord` action
      this.excludedProposalAccordDisabled = !!(selection.source.selected.length === 0 || !this.isSigned ||
        selection.source.selected.filter(cred => !cred.pivot.proposal_location).length);
    }

    if (type === 'claim') {
      //  Used to Disable/Enable `verify and send mandate` action
      //  Check if there is selection and if selected items are verified
      //  If there are verified debt selected we disable action
      this.claimVerifyDisabled = !!(selection.source.selected.length === 0 ||
        selection.source.selected.filter(cred => cred.pivot.verified === 1).length);

      //  Used to Disable/Enable `send mandate recovery` action
      this.claimRecoveryDisabled = !!(selection.source.selected.length === 0 ||
        selection.source.selected.filter(cred => cred.pivot.recovery_creditor_id).length);

      //  Used to Disable/Enable `resend mandate` action
      //  Check if there is selection and if selected items are not verified
      this.claimResendDisabled = !!(selection.source.selected.length === 0 ||
        selection.source.selected.filter(cred => cred.pivot.verified !== 1).length);

      //  Used to Disable/Enable `send aep` action
      //  Check if there is selection and if can send aep email
      this.claimSendAepDisabled = !!(selection.source.selected.length === 0 ||
        (this.case.legal_documents.filter(file => file.document_type === 'aep').length === 0 && // No `aep` files
          // No `aep-reg` files uploaded for client
          (this.case.legal_documents.filter(
              file => file.document_type === 'aep-reg' && file.uploaded_by === 'client').length === 0 ||
            // No `aep-reg` files uploaded for partner
            this.case.legal_documents.filter(
              file => file.document_type === 'aep-reg' && file.uploaded_by === 'partner').length === 0)) ||
        selection.source.selected.filter(cred => cred.pivot.aep_sent === 1).length);

      //  Used to Disable/Enable `resend aep` action
      //  Check if there is selection and if can send aep email
      this.claimResendAepDisabled = !!(selection.source.selected.length === 0 ||
        (this.case.legal_documents.filter(file => file.document_type === 'aep').length === 0 && // No `aep` files
          // No `aep-reg` files uploaded for client
          (this.case.legal_documents.filter(
              file => file.document_type === 'aep-reg' && file.uploaded_by === 'client').length === 0 ||
            // No `aep-reg` files uploaded for partner
            this.case.legal_documents.filter(
              file => file.document_type === 'aep-reg' && file.uploaded_by === 'partner').length === 0)) ||
        selection.source.selected.filter(cred => cred.pivot.aep_sent !== 1).length);

      //  Used to Disable/Enable `send proposal and accord` action
      this.claimProposalAccordDisabled = !!(selection.source.selected.length === 0 || !this.isSigned ||
        selection.source.selected.filter(cred => !cred.pivot.proposal_location).length);
    }
  }

  sendLastChance($event: MouseEvent, selection) {
    $event.preventDefault();
    const ids = selection.selected ? selection.selected.map(cred => cred.pivot.id) : selection;
    Swal.fire({
      title:              this.translate.instant('SHARED.warning'),
      text:               this.translate.instant('CASES.details.send-last-chance-confirm'),
      icon:               'warning',
      showCancelButton:   true,
      confirmButtonText:  this.translate.instant('SHARED.send'),
      confirmButtonColor: '#886AB5',
      cancelButtonText:   this.translate.instant('SHARED.cancel'),
    }).then(res => {
      if (res.isConfirmed) {
        this.isLoading++;
        this.isLoadingIds = ids;

        this.creditorService.sendLastChanceEmail(ids[0], ids.length > 1 ? {selected_ids: ids} : null)
          .pipe(finalize(() => {
            this.isLoading--;
            this.isLoadingIds = [];
          }))
          .subscribe(
            value => {
              this.toast.success(this.translate.instant('CASES.details.email-sent'));
              if (selection.selected) {
                selection.clear(); //  Remove selections
              }
            }, error => {
              if (error.error.errors.case.errors && error.error.errors.case.errors.length) {
                this.throwMultipleErrors(error.error.errors.case.errors);
              } else {
                this.toast.error(this.translate.instant('CASES.details.email-sent-error'));
              }
            });
      }
    });
  }

  private returnUnsecuredCreditorsIds(): any {
    const unsecuredCreditorIds = [];
    this.selectionUnsecured.selected.forEach(singleCreditor => {
      unsecuredCreditorIds.push(singleCreditor.pivot.id);
    });
    return unsecuredCreditorIds;
  }

  sendEmail($event: MouseEvent, id, creditor?) {
    $event.preventDefault();

    const unsecuredCreditorIds = [];
    if (creditor === 'unsecured_creditors') {
      this.selectionUnsecured.selected.forEach(singleCreditor => {
        unsecuredCreditorIds.push(singleCreditor.id);
      });
    }
    Swal.fire({
      title:              this.translate.instant('SHARED.warning'),
      text:               this.translate.instant('CASES.details.send-last-chance-confirm'),
      icon:               'warning',
      showCancelButton:   true,
      confirmButtonText:  this.translate.instant('SHARED.send'),
      confirmButtonColor: '#886AB5',
      cancelButtonText:   this.translate.instant('SHARED.cancel'),
    }).then(res => {
      if (res.isConfirmed) {
        this.isLoading++;
        this.isLoadingIds = id;
        const observable  = creditor === 'unsecured_creditors' ?
          this.creditorService.sendAzcarateEmailUnsecured(this.case.id, unsecuredCreditorIds) :
          this.creditorService.sendAzcarateEmail(id);
        observable.pipe(finalize(() => {
          this.isLoading--;
          this.isLoadingIds = null;
        }))
          .subscribe(
            value => {
              this.toast.success(this.translate.instant('CASES.details.email-sent'));
            }, error => {
              this.toast.error(this.translate.instant('CASES.details.email-sent-error'));
            });
      }
    });
  }

  public previewEmail(emailType: 'SettlementDemand' | 'ExtrajudicialNotice', caseId: number, creditorId: number) {
    this.caseCreditorService.previewEmail(caseId, creditorId, emailType).subscribe(result => {
        const emailHeader = {
          from:    result.data.from,
          to:      result.data.to,
          cc:      result.data.cc,
          subject: result.data.subject,
        };

        const dialogRef = this.dialog.open(EmailPreviewComponent, {
          width:  '310mm',
          height: '100vh',
          data:   {
            title:       'Creditor Document Request Preview',
            previewType: 'email',
            emailHeader,
            emailBody:   result.data.body,
            attachments: result.data.attachments,
          },
        });

        dialogRef.afterClosed().subscribe(res => {
          if (res && res.action === 'send-email') {
            this.caseCreditorService.sendEmail(caseId, creditorId, emailType).subscribe(
              r => this.toast.success('Sent ' + emailType),
              errorResponse => this.parseValidationErrors(errorResponse),
            );
          }
          if (res && res.action === 'save-and-download-document' && res.params?.type) {
            this.caseCreditorService.saveAndDownloadDocument(caseId, creditorId, res.params.type).subscribe(
              r => {
                if (r.type === 4) {
                  const fileName = res.params?.type + '_' + DateTime.local().toFormat('yyyy-LL-dd_HH-mm') + '.pdf';
                  saveAs(r.body, fileName);
                  this.toast.success('Document downloaded');
                }
              },
              errorResponse => this.parseValidationErrors(errorResponse),
            );
          }
        });
      },
      errorResponse => this.parseValidationErrors(errorResponse));
  }

  private parseValidationErrors(errorResponse: any) {
    const error = errorResponse.error;
    if (+error.code === 422 && error.errors) {
      for (const [key, value] of Object.entries(error.errors)) {
        const valueArray = value as any;
        valueArray.forEach(e => this.toast.error(e));
      }
    }
  }

  public returnLenderType(lenderType: 'standard' | 'high_interest' | 'collections') {
    const types = {
      collections:   '',
      standard:      '',
      high_interest: '#FFFF63',
    };
    return types[lenderType];
  }

  public saveLoanCreditorData(element, formValue): void {
    // if formvalue for product is empty, show error
    if (!formValue.product_id) {
      this.toast.error(this.translate.instant('CASE_CREDITOR.select_product'));
      return;
    }
    formValue.percent_reduction = ((element.pivot.initial_balance - formValue.negotiated_amount) / element.pivot.initial_balance) * 100;
    this.creditorService.saveLoanCreditorsData(this.case.id, element.pivot.id, formValue)
      .subscribe(res => {
        this.fetchCreditors({});
        this.toast.success(this.translate.instant('SHARED.success'));
      }, err => {
        if (err.error?.message) {
          this.toast.error(err.error.message);
        } else {
          this.toast.error(this.translate.instant('SHARED.error'));
        }
        this.fetchCreditors({});
      });
  }
  public onExclude(element, formValue?): void {
    this.isExcluded = 1;
    const data      = {
      excluded:          this.isExcluded,
      debt_agreed:       formValue?.debt_agreed,
      monthly_repayment: formValue?.monthly_repayment,
      reference:         formValue?.reference,
      status:            formValue?.status,
    };
    this.creditorService.excludeUnsecured(this.case.id, element.pivot.id, data)
      .pipe(finalize(() => {
        this.isLoading--;
      }))
      .subscribe(
        value => {
          this.originalUnsecuredCreditors = this.originalUnsecuredCreditors.filter(
            creditor => creditor.pivot.id !== element.pivot?.id);
          this.unsecuredCreditors         = new MatTableDataSource<Creditor>(this.originalUnsecuredCreditors);
          this.originalExcludedCreditors.push(element);
          this.excludedCreditors = new MatTableDataSource<Creditor>(this.originalExcludedCreditors);
          this.excludedForm.reset();
        }, error => {
          this.toast.error(this.translate.instant('SHARED.error'));
        });
  }

  public onInclude(element): void {
    this.isExcluded = 0;
    this.creditorService.excludeUnsecured(this.case.id, element.pivot.id, {excluded: this.isExcluded})
      .pipe(finalize(() => {
        this.isLoading--;
      }))
      .subscribe(
        value => {
          this.toast.success(this.translate.instant('SHARED.success'));
          this.originalExcludedCreditors = this.originalExcludedCreditors.filter(
            creditor => creditor.pivot.id !== element.pivot?.id);
          this.excludedCreditors         = new MatTableDataSource<Creditor>(this.originalExcludedCreditors);
          this.originalUnsecuredCreditors.push(element);
          this.unsecuredCreditors = new MatTableDataSource<Creditor>(this.originalUnsecuredCreditors);
        }, error => {
          this.toast.error(this.translate.instant('SHARED.error'));
        });
  }

  private fetchStatuses(): void {
    this.isLoading++;
    this.statusService.index().pipe(finalize(() => this.isLoading--)).subscribe(result => {
        this.statuses = result.data;
      },
    );
  }

  public previewCreditorProposal(): void {
    const element          = this.selectionUnsecured.selected[0];
    const navigationExtras = {
      queryParams: {uuid: this.case.uuid, case_creditor_id: element.pivot.id, ownership: element.pivot.ownership},
    };
    this.router.navigate(['/creditor-proposal-preview'], navigationExtras);
  }

  public downloadFile(documentType: string): void {
    const document = this.selectionUnsecured.selected[0];
    if (documentType === 'proposal') {
      saveAs(this.storageUrl + document.pivot.proposal_location);
    } else if (documentType === 'proposal_accord') {
      saveAs(this.storageUrl + document.pivot.proposal_location_accord);
    }
  }

  private pushToCreditorTable(value: CaseCreditor, nextTable: 'secured' | 'unsecured' | 'claim'): void {
    if (nextTable === 'claim') {
      this.originalClaimCreditors.push(value);
      this.claimCreditors = new MatTableDataSource<Creditor>(this.originalClaimCreditors);
    } else if (nextTable === 'secured') {
      this.originalSecuredCreditors.push(value);
      this.securedCreditors = new MatTableDataSource<Creditor>(this.originalSecuredCreditors);
    } else if (nextTable === 'unsecured') {
      this.originalUnsecuredCreditors.push(value);
      this.unsecuredCreditors = new MatTableDataSource<Creditor>(this.originalUnsecuredCreditors);
    }
  }

  public changeCreditorTable(value: CaseCreditor, creditorType: 'secured' | 'unsecured' | 'claim',
                             prevCreditorType: 'unsecured' | 'secured' = null): void {
    if (creditorType === 'claim' && prevCreditorType === 'secured') {
      this.originalClaimCreditors = this.originalClaimCreditors.filter(
        creditor => creditor.pivot.id !== value.pivot?.id);
      this.claimCreditors         = new MatTableDataSource<Creditor>(this.originalClaimCreditors);
      this.pushToCreditorTable(value, prevCreditorType);
    }
    if (creditorType === 'claim' && prevCreditorType === 'unsecured') {
      this.originalClaimCreditors = this.originalClaimCreditors.filter(
        creditor => creditor.pivot.id !== value.pivot?.id);
      this.claimCreditors         = new MatTableDataSource<Creditor>(this.originalClaimCreditors);
      this.pushToCreditorTable(value, prevCreditorType);
    }
    if (creditorType === 'unsecured') {
      this.originalUnsecuredCreditors = this.originalUnsecuredCreditors.filter(
        creditor => creditor.pivot.id !== value.pivot?.id);
      this.unsecuredCreditors         = new MatTableDataSource<Creditor>(this.originalUnsecuredCreditors);
      this.pushToCreditorTable(value, 'claim');
    }
    if (creditorType === 'secured') {
      this.originalSecuredCreditors = this.originalSecuredCreditors.filter(
        creditor => creditor.pivot.id !== value.pivot?.id);
      this.securedCreditors         = new MatTableDataSource<Creditor>(this.originalSecuredCreditors);
      this.pushToCreditorTable(value, 'claim');
    }
  }

  public toggleType(value: CaseCreditor, creditorType: 'unsecured' | 'claim' | 'secured',
                    prevCreditorType = null): void {
    if (creditorType === 'unsecured') {
      this.caseService.toggleIsClaimable(this.case.id, value.pivot?.id, {type: 'claim'})
        .pipe(first())
        .subscribe(
          (data) => {
            this.toastr.success(this.translate.instant('CASES.editor.creditors.result.success'));
          },
          error => {
            this.toastr.error(this.translate.instant('CASES.editor.creditors.result.error'), error.error.message);
          },
        );
      this.changeCreditorTable(value, creditorType);
    } else if (creditorType === 'secured') {
      this.caseService.toggleIsClaimable(this.case.id, value.pivot?.id, {type: 'claim'})
        .pipe(first())
        .subscribe(
          (data) => {
            this.toastr.success(this.translate.instant('CASES.editor.creditors.result.success'));
          },
          error => {
            this.toastr.error(this.translate.instant('CASES.editor.creditors.result.error'), error.error.message);
          },
        );
      this.changeCreditorTable(value, creditorType);
    } else if (creditorType === 'claim') {
      this.caseService.toggleIsClaimable(this.case.id, value.pivot?.id, {type: prevCreditorType})
        .pipe(first())
        .subscribe(
          (data) => {
            this.toastr.success(this.translate.instant('CASES.editor.creditors.result.success'));
          },
          error => {
            this.toastr.error(this.translate.instant('CASES.editor.creditors.result.error'), error.error.message);
          },
        );
      this.changeCreditorTable(value, creditorType, prevCreditorType);
    } else if (creditorType === 'claim') {
      this.caseService.toggleIsClaimable(this.case.id, value.pivot?.id, {type: prevCreditorType})
        .pipe(first())
        .subscribe(
          (data) => {
            this.toastr.success(this.translate.instant('CASES.editor.creditors.result.success'));
          },
          error => {
            this.toastr.error(this.translate.instant('CASES.editor.creditors.result.error'), error.error.message);
          },
        );
      this.changeCreditorTable(value, creditorType, prevCreditorType);
    }
  }

  public sendCase(clientCase: Case, projectId: string): void {
    Swal.fire({
      title: this.translate.instant('CASE_CREDITOR.refer_case.heading'),
      text: this.translate.instant('CASE_CREDITOR.refer_case.warning-text') + ' ' + projectId.toUpperCase(),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'OK',
      cancelButtonText:   this.translate.instant('SHARED.cancel'),
    }).then(res => {
      if (res.isConfirmed) {
        this.isLoading++;
        this.caseService.sendCase(clientCase.id, {destination_project_id: projectId})
          .pipe(finalize(() => this.isLoading--))
          .subscribe(result => {
              if (projectId === 'dvl') {
                this.case.dvl_ref_number   = result.data.case.dvl_ref_number;
              }

              if (projectId === 'epi') {
                this.case.epi_ref_number   = result.data.case.epi_ref_number;
              }

              this.toast.success('Sent case successfully');
            },
            error => {
              let errorMessage = 'Failed to send case';
              if (error.error.status === 422) {
                const parsedError = JSON.parse(error.error.message);
                errorMessage = parsedError[Object.keys(parsedError)[0]][0];
              }
              errorMessage = errorMessage.replace('.', ' ');
              this.toast.error(errorMessage);
            },
          );
      }
    });
  }

  private throwMultipleErrors(errorsArray: Array<string> | Array<{ errors: string, id: number, name: string,
    reference_number: string }>): void {
    errorsArray.forEach(caseCreditorError => {
      this.toast.error(caseCreditorError, '',
        {disableTimeOut: true});
    });
  }

  public generateProposal(): void {

    // check if the income and expenses are filled and not zero before generating proposal
    // if (this.case.income === 0 || this.case.expenses === 0) {
    //   this.toastr.error(this.translate.instant('CASES.single.draft.proposal.income_expenses_zero'));
    //   return;
    // }
    // get the data from financialoverviewservice
    this.financialOverviewService.fetchLatest(this.case.id).subscribe(
      res => {
        if (res.data.income === 0 || res.data.expenses === 0) {
          Swal.fire({
            title: 'Error, Incomes or Expenses are zero',
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: this.translate.instant('SHARED.close'),
          });
          return false;
        } else {

          const dialogRef = this.dialog.open(ProposalModalComponent, {
            width: '90%',
            height: '85%',
            data: {
              case: this.case
            },
          });

          dialogRef.afterClosed().subscribe(result => {
            if (result && !result.dismissed && result.data) {
              this.case.proposal = result.data;
              this.fetchCase(this.case.id);
            }
          });
        }

        // this.openProposalModal();
      },
      err => {
        this.toastr.error(this.translate.instant('CASES.single.draft.proposal.income_expenses_zero'));
      }
    );
  }

  public previewProposal(clientRole: string): void {
    let location = (environment.APP_URL.replace('app.', 'clientes.')) +
      '/public/sign-proposal?uuid=' + this.case.uuid + '&sign=' + clientRole;
    window.open(location, '_blank');
  }

  public requestProposalSignature(): void {
    this.caseDocumentService.requestProposalSignature(this.case.id).subscribe(res => {
      this.toastr.success(this.translate.instant('CASES.single.draft.proposal.request_signature.response.success'));
    }, err => {
      this.toastr.error(this.translate.instant('SHARED.went-wrong'));
    });
  }

  public sendProposalToCreditors(caseId: number): void {
    if (!this.case.client.id_card) {
      this.toastr.error(this.translate.instant('CLIENT.auth-payment.dni_required'));
      return;
    }
    this.caseDocumentService.sendProposalToCreditors(this.case.id).subscribe(res => {
      this.toastr.success(this.translate.instant('CASES.single.draft.proposal.proposal_sent_to_creditors'));
    }, err => {
      this.toastr.error(this.translate.instant('SHARED.went-wrong'));
    });
  }

  public getTotal(columnName: string): number {
    let total = 0;

    if (this.loanCreditors) {
      if (columnName === 'debt_amount'){
        this.loanCreditors.data.forEach(data => {
          total += +(data.pivot.debt_amount  || 0);
        });
      }

      if (columnName === 'negotiated_amount'){
        this.loanCreditors.data.forEach(data => {
          total += +data.pivot.negotiated_amount;
        });
      }

      if(columnName === 'initial_balance'){
        this.loanCreditors.data.forEach(data => {
            total += +(data.pivot.initial_balance || 0);
        });
      }

      if (columnName === 'total_reduction'){
        let negotiatedAmount = 0;
        let debtAmount = 0;
        this.loanCreditors.data.forEach(data => {
          negotiatedAmount += +data.pivot.negotiated_amount;
          debtAmount += +(data.pivot.debt_amount || 0);
        });

        if (debtAmount === 0) {
          total = 0;
        } else {
          total = debtAmount - negotiatedAmount;
        }
      }

      if (columnName === 'percent_reduction'){
        let debtAmount = 0;
        let negotiatedAmount = 0;

        this.loanCreditors.data.forEach(data => {
            debtAmount += +(data.pivot.initial_balance || 0);
            negotiatedAmount += +data.pivot.negotiated_amount;
        });
        if (debtAmount === 0) {
          total = 0;
        } else {
          total = +(+(+(debtAmount - negotiatedAmount) / debtAmount) * 100);
        }
      }

      return total;
    }
  }

  private fetchProducts() {
    this.isLoading++;
    this.productService.index({ select_all: 1 }).pipe(finalize(() => this.isLoading--)).subscribe(
      result => this.products = result.data
    );
  }

  // return product name by id
  public getProductName(id: number): string {
    return this.products.find(product => product.id === id)?.name;
  }

  openLinkInNewWindow(location, params = {}): void {
    Object.keys(params).forEach(key => {
      location += location.includes('?') ? '&' : '?';
      location += key + '=' + params[key];
    });
    if (location.includes('http') || location.includes('https')) {
      window.open(location, '_blank')
    } else {
      location = environment.APP_URL + location;
      window.open(location, '_blank');
    }
  }

  creditorDetails(creditor: Creditor, element): void {
    console.log(creditor);
    this.dialog.open(CaseCreditorInfoComponent, {
      width: 'fit-content',
      data: creditor,
    })
  }

  generateExternalLoan(): void {
    this.caseDocumentService.generateExternalLoan(this.case.id).subscribe(res => {
      console.log(res);
      this.case.loan_request_pdf_location = res.data;
      this.toastr.success('Loan Request PDF generated successfully, you can now download it');
    }, err => {
      this.toastr.error('Failed to generate loan pdf');
    });
  }

  private fetchCreditorsNoFilter() {
    this.isLoading++;
    this.creditorService.index({ all: 1, active: 1 }).pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
          this.allCreditors = result.data;
        },
        err => console.error(err)
      );
  }

  public getUnsecuredCreditorName(id: number): string {
    return this.allCreditors.find(creditor => creditor.id === id)?.name;
  }

  public getResponseReceivedStatus(creditors): string {
    let percent       = 0;
    let acceptedDebt  = 0;
    this.loanCreditors.data.forEach(creditor => {
      if ((creditor.pivot.response_received == 'dm_accepted' || creditor.pivot.response_received == 'dm_signed')) {
        acceptedDebt += +creditor.pivot.debt_amount;
      }
    });

    percent = (acceptedDebt / this.totalDebt) * 100;
    return percent.toFixed(2);
  }

  public getDebtPercentage(creditor): number {
    // if(creditor.pivot.product_id === 15) {
      return (creditor.pivot.debt_amount / this.totalDebt) * 100;
    // }
  }

  public openPaymentPlanGenerationModal(additional_data) {
    const dialogRef = this.dialog.open(CasePaymentPlanGeneratorComponent, {
      width: '60%',
      data:  {
        case: this.case,
        creditor: additional_data
      },
    });
  }

  public viewQuickWin(creditor: Creditor, client: Case) {
    this.dialog.open(QuickWinInfoComponent, {
      width: 'fit-content',
      data: {creditor, client}
    });
  }

  public markAsRefinanced(creditor: Creditor) {
    this.dialog.open(CreditorRefinanced, {
      width: 'fit-content',
      data: {creditor}
    });
  }

  public getTranslatedCaseCreditorStatus(creditor): string {
    if(creditor.status) {
      return ((this.authUser.locale === 'en') ? creditor.status.name_en : creditor.status.name_es) ?? '';
    }
  }

  public showInfo(creditor: CaseCreditor) {
    let currentCreditorName = creditor.name;
    let originalCreditorName = this.getUnsecuredCreditorName(creditor.pivot.current_creditor_id);

    let swalString = `
      <table style="text-align: left; ">
          <tr>
              <td style="white-space: nowrap;">${this.translate.instant('CASE_CREDITOR.model.relations.current_creditor')}:</td>
              <td style="padding-left:10px;white-space: nowrap">${currentCreditorName}</td>
          </tr>
          <tr><td>&nbsp;</td><td>&nbsp;</td></tr>
          <tr>
              <td style="white-space: nowrap;">${this.translate.instant('CASE_CREDITOR.model.relations.original_creditor')}:</td>
              <td style="padding-left:10px;white-space: nowrap">${originalCreditorName}</td>
          </tr>
      </table>
    `
    Swal.fire({
      title: this.translate.instant('CASE_CREDITOR.info.creditor_info'),
      html: swalString,
      showCancelButton: false,
      width: '650px',
      confirmButtonText: this.translate.instant('SHARED.close'),
    });
  }

  public isElementRestricted(){

    if (!this.case || !this.case.status_id) {
      return false;
    }

    const enabledRoles = environment.ALLOW_CASES_ROLES_IDS;
    const enabledStatusId = environment.BLOCK_CASES_STATUS_IDS;
    const enabledDepartmentsId = environment.ALLOW_CASES_DEPARTMENTS_IDS;

    const isDepartmentAllowed = this.authUser.departments.some(department => 
      enabledDepartmentsId.includes(department.id)
    );

    if ( 
      !enabledStatusId.includes(this.case.status_id) || 
       enabledRoles.includes(this.authUser.role_id) ||
       isDepartmentAllowed
    ){
      return true;
    }
    return false;
  }

  public sendExtrajudicialClaim(selectedCaseCreditors): void {
    const selectedIds = selectedCaseCreditors.selected ?
      selectedCaseCreditors.selected.map(cred => cred.pivot.id) :
      selectedCaseCreditors;
    this.isLoading++;
    this.isLoadingIds = selectedIds;
    console.log(this.case.id, {selected_ids: selectedIds});
    this.creditorService.sendExtrajudicialClaim(this.case.id, {selected_ids: selectedIds})
      .pipe(finalize(() => {
        this.isLoading--;
        this.isLoadingIds = [];
        if (selectedCaseCreditors.selected) {
          selectedCaseCreditors.clear(); //  Remove selections
        }
      }))
      .subscribe(
        value => {
          this.toast.success(this.translate.instant('SHARED.success'));
        },
        error => {
          if (error) {
            this.throwMultipleErrors(error.error.errors.case.errors);
          } else {
            this.toast.error(this.translate.instant('SHARED.error'));
          }
        });
  }

  public calculatePercentageNeededForTarget(value) {
    let totalDebt = 0;
    let annualPayment = value * 12;
    if(this.loanCreditors) {
        this.loanCreditors.data.forEach(creditor => {
        totalDebt += +creditor.pivot.debt_amount;
        });
    }
    let annualPaymentPercentage = (annualPayment / totalDebt) * 100;
    if (annualPaymentPercentage < 0) {
      return 0;
    }
    return 100 - annualPaymentPercentage;
  }

  private fetchCase(caseId: number) {
    this.caseService.get(caseId, ['product']).pipe(finalize(() => this.isLoading--)).subscribe(result => {
      this.isLoading++;
      this.isProductDm  = result.data.product?.group_slug === 'dmp';
      this.isProductLso = result.data.product?.group_slug === 'lso';
      this.useCreditorLoanFields = true;
      this.caseDocumentService.indexCaseDocuments(caseId)
        .pipe(finalize(() => this.isLoading--))
        .subscribe(res => {
          this.case         = res.data;
          this.case.product = result.data.product;
          this.fetchStatuses();
          this.fetchProducts();
          this.buildDMForm();
          this.fetchData();
          this.buildDisplayColumns();
          this.buildForm();
          this.buildLoanCreditorsForm();
          this.caseDocumentService.index(caseId, ['legal_documents'])
            .pipe(finalize(() => this.isLoading--))
            // tslint:disable-next-line:no-shadowed-variable
            .subscribe(res => {
              this.case.legal_documents = res.data.legal_documents;
              this.missingAepFiles      = !!(this.case.legal_documents.filter(
                  file => file.document_type === 'aep').length === 0 &&
                (this.case.legal_documents.filter(
                    file => file.document_type === 'aep-reg' && file.uploaded_by === 'client').length === 0 ||
                  this.case.legal_documents.filter(
                    file => file.document_type === 'aep-reg' && file.uploaded_by === 'partner').length === 0));
              this.latestPropuestaPdfLocation  = this.case.creditor_proposal_pdf?.proposal_location;
              this.latestPropuestaClientPdfLocation  = this.case.proposal_pdf?.path;
            });
          this.isLoading++;
          this.caseDocumentService.showPropuestoModelo(caseId)
            .pipe(finalize(() => this.isLoading--))
            // tslint:disable-next-line:no-shadowed-variable
            .subscribe(res => {
              this.propuestoModelo = res.data;
              if (this.propuestoModelo) {
                this.isSigned = this.case.joint_application ?
                  !!(this.propuestoModelo.signature && this.propuestoModelo.signature_partner) :
                  !!this.propuestoModelo.signature;
              } else {
                this.isSigned = false;
              }
            });
          this.selectionSecured.changed.subscribe(next => {
            this.checkIfDisabled(next, 'secured');
          });
          this.selectionUnsecured.changed.subscribe(next => {
            this.checkIfDisabled(next, 'unsecured');
          });
          this.selectionPublicDebts.changed.subscribe(next => {
            this.checkIfDisabled(next, 'public_debts');
          });

          this.form.valueChanges.subscribe(value => {
            const filters = this.getFilters();
            this.fetchData(filters);
          });
          this.loanCreditorsForm.get('product_id').valueChanges.subscribe(value => {
            this.selectedProduct = this.products.find(product => product.id === value);
          });

          this.financialOverviewService.fetchLatest(this.case.id).subscribe(overview => {
              this.targetDisposable = overview.data.target_monthly_payment;
              this.disposableIncome = (overview.data.income - overview.data.expenses);
              if(this.disposableIncome) {
                  console.log('disposableIncome', this.disposableIncome);
              }
          });
        });
    });
  }

  public getDaysInStatus(element): number {
    console.log(element);
    if (element.pivot.status_log.created_at) {
      return Math.ceil(DateTime.fromISO(element.pivot.status_log.created_at).diffNow('days').negate().as('days'));
    }
  }
}
