import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
//import { ReportService } from '../../report.service';
import { DistributionService } from '../../distribution.service';
import { Creditor } from 'projects/_base-shared/models/Entity/Creditor';
import { CreditorService } from '../../../creditor/creditor.service';

@Component({
  selector:    'app-remittance-report-modal',
  templateUrl: './remittance-report-modal.component.html',
  styleUrls:   ['./remittance-report-modal.component.scss'],
})
export class RemittanceReportModalComponent implements OnInit {
  public form: UntypedFormGroup;
  public isLoading = 0;
  public creditors: Array<Creditor> = [];

  constructor(
      private toast: ToastrService,
      private reportService: DistributionService,
      private fb: UntypedFormBuilder,
      public dialogRef: MatDialogRef<RemittanceReportModalComponent>,
      private creditorService: CreditorService,
      @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  ngOnInit(): void {
    this.fetchCreditors();
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      creditor_id: [null],
      start_date: [null, Validators.required],
      end_date:   [null, Validators.required],
    });
  }

  sendReportData() {
    if (!this.form.valid) {
      return;
    }

    const requestData = {
      creditor_id: this.form.get('creditor_id').value ? this.form.get('creditor_id').value : null,
      start_date: this.form.value.start_date.toLocaleDateString('en-CA'),
      end_date:   this.form.value.end_date.toLocaleDateString('en-CA'),
    };
    this.isLoading++;

    this.reportService.generateRemittanceDistReport(requestData).pipe(finalize(() => this.isLoading--))
        .subscribe(res => {
              // const fileName = 'sales_report' + moment().format('YYYY-MM-DD_HH-mm') + '.csv';
              // saveAs(res.body, fileName);
              this.dialogRef.close(true);
              this.toast.success(res.message);

            },
            err => console.error(err),
        ); 
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  private fetchCreditors() {
    this.isLoading++;
    this.creditorService.index({all: 1, active: 1}).pipe(finalize(() => this.isLoading--)).subscribe(
        result => this.creditors = result.data,
        err => console.error(err),
    );
  }
}
