import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ToastrService } from 'ngx-toastr';
import { ReportService } from '../report.service';
import { MiscConfigService } from '../../config/misc-config/misc-config.service';
import { CreditorService } from '../../creditor/creditor.service';
import { finalize } from 'rxjs/operators';
import { Creditor } from 'projects/_base-shared/models/Entity/Creditor';
import { Router } from '@angular/router';

@Component({
  selector:    'app-missing-documents',
  templateUrl: './creditor-import.component.html',
  styleUrls:   ['./creditor-import.component.scss'],
})
export class CreditorImportComponent implements OnInit {
  public form: UntypedFormGroup;

  public csvContent: Array<any> = [];
  public multipleCreditorsForCase: Array<any> = [];
  public noCreditorsForCase: Array<any> = [];
  public proposalsPresented: Array<any> = [];
  public casesInDMAccepted: Array<any> = [];

  public dataSource: MatTableDataSource<any>;
  public fdsDisplayedColumns: string[] = [
    'ref_number',
    'name',
    'dni',
    'debt_amount',
    'offered_amount',
    'percentage_reduction',
  ];

  public dmpDisplayedColumns: string[] = [
    'ref_number',
    'dni',
    'name',
    'aceptada',
  ];

  public updateStatusDisplayedColumns: string[] = [
    'ref_number',
    'dni',
    'name',
  ];

  public updateAmountDisplayedColumns: string[] = [
    'ref_number',
    'dni',
    'name',
    'orig_client_debt_amount',
    'client_debt_amount',
    'debt_amount',
    'offered_amount',
  ];

  public multipleCreditorsForCaseDisplayedColumns: string[] = [
    'ref_number',
    'dni',
    'creditor_count',
  ];

  public noCreditorsForCaseDisplayedColumns: string[] = [
    'ref_number',
    'dni',
  ];

  public proposalsPresentedDisplayedColumns: string[] = [
    'ref_number',
    'dni',
    'name',
    'debt_amount',
    'offered_amount'
  ];

  public casesInDMAcceptedDisplayedColumns: string[] = [
    'ref_number',
    'case_status',
    'dni',
  ];

  public updateCreditorsDisplayedColumns: string[] = [
    'dni',
    'name',
    'debt_amount',
    'offered_amount',
    'from_creditor',
    'to_creditor',
  ];

  public paginatorConfig            = {
    pageIndex: 0,
    pageSize:  10,
    length:    1,
  };

  public isLoading           = 0;
  public simpleExportLoading = 0;
  public exportLoading       = 0;
  public creditors: Array<Creditor> = [];
  public creditorSelected: boolean = false;

  public badPercentage: number;
  public decentPercentage: number;
  public goodPercentage: number;

  private creditor_id: number;
  public selectedCreditorName: string;
  public selectedOption: string = 'fds';

  constructor(
      private toast: ToastrService,
      private reportService: ReportService,
      private fb: UntypedFormBuilder,
      private configService: MiscConfigService,
      private creditorService: CreditorService,
      protected router: Router,) {
  }

  ngOnInit(): void {
    // get percentage reduction from config
    this.configService.getConfigData().subscribe(next => {
      this.badPercentage    = next.data.find(obj => obj.key === 'percentage_reduction_bad').value;
      this.decentPercentage = next.data.find(obj => obj.key === 'percentage_reduction_decent').value;
      this.goodPercentage   = next.data.find(obj => obj.key === 'percentage_reduction_good').value;
    });
    this.fetchCreditors();
    // file upload form
    this.form = this.fb.group({
      creditor_id: [null],
      file: [null],
      import_type: ['fds'],
    });

    // form change listener for the creditor_id field
    this.form.get('creditor_id').valueChanges.subscribe((value) => {
      this.creditor_id = value;
      this.selectedCreditorName = this.creditors.find(creditor => creditor.id === value).name;
      this.creditorSelected = true;
    });
    this.form.get('import_type').valueChanges.subscribe((value) => {
      this.selectedOption = value;
      this.csvContent = [];
      this.multipleCreditorsForCase = [];
      this.noCreditorsForCase = [];
      this.casesInDMAccepted = [];
      this.proposalsPresented = [];
      if(value === 'updatecreditors') {
        this.creditorSelected = true;
      }
    });
    
  }

  // submit file prevent default
  public submitFile(event: Event): void {
    event.preventDefault();
  }

  public changeListener(files: FileList) {
    // clear the tables
    this.csvContent = [];
    this.multipleCreditorsForCase = [];
    this.noCreditorsForCase = [];
    this.casesInDMAccepted = [];
    this.proposalsPresented = [];

    const creditorIdString = this.form.get('import_type').value === 'updatecreditors' ? 'updatecreditors' : this.creditor_id.toString();

    const formData = new FormData();
    formData.append('file', files[0]);
    formData.append('creditor_id', creditorIdString);
    formData.append('import_type', this.selectedOption);

    this.isLoading = 1;
    this.reportService.uploadCreditorFile(formData).subscribe((res: any) => {
      this.isLoading = 0;
      this.csvContent = res.data.result;
      this.multipleCreditorsForCase = res.data.multipleCreditorsForCase;
      this.noCreditorsForCase = res.data.noCreditorsForCase;
      this.proposalsPresented = res.data.proposalPresentArray;
      this.casesInDMAccepted  = res.data.casesInDMAccepted;
      this.dataSource = new MatTableDataSource<any>(this.csvContent);
      this.paginatorConfig.length = this.csvContent.length;
    }, (err) => {
      this.isLoading = 0;
      this.toast.error(err.error.message);
    });
  }

  private fetchCreditors() {
    this.isLoading++;
    this.creditorService.index({all: 1, active: 1}).pipe(finalize(() => this.isLoading--)).subscribe(
        result => this.creditors = result.data,
        err => console.error(err),
    );
  }

  // function to open a link in a new tab
  public openLink(id: number): void
  {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/cases', id, 'creditors'])
    );
    window
        .open(url, '_blank')
        .focus();
  }
}
