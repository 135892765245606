import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CaseListFilter } from '../../../../../../_base-shared/models/Case/CaseListFilter';
import { CaseService } from '../../case/case.service';
import { DistributionReportModalComponent } from '../distribution-report-modal/distribution-report-modal.component';
import { DistributionService } from '../distribution.service';
import { ReconciliationReportModalComponent } from './reconciliation-report-modal/reconciliation-report-modal.component';
import { RemittanceReportModalComponent } from './remittance-report-modal/remittance-report-modal.component';
import { finalize } from 'rxjs/operators';

@Component({
  selector:    'app-distribution-report-list',
  templateUrl: './distribution-report-list.component.html',
  styles:      [],
})
export class DistributionReportListComponent implements OnInit {
  public caseListFilter: CaseListFilter;
  // tslint:disable-next-line:max-line-length
  public displayedColumns: string[] = ['name', 'actions']; //'description',

  public businessReports: Array<{
    reportType: 'reconciliation_report_dist' | 'wallets_report_dis' | 'remittance_report_dis',
    description: string,
    title: string,
    enabled: boolean,
    reportGroup: 'distribution_report',
    action?: () => void
  }>;
  /*public performanceReports: Array<{
    reportType: 'claims' | 'scheduled_fee' | 'payments_received',
    description: string,
    title: string,
    enabled: boolean,
    reportGroup: 'performance',
    //action: (event: Event) => this.openDistributionModal($event, 'type1', 'group1')
  }>;
  public fundingReports: Array<{
    reportType: 'funding_claims' | 'funding_schedule_fee' | 'pledge',
    description: string,
    title: string,
    enabled: boolean,
    reportGroup: 'funding'
  }>;*/
  
  public isLoading = 0;
  public casesCount: number;

  constructor(
    private translate: TranslateService,
    private toastr: ToastrService,
    private caseService: CaseService,
    private distributionService: DistributionService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.buildReportOptions();
    this.caseListFilter = {has_distribution: 1, select_all: 1, only_count: 1};
  }

  private buildReportOptions() {
    this.businessReports = [
      {
        title:       'DISTRIBUTION.report.reconciliation_dist.label',
        description: null,
        reportType:  'reconciliation_report_dist',
        enabled:     true,
        reportGroup: 'distribution_report',
        action:      this.reconciliationDistModal.bind(this),
      },
      {
        title:       'DISTRIBUTION.report.wallets_dist.label',
        description: null,
        reportType:  'wallets_report_dis',
        enabled:     true,
        reportGroup: 'distribution_report',
        action:      this.walletsDistModal.bind(this),
      },
      {
        title:       'DISTRIBUTION.report.remittance_dist.label',
        description: null,
        reportType:  'remittance_report_dis',
        enabled:     true,
        reportGroup: 'distribution_report',
        action:      this.remittanceDistModal.bind(this),
      },
    ];

    /*this.performanceReports = [
      {
        title:       'DISTRIBUTION.report.claims.label',
        description: null,
        reportType:  'claims',
        enabled:     true,
        reportGroup: 'performance',
      },
      {
        title:       'DISTRIBUTION.report.scheduled_fee.label',
        description: null,
        reportType:  'scheduled_fee',
        enabled:     true,
        reportGroup: 'performance',
      },
      {
        title:       'DISTRIBUTION.report.payments_received.label',
        description: null,
        reportType:  'payments_received',
        enabled:     true,
        reportGroup: 'performance',
      },
    ];

    this.fundingReports = [
      {
        title:       'DISTRIBUTION.report.funding_claims.label',
        description: null,
        reportType:  'funding_claims',
        enabled:     true,
        reportGroup: 'funding',
      },
      {
        title:       'DISTRIBUTION.report.funding_scheduled_fee.label',
        description: null,
        reportType:  'funding_schedule_fee',
        enabled:     true,
        reportGroup: 'funding',
      },
      {
        title:       'DISTRIBUTION.report.pledge_report.label',
        description: null,
        reportType:  'pledge',
        enabled:     true,
        reportGroup: 'funding',
      },
    ];*/
  }

  public reconciliationDistModal($event): void {
    $event.stopPropagation();
    $event.preventDefault();

    this.dialog.open(ReconciliationReportModalComponent, {
      width:     '40%',
      autoFocus: false,
      minHeight: '280px',
    });
  }

  public walletsDistModal($event): void {
    $event.stopPropagation();
    $event.preventDefault();

    this.distributionService.generateWalletsExportReport().pipe(finalize(() => this.isLoading--))
        .subscribe(res => {
              // const fileName = 'sales_report' + moment().format('YYYY-MM-DD_HH-mm') + '.csv';
              // saveAs(res.body, fileName);
              this.toastr.success(res.message);

            },
            err => console.error(err),
        );
  }

  public remittanceDistModal($event): void {
    $event.stopPropagation();
    $event.preventDefault();

    this.dialog.open(RemittanceReportModalComponent, {
      width:     '40%',
      autoFocus: false,
      minHeight: '280px',
    });
  }

  /*public openDistributionModal($event, reportType, reportGroup) {
    $event.preventDefault();
    console.log(reportType);
    console.log(reportGroup);
    const dialogRef = this.dialog.open(DistributionReportModalComponent, {
      width:  '50%',
      maxHeight: '100vh',
      data:   {
        reportType,
        reportGroup,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }*/

}
