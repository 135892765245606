<div class="row pb-3">
  <div class="col-12">
    <h2>{{ 'CONFIG.drip-campaign.list.header' | translate }}</h2>
  </div>
  <div class="col-md-8">
    <button mat-raised-button color="primary" routerLink="create" type="button" class="mt-3">
      + {{ 'CONFIG.drip-campaign.list.add-new' | translate }}
    </button>
  </div>
  <div class="col-md-4">
    <app-input [formControl]="search" type="text" appearance="standard" fullWidth="true"
               [label]="translateService.instant('SHARED.search')" [prefixType]="'icon'" prefix="search">
    </app-input>
  </div>
</div>

<div class="mat-elevation-z8">
  <table mat-table matSort [dataSource]="dataSource">
    <!-- ID -->
    <ng-container matColumnDef="id">
      <th mat-header-cell mat-sort-header
          *matHeaderCellDef>{{ 'CONFIG.drip-campaign.list.table-head.id' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.id }} </td>
    </ng-container>
    <!-- Name -->
    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ 'CONFIG.drip-campaign.list.table-head.name' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.name }} </td>
    </ng-container>
    <!-- Target -->
    <ng-container matColumnDef="target">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ 'CONFIG.drip-campaign.list.table-head.target' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ formatTarget(element.target) }} </td>
    </ng-container>
    <!-- Active -->
    <ng-container matColumnDef="active">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ 'CONFIG.drip-campaign.list.table-head.active' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.active" class="badge badge-primary">
          {{ translateService.instant('CONFIG.drip-campaign.list.table-data.active.true') }}
        </span>
        <span *ngIf="!element.active" class="badge badge-danger">
          {{ translateService.instant('CONFIG.drip-campaign.list.table-data.active.false') }}
        </span>
      </td>
    </ng-container>
    <!-- Status -->
    <ng-container matColumnDef="statusable_type">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ 'CONFIG.drip-campaign.list.table-head.statusable_type' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.statusable_type === 'status'">
          {{ 'CONFIG.drip-campaign.list.table-data.statusable_type.status' | translate }}
        </span>
        <span *ngIf="element.statusable_type === 'payment_status'">
          {{ 'CONFIG.drip-campaign.list.table-data.statusable_type.payment_status' | translate }}
        </span>
        <span *ngIf="element.statusable_type === 'call_status'">
          {{'CONFIG.drip-campaign.list.table-data.statusable_type.call_status' | translate}}
        </span>
        <span *ngIf="element.statusable_type === 'case_creditor_status'">
          {{ 'CONFIG.drip-campaign.list.table-data.statusable_type.case_creditor_status' | translate }}
        </span>
        <span *ngIf="element.statusable_type === 'case_creditor_payment_status'">
          {{ 'CONFIG.drip-campaign.list.table-data.statusable_type.case_creditor_payment_status' | translate }}
        </span>
        <span *ngIf="element.statusable_type === 'case_creditor_invoice_status'">
          {{ 'CASE_CREDITOR.invoice_status.model_name.singular' | translate }}
        </span>
      </td>
    </ng-container>
    <!-- Status -->
    <ng-container matColumnDef="statusable">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ 'CONFIG.drip-campaign.list.table-head.statusable' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        <span class="badge badge-primary">{{ element.statusable?.name }}</span>
      </td>
    </ng-container>
    <!-- Drip Notifications Count -->
    <ng-container matColumnDef="drip_notifications_count">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ 'CONFIG.drip-campaign.list.table-head.drip_notifications_count' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        <span>{{ element.drip_notifications_count }}</span>
      </td>
    </ng-container>
    <!-- Created At-->
    <ng-container matColumnDef="created_at">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "SHARED.model.created_at" | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.created_at | toDateObject | date }}</td>
    </ng-container>
    <!-- Actions -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{ "SHARED.actions" | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <div class="d-flex">
          <p *ngFor="let action of actions" style="margin: 0; padding-right: 10px">
            <a *ngIf="action === 'Delete'" (click)="openDeleteDialog(element.id, $event)">
              {{ "SHARED.delete" | translate }}
            </a>
          </p>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        routerLink="{{'/drip-campaigns/' + row.id + '/edit'}}"></tr>
  </table>

  <div *ngIf="isLoading" class="text-center" style="padding: 50px">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">{{ "SHARED.loading" | translate }}...</span>
    </div>
  </div>

  <mat-paginator [length]="paginatorConfig.length" [pageSize]="paginatorConfig.pageSize" [pageSizeOptions]="[5, 10, 20]"
                 (page)="paginatorChange($event)" showFirstLastButtons>
  </mat-paginator>
</div>
