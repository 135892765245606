import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';
import { NegotiationReportComponent } from './financial-report-list/negotiation-report/negotiation-report.component';

@Injectable({
  providedIn: 'root',
})
export class ReportService extends MainBaseApiService {

  indexQueuedExports(requestData) {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/reports', {params: requestData})
        .pipe(catchError(response => this.handleError(response)));
  }

  headlineReports() {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/reports/headline')
        .pipe(catchError(response => this.handleError(response)));
  }

  getMissingDocs(data) {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/reports/missing-docs-report', {params: data})
        .pipe(catchError(response => this.handleError(response)));
  }

  generateBreakageReport(requestData) {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/reports/breakage', {params: requestData})
        .pipe(catchError(response => this.handleError(response)));
  }

  generatePaymentReceivedReport(requestData) {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/reports/payment_received_report',
        {params: requestData})
        .pipe(catchError(response => this.handleError(response)));
  }

  getMissingDocsSimpleExport(): Observable<HttpResponse<Blob>> {
    // @ts-ignore
    return this.http.get(this.apiUrl + '/reports/missing-docs-report-export', {observe: 'response', responseType: 'blob'})
      .pipe(catchError(response => this.handleError(response)));
  }

  getMissingDocsExport(): Observable<HttpResponse<Blob>> {
    return this.http.get(this.apiUrl + '/reports/missing-docs-report-export-advanced', {observe: 'response', responseType: 'blob'})
      .pipe(catchError(response => this.handleError(response)));
  }

  generateSalesReport(requestData) {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/reports/sales_report', {params: requestData})
        .pipe(catchError(response => this.handleError(response)));
  }

  generatePackagerSalesReport(requestData): Observable<LaravelResourceResponse<any>> {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/reports/packager-sales-report', {params: requestData})
      .pipe(catchError(response => this.handleError(response)));
  }

  public generateAutomatedCreditorReport(requestData): Observable<LaravelResourceResponse<any>> {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/reports/automated-creditor-report', {params: requestData})
      .pipe(catchError(response => this.handleError(response)));
  }

  public generateGCLIDReport(requestData): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/cases/export-gclid', {params: requestData})
      .pipe(catchError(response => this.handleError(response)));
  }

  public generateNegotiationReport(requestData): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/reports/negotiation-report', {params: requestData})
      .pipe(catchError(response => this.handleError(response)));
  }

  // creditor import
  // upload file
  public uploadCreditorFile(formData): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(this.apiUrl + '/reports/creditor-import', formData)
      .pipe(catchError(response => this.handleError(response)));
  }

  // generateBulkDmReport
  public generateBulkDmReport(requestData): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/reports/bulk-dm-report', {params: requestData})
      .pipe(catchError(response => this.handleError(response)));
  }

  public generateCreditorNegotiationReport(requestData): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/reports/creditor-negotiation-report', {params: requestData})
      .pipe(catchError(response => this.handleError(response)));
  }

  // generate creditor debt report
  public generateCreditorDebtReport(requestData): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/reports/creditor-debt-report', {params: requestData})
      .pipe(catchError(response => this.handleError(response)));
  }

  public generateCreditorsExportReport() {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/reports/creditors_export_report')
        .pipe(catchError(response => this.handleError(response)));
  }

  public generateWalletsExportReport() {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/reports/wallets_export_report')
        .pipe(catchError(response => this.handleError(response)));
  }

  public generateDMReconciliationExportReport(requestData) {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/reports/dm_reconc_export_report', {params: requestData})
        .pipe(catchError(response => this.handleError(response)));
  }

  public generateCreditorDMAceeptedReport(requestData): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/reports/creditor-dm-accepted-report', {params: requestData})
      .pipe(catchError(response => this.handleError(response)));
  }

  public getHistoricalReports(userId): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/reports/historical-reports/' + userId)
      .pipe(catchError(response => this.handleError(response)));
  }

  public getHistoricalUserWithReports() {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/reports/historical-reports')
      .pipe(catchError(response => this.handleError(response)));
  }
}
