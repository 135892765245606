import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormGroup } from '@angular/forms';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { DateTime, Duration } from 'luxon';
import { Subscription, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { Case } from '../../../../../../_base-shared/models/Case/Case';
import { CaseListFilter } from '../../../../../../_base-shared/models/Case/CaseListFilter';
import { User } from '../../../../../../_base-shared/models/User/User';
import { MainBaseApiService } from '../../../_shared/services/main-base-api.service';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';
import { CaseService } from '../case.service';
import { CreditorService } from '../../creditor/creditor.service';
import { StatusPickerTrait } from '../../status/status-picker.trait';
import { Creditor } from '../../../../../../_base-shared/models/Entity/Creditor';
import { CaseCreditorService } from '../case-creditor.service';
import { Status } from 'projects/_base-shared/models/Status/Status';
import { StatusService } from '../../status/status.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { th } from 'date-fns/locale';

@Component({
  templateUrl: './creditor-list.component.html',
  styleUrls:   ['./creditor-list.component.scss']
})
export class CreditorCaseListComponent extends StatusPickerTrait implements OnInit {

  public displayedColumns: string[] = ['select', 'ref_number', 'payment_status', 'case_creditor_status', 'dm_percent_accepted', 'no_of_creditors', 'amount_paid', 'microlenders'];
  public dataSource: MatTableDataSource<Case>;
  public pageSelected = false;
  public initialSelection = [];
  public allowMultiSelect = true;
  public selection    = new SelectionModel(this.allowMultiSelect, this.initialSelection);
  public filtersReady = true;
  public form: FormGroup;
  public dataForm: FormGroup;
  public filterForm: FormGroup;
  public isLoading = 0;
  public creditors: Array<Creditor> = [];
  public creditor: Creditor;
  public caseListFilter: CaseListFilter;
  public filters: any;
  public isLoadingCases = 0;
  public showInvoiceStatus: boolean;
  public totalResults: number;
  public totalPages: number;
  public defaultPaginatorConfig: { pageIndex: number, pageSize: number, length: number };
  public paginatorConfig: { pageIndex: number, pageSize: number, length: number };
  public defaultSort: { direction: 'asc' | 'desc', active: 'created_at' };
  public creditorDataSource: Array<string> = [];
  public caseCreditorStatuses: Array<any>  = [];
  public authUser: User;
  public allStatuses: Status[];
  public statusFormControlName = 'statuses';
  public localStorageName = 'creditor-case-list-filters_v1.0.0';
  private formChangeSubscriber: Subscription;
  public hasYesNoOptions: Array<{ label: string, value: number }>    = [];
  public filterType: 'case' | 'legal' | 'affiliate_case' | 'customer_contact' | 'packager_non_transferred' = null;
  public isLoadingExport = 0;
  public allowedUsers = [751600, 752733];

  casesLoaded: Promise<boolean> = Promise.resolve(false);

  sort: MatSort;

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private globalEventsService: MainGlobalEventService,
    private creditorService: CreditorService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private caseService: CaseService,
    private caseCreditorService: CaseCreditorService,
    private statusService: StatusService,
    private toastr: ToastrService,
  ) {
    super('statuses', true, true);
  }

  ngOnInit(): void {
    this.filterType = this.route.snapshot.data.hasOwnProperty('filterType') ?
                      this.route.snapshot.data.filterType :
                      null;
    this.caseListFilter = this.getFiltersFromStorage();
    this.fetchCreditors();
    this.getCreditorStatusOptions();
    this.fetchStatuses();
    this.buildHasYesNoOptions();
    this.subscriptions.push(this.globalEventsService.authUser$.subscribe(user => {
      this.authUser = user;
    }));
    this.defaultPaginatorConfig = { pageIndex: 0, pageSize: 10, length: 1 };
    this.defaultSort            = { direction: 'desc', active: 'created_at' };
    this.paginatorConfig        = this.defaultPaginatorConfig;
    this.buildForm(this.caseListFilter, this.authUser);
  }

  public sortData(sort) {
    this.caseListFilter.sort_by = sort.active ? sort.active : this.defaultSort.active;
    this.caseListFilter.order   = sort.direction ? sort.direction : this.defaultSort.direction;

    this.fetchCases();
    this.creditorDataSource = [];
  }

  public buildForm(caseListFilter: CaseListFilter, authUser: User): void {
    if (this.formChangeSubscriber) {
      this.formChangeSubscriber.unsubscribe();
    }

    this.form = this.fb.group({
      search:                      [caseListFilter.search],
      creditors:                   [caseListFilter.creditors],
      statuses:                    [caseListFilter.statuses],
      case_creditor_statuses:      [caseListFilter.case_creditor_statuses],
      dm_percent_accepted_greater: [caseListFilter.dm_percent_accepted_greater],
      dm_percent_accepted_less:    [caseListFilter.dm_percent_accepted_less],
      // number_of_creditors:         [caseListFilter.number_of_creditors],
      amount_paid:                 [caseListFilter.amount_paid],
      has_microlenders:            [caseListFilter.has_microlenders],
    });
    this.subscribeToFormChanges();
    this.form.get('creditors').valueChanges.subscribe(creditorId => {
      this.creditor = this.creditors.find(creditor => creditor.id === creditorId);
      if(this.creditor) {
        // fetch cases by creditor
        this.fetchCases();
      }
    });
    this.fetchCases();
  }

  private getFiltersFromStorage(): CaseListFilter {
    const filter                       = new CaseListFilter();
    let data                           = JSON.parse(localStorage.getItem(this.localStorageName));
    data                               = data ? data : {};
    filter.search                      = data.search ? data.search : null;
    filter.creditors                   = data.creditors ? data.creditors : [];
    filter.statuses                    = data.statuses ? data.statuses : [];
    // filter.number_of_creditors         = data.number_of_creditors ? data.number_of_creditors : null;
    filter.dm_percent_accepted_greater = data.dm_percent_accepted_greater ? data.dm_percent_accepted_greater : null;
    filter.dm_percent_accepted_less    = data.dm_percent_accepted_less ? data.dm_percent_accepted_less : null;
    filter.case_creditor_statuses      = data.case_creditor_statuses ? data.case_creditor_statuses : [];
    filter.amount_paid                 = data.amount_paid ? data.amount_paid : null;
    filter.has_microlenders            = data.has_microlenders !== undefined ? data.has_microlenders : 0;

    return filter;
  }


  public togglePageSelect($event) {
    this.pageSelected = $event.checked;
    console.log(this.pageSelected);
    if ( ! this.pageSelected) {
      this.patchSelectAllFilter(0);
    }
    if (this.allRowsSelected()) {
      this.dataSource.data.forEach(clientCase => this.selection.deselect(clientCase));
    } else {
      this.dataSource.data.forEach(clientCase => this.selection.select(clientCase));
    }
    console.log(this.selection);
    console.log(this.dataSource.data);
  }

  public globalSelectAll($event) {
    $event.preventDefault();
    this.patchSelectAllFilter(1);
  }

  public globalClearSelectAll($event) {
    $event.preventDefault();
    this.clearSelection();
  }

  private patchSelectAllFilter(state: boolean | 0 | 1) {
    this.caseListFilter.select_all = state;
  }

  public allRowsSelected() {
    return this.selection.selected.length === this.dataSource.data.length;
  }

  public exportRecordsCustomerContact(caseListFilter: CaseListFilter): void {
    const oldSelectAll        = caseListFilter.select_all;
    caseListFilter.select_all = 1;
    this.exportRecords(caseListFilter);
    this.caseListFilter.select_all = oldSelectAll;
  }

  public exportRecords(caseListFilter: CaseListFilter, clientAllowMarketing = null): void {
    caseListFilter.cases = [];
    this.selection.selected.forEach(selectedCase => caseListFilter.cases.push(selectedCase.id));
    caseListFilter.expected_count = caseListFilter.select_all ? this.totalResults : caseListFilter.cases.length;
    const requestFilter           = MainBaseApiService.convertFiltersForRequest(caseListFilter, 'get');

    if (clientAllowMarketing !== null) {
      requestFilter.client_allow_marketing = clientAllowMarketing;
    }

    let exportObservable: Observable<any>;
    if (this.filterType === 'legal') {
      exportObservable = this.caseService.exportCasesLegal(requestFilter);
    } else if (this.filterType === 'customer_contact') {
      exportObservable = this.caseService.exportCasesCustomerContact(requestFilter);
    } else {
      exportObservable = this.caseService.exportCreditorCases(requestFilter);
    }

    this.isLoadingExport++;
    this.subscriptions.push(
      exportObservable.pipe(finalize(() => this.isLoadingExport--)).subscribe(
        result => {
          this.toastr.success(result.message);
          this.clearSelection();
        },
        err => console.error(err)
      )
    );
    this.caseListFilter.cases = null;
  }

  public sendExtrajudicialClaims(caseListFilter: CaseListFilter): void {

    const relations = ['packager_status', 'case_invoice_status', 'call_status', 'packager', 'affiliate', 'product', 'lead.affiliate',
      'payment_status', 'status', 'latest_case_status_log', 'oldest_case_pending_document', 'creditors', 'doc_status', 'case_status_logs', 'expense', 'distribution'];

    this.filters = {
      is_from_case_creditor: 1,
      amount_paid_greater_then: caseListFilter.amount_paid,
      case_creditor_statuses: this.caseListFilter.case_creditor_statuses,
      all_active: 1,
    };
    // turn creditor id into array
    if (this.caseListFilter.creditors) {
      const creditor = [];
      creditor.push(this.caseListFilter.creditors);
      this.caseListFilter.creditors = creditor;
    }

    caseListFilter.cases = [];
    this.selection.selected.forEach(selectedCase => caseListFilter.cases.push(selectedCase.id));
    caseListFilter.expected_count = caseListFilter.select_all ? this.totalResults : caseListFilter.cases.length;
    
    Object.assign(this.filters, this.caseListFilter);
    const requestData = MainBaseApiService.convertFiltersForRequest(
      { ...this.filters, with: relations }, 'get'
    );
    this.isLoading++;
    this.subscriptions.push(
      this.caseService.sendExtrajudicialClaims(requestData).pipe(finalize(() => this.isLoading--)).subscribe(
        result => {
          this.toastr.success(result.message);
          this.clearSelection();
        },
        err => console.error(err)
      )
    );
  }

  public toggleRow(event: MatCheckboxChange, row) {
    this.selection.toggle(row);
    if ( ! event.checked) {
      this.pageSelected = false;  //  Hide global select
      this.patchSelectAllFilter(0);
    }
  }

  private fetchCreditors(): void {
    this.isLoading++;
    this.subscriptions.push(
      this.creditorService.index({all: 1, active: 1}).pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
          this.creditors = result.data
        }),
    );
  }

  private clearSelection() {
    this.selection.clear();
    this.pageSelected = false;  //  Hide global select
    this.patchSelectAllFilter(0);
  }

  private fetchCases(): void {
    this.clearSelection();  //  Reset global select

    const relations = ['packager_status', 'case_invoice_status', 'call_status', 'packager', 'affiliate', 'product', 'lead.affiliate',
      'payment_status', 'status', 'latest_case_status_log', 'oldest_case_pending_document', 'creditors', 'doc_status', 'case_status_logs', 'expense', 'distribution'];

    this.filters = {
      activity_status: 'active',
      status_date_type: 'sign_up',
      has_microlenders: 0,
      seventy_percent_reduction: 0,
      has_imported_creditors: 0,
      select_all: 0,
      product: 'all',
      has_redsys_has_no_cashflows_token: 0,
      page_type: 'case',
      per_page: this.paginatorConfig.pageSize,
      page: this.paginatorConfig.pageIndex,
      sort_by: this.defaultSort.active,
      order: this.defaultSort.direction,
    };

    this.caseListFilter.cases = [];
    // turn creditor id into array
    if (this.caseListFilter.creditors) {
      const creditor = [];
      creditor.push(this.caseListFilter.creditors);
      this.caseListFilter.creditors = creditor;
    }
    Object.assign(this.filters, this.caseListFilter);

    const requestData = MainBaseApiService.convertFiltersForRequest(
      { ...this.filters, with: relations}, 'get'
    );

    this.dataSource = new MatTableDataSource<Case>([]);
    this.isLoadingCases++;
    this.subscriptions.push(
      this.caseService.index(requestData).pipe(finalize(() => this.isLoadingCases--)).subscribe(
        result => {
          this.clearSelection();
          this.mapLatestContactHistories(result.data, 'history_call');
          this.dataSource             = new MatTableDataSource<Case>(result.data);
          this.dataSource.sort        = this.sort;
          this.paginatorConfig.length = result.meta.total;
          this.totalResults           = result.meta.total;
          this.totalPages             = result.meta.last_page;
          Promise.resolve(true).then(() => this.casesLoaded = Promise.resolve(true));
        },
        err => console.error(err)
      )
    );
  }

  private mapLatestContactHistories(data, keyName) {
    data.map(obj => {
      if (obj.notification_histories?.length) {
        const notificationsReceived = obj.notification_histories.filter(notification => {
          return notification.notifiable_type === keyName;
        });

        if (notificationsReceived.length) {
          if (keyName === 'history_call') {
            const successfulCalls = this.getSuccessfulCalls(notificationsReceived);

            if (successfulCalls.length) {
              const latestSuccessfulCall  = this.getLatestNotification(successfulCalls);
              obj.history_successful_call = this.formatDateForDisplay(latestSuccessfulCall.contacted_at);
              obj.agent                   = latestSuccessfulCall?.staff?.first_name + ' ' +
                latestSuccessfulCall?.staff?.last_name;
            }
          }

          const latestNotification = this.getLatestNotification(notificationsReceived);

          if (keyName === 'history_call' && typeof latestNotification.notifiable !== 'undefined') {
            obj.call_duration = latestNotification.notifiable.call_duration;
          }
          // console.log(latestNotification);
          obj[keyName] = this.formatDateForDisplay(latestNotification.contacted_at);
          return obj;
        }
      }
    });
  }

  private getSuccessfulCalls(data) {
    // data.filter(his => console.log(his));
    return data.filter(
      contactHistory => (typeof contactHistory.notifiable !== 'undefined' && contactHistory.notifiable.success ===
        1));
  }

  private getLatestNotification(data) {
    const mostRecentDate = new Date(
      Math.max(...data.map(contactHistory => {
          return new Date(contactHistory.contacted_at);
        }
      ))
    );

    // Most recent contact
    return data.filter(e => {
      const date = new Date(e.contacted_at);
      return date.getTime() === mostRecentDate.getTime();
    })[0];
  }

  private formatDateForDisplay(date) {
    const diff  = DateTime.local().diff( DateTime.fromISO(date)).as('milliseconds');
    const d     = Duration.fromMillis(diff);
    const days  = `${ Math.floor(d.as('days')) } d`;
    const hours = `${ d.hours } hr${ d.hours > 1 || d.hours === 0 ? 's' : '' }`;

    return days + ', ' + hours;
  }

  public creditorCount(clientCase) {
    let i = 0;
    clientCase.forEach(element => {
      if(element.pivot?.type == 'unsecured') {
        i++;
      }
    });
    return i;
  }

  private getCreditorStatusOptions(): void {
    this.caseCreditorService.getCaseCreditorStatuses({select_all: 1})
      .pipe(finalize(() => this.isLoading--))
      .subscribe(
        res => {
          if(this.authUser.locale === 'en') {
            this.caseCreditorStatuses = res.data.sort((a,b) => a.name_en.localeCompare(b.name_en));
          } else {
            this.caseCreditorStatuses = res.data.sort((a,b) => a.name_es.localeCompare(b.name_es));
          }
      });
  }

  // find case creditor status by id
  public findCaseCreditorStatusById(caseObj: Case) {
    // iterate through case creditors and find the status for the selected creditor
    let status = {name_es: ''};
    caseObj.creditors.forEach(creditor => {
      if(creditor.pivot.creditor_id === this.caseListFilter.creditors?.[0]) {
        if(!creditor.pivot?.case_creditor_status_id) {
          return;
        }
        status = creditor.pivot?.status;
      }
    });
    // find the status object according to the locale of the user
    return status ? status.name_es : '';
  }

  public paginatorChange($event: PageEvent): void {
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize  = $event.pageSize;
    this.paginatorConfig.length    = $event.length;

    this.caseListFilter.page     = this.paginatorConfig.pageIndex + 1;
    this.caseListFilter.per_page = this.paginatorConfig.pageSize;
    this.fetchCases();
    this.creditorDataSource = [];
  }

  private fetchStatuses(): void {
    this.isLoading++;
    this.subscriptions.push(
      this.statusService.indexCategoriesWithStatuses().pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
            this.statusCategories         = result.data;
            this.filteredStatusCategories = result.data;
            this.statusCategories.forEach(category => {
              this.allStatuses.push(category);
              category.statuses.forEach(status => this.allStatuses.push(status));
              if (this.authUser.role_id !== 5) { // hide Solicitud Prestamo status for non-superadmin users
                category.statuses = category.statuses.filter(status => status.id !== 320);
              }
            });
            this.setStatusControls(this.form.get(this.statusFormControlName).value);
          },
        ),
    );
  }

  public searchCases() {
    this.fetchCases();
  }

  private storeFiltersToStorage(filter: CaseListFilter): void {
    return localStorage.setItem(this.localStorageName, JSON.stringify(filter));
  }

  private subscribeToFormChanges() {
    this.formChangeSubscriber = this.form.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
    ).subscribe(res => {
      if (this.form.invalid) {
        return;
      }
      this.caseListFilter = this.form.value;
      // TODO: update filters in query string
      this.storeFiltersToStorage(this.caseListFilter);
      this.fetchCases();
    });
  }

  public clearFilters(): void {
    localStorage.removeItem(this.localStorageName);
    this.caseListFilter = this.getFiltersFromStorage();
    this.buildForm(this.caseListFilter, this.authUser);
    this.statusCategoryControl.patchValue([]);
    this.statusControl.patchValue([]);
  }

  public buildHasYesNoOptions(): void {
    this.hasYesNoOptions = [
      {
        label: this.translate.instant('SHARED.yes'),
        value: 2
      },
      {
        label: this.translate.instant('SHARED.no'),
        value: 1
      }
    ];
  }
}
