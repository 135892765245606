import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { CaseCreditorStatusService } from '../status-list/case-creditor-status.service';

@Component({
    selector: 'app-case-creditor-info',
    templateUrl: './editor-dialog.component.html'
})

export class CaseCreditorStatusEditorComponent implements OnInit {

    public form: UntypedFormGroup;
    public isEdit: boolean = true;

    public allowedUsers = [36, 751600, 752733, 768371];

    constructor(
        public dialogRef: MatDialogRef<CaseCreditorStatusEditorComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private caseCreditorStatusService: CaseCreditorStatusService,
    ) {
    }

    ngOnInit() {
        this.form = new FormGroup({
            name_en: new UntypedFormControl(this.data?.creditor?.name_en || ''),
            name_es: new UntypedFormControl(this.data?.creditor?.name_es || ''),
            is_for_bulk_dm: new UntypedFormControl(this.data?.creditor?.is_for_bulk_dm ? true : false),
            is_system_status: new UntypedFormControl(this.data?.creditor?.is_system_status ? true : false),
            exclude_from_distribution: new UntypedFormControl(this.data?.creditor?.exclude_from_distribution ? true : false),
            slug: new UntypedFormControl(this.data?.creditor?.slug || ''),
        });
        console.log(this.data);
        if(!this.data) {
            this.isEdit = false;
        }

        if(this.isEdit) {
            this.form.get('slug').disable();

            if(this.data?.is_system_status || this.allowedUsers.includes(this.data?.authUser?.id)) {
                this.form.get('is_system_status').disable();
            }
        }


        this.form.get('name_en').valueChanges.subscribe((value) => {
            this.form.get('slug').setValue(this.onKeyDown(value));
        })
    }

    onCloseClick(): void {
        this.dialogRef.close();
    }

    onSubmit(): void {
        const data = this.form.getRawValue();
        if (this.data?.creditor?.id) {
            this.caseCreditorStatusService.update(this.data.creditor?.id, data).subscribe(() => {
                this.dialogRef.close();
            });
        } else {
            this.caseCreditorStatusService.store(data).subscribe(() => {
                this.dialogRef.close();
            });
        }
    }

    onKeyDown(text): void {
        if(this.isEdit && this.data?.creditor?.slug) {
            return this.data.creditor?.slug;
        }
        var str = text.replace(/\s+/g, '_');
        return str.toLowerCase();
    }
}
