<div class="card shadow mb-4" style="height: 100%">
  <div class="card-header">
    <div class="d-flex justify-content-between align-items-center" style="height: 40px">
      <div>
        <h3>{{ "CASES.single.general.client_editor.header" | translate }}</h3>
      </div>
      <div *ngIf="!isEditing" class="col-5 text-right">
        <button mat-icon-button color="primary" (click)="toggleEditMode()" class=" p-0">
          <mat-icon>settings</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="card-body" style="padding-top: 0">
    <app-server-response [response]="serverResponse"></app-server-response>
    <div class="pt-4 pl-4" *ngIf="isLoading">
      <mat-spinner class="m-0 m-auto"></mat-spinner>
    </div>
    <form *ngIf="form" [hidden]="isLoading" [formGroup]="form" (ngSubmit)="submitForm()">
      <div>
        <mat-tab-group mat-align-tabs="start" [selectedIndex]="0" [class.single-case]="!case?.joint_application">
          <mat-tab [label]="'CASES.single.general.client_editor.tabs.client' | translate">
            <div class="disabled-form">
              <div formGroupName="case">
                <!-- Ref Number -->
                <app-input formControlName="ref_number" [label]="'CASES.model.ref_number' | translate"
                           [showLabel]="false" [extraLabel]="true" appearance="standard" [fullWidth]="true"
                           flexClass="align-items-center">
                </app-input>
              </div>
              <div formGroupName="client">
                <!-- First Name -->
                <app-input formControlName="first_name" [label]="'USERS.model.first_name' | translate"
                           [showLabel]="false" [extraLabel]="true" appearance="standard" [fullWidth]="true"
                           flexClass="align-items-center">
                </app-input>
                <!-- Last Name -->
                <app-input formControlName="last_name" [label]="'USERS.model.last_name' | translate"
                           [showLabel]="false" [extraLabel]="true" appearance="standard" [fullWidth]="true"
                           flexClass="align-items-center">
                </app-input>
                <!-- Email -->
                <app-input type="email" formControlName="email" [label]="'USERS.model.email' | translate"
                           [showLabel]="false" [extraLabel]="true" appearance="standard" [fullWidth]="true"
                           flexClass="align-items-center">
                </app-input>
                <!-- Phone -->
                <div>
                  <app-input type="number" formControlName="phone" [label]="'USERS.model.phone' | translate"
                             [showLabel]="false" [extraLabel]="true" appearance="standard" [fullWidth]="false"
                             flexClass="align-items-center">
                  </app-input>
                  <div class="d-flex flex-row justify-content-center align-items-center"
                       *ngIf="!isEditing && case.client.phone !== null" class="blue-text"
                       style="position: absolute;right: 0px;top: 192px;cursor: pointer">
                    <mat-icon aria-hidden="false" class="mr-3" (click)="clickToCall('phone', 'client')">call
                    </mat-icon>
                  </div>
                </div>
                <!--Home phone-->
                <div>
                  <app-input type="number" formControlName="home_phone" [label]="'USERS.model.home_phone' | translate"
                             [showLabel]="false" [extraLabel]="true" appearance="standard" [fullWidth]="false"
                             flexClass="align-items-center">
                  </app-input>
                  <div class="d-flex flex-row justify-content-center align-items-center"
                       *ngIf="!isEditing && case.client.home_phone !== null" class="blue-text"
                       style="position: absolute;right: 0px;top: 231px;cursor: pointer">
                    <mat-icon aria-hidden="false" class="mr-3" (click)="clickToCall('home_phone', 'client')">call
                    </mat-icon>
                  </div>
                </div>
                <!--Work phone-->
                <div>
                  <app-input type="number" formControlName="work_phone" [label]="'USERS.model.work_phone' | translate"
                             [showLabel]="false" [extraLabel]="true" appearance="standard" [fullWidth]="false"
                             flexClass="align-items-center">
                  </app-input>
                  <div class="d-flex flex-row justify-content-center align-items-center"
                       *ngIf="!isEditing && case.client.work_phone !== null" class="blue-text"
                       style="position: absolute;right: 0px;top: 268px;cursor: pointer">
                    <mat-icon aria-hidden="false" class="mr-3" (click)="clickToCall('work_phone', 'client')">call
                    </mat-icon>
                  </div>
                </div>
                <!-- ID Card -->
                <app-input formControlName="id_card" [label]="'USERS.model.id_card' | translate"
                           [showLabel]="false" [extraLabel]="true" appearance="standard" [fullWidth]="true"
                           flexClass="align-items-center">
                </app-input>
              </div>
              <div formGroupName="client">
                <!-- Address -->
                <div formGroupName="address">
                  <!-- Address 1 -->
                  <app-input formControlName="address_1" [label]="'ADDRESS.address_1' | translate"
                             [showLabel]="false" [extraLabel]="true" appearance="standard" [fullWidth]="true"
                             flexClass="align-items-center">
                  </app-input>
                  <!-- Address 2 -->
                  <app-input formControlName="address_2" [label]="'ADDRESS.address_2' | translate"
                             [showLabel]="false" [extraLabel]="true" appearance="standard" [fullWidth]="true"
                             flexClass="align-items-center">
                  </app-input>
                </div>
              </div>
              <div formGroupName="case">
                <!-- Affiliate -->
                <div formGroupName="lead" class="row align-items-center">
                  <app-dropdown *ngIf="form" [showLabel]="false" [showExtraLabel]="true" class="col"
                                appearance="standard" [data]="affiliates" flexClass="align-items-baseline"
                                [label]="'CASES.single.general.client_editor.affiliate' | translate"
                                [parentFormGroup]="form.get('case.lead')"
                                [parentFormControlName]="'affiliate_id'" [matOptionValue]="'id'">
                  </app-dropdown>
                </div>
              </div>
              <!-- Vulnerability -->
              <div formGroupName="client" class="row align-items-center">
                <app-dropdown *ngIf="form" [showLabel]="false" [showExtraLabel]="true" class="col"
                              appearance="standard" [data]="vulnerabilities" flexClass="align-items-baseline"
                              [label]="'USERS.model.vulnerability.label' | translate"
                              [parentFormControl]="form.get('client.vulnerability')"
                              [matOptionValue]="'value'" [matOptionLabel]="'label'">
                </app-dropdown>
              </div>
            </div>
            <button mat-button color="primary" mat-raised-button class="mt-2" type="button" 
              (click)="openUserPasswordModal(case.client.id)">
              {{ 'USERS.editor.change-user-password' | translate }}
            </button>
          </mat-tab>
          <mat-tab [label]="'CASES.single.general.client_editor.tabs.partner' | translate"
                   *ngIf="this.case.joint_application">
            <div class="disabled-form" formGroupName="partner">
              <!-- Partner First Name -->
              <app-input formControlName="first_name" [label]="'USERS.model.first_name' | translate"
                         [showLabel]="false" [extraLabel]="true" appearance="standard" [fullWidth]="true"
                         flexClass="align-items-center">
              </app-input>
              <!-- Partner Last Name -->
              <app-input formControlName="last_name" [label]="'USERS.model.last_name' | translate"
                         [showLabel]="false" [extraLabel]="true" appearance="standard" [fullWidth]="true"
                         flexClass="align-items-center">
              </app-input>
              <!-- Partner Email -->
              <app-input type="email" formControlName="email" [label]="'USERS.model.email' | translate"
                         [showLabel]="false" [extraLabel]="true" appearance="standard" [fullWidth]="true"
                         flexClass="align-items-center">
              </app-input>
              <!-- Partner Phone -->
              <div>
                <app-input type="number" formControlName="phone" [label]="'USERS.model.phone' | translate"
                           [showLabel]="false" [extraLabel]="true" appearance="standard" [fullWidth]="false"
                           flexClass="align-items-center">
                </app-input>
                <div class="d-flex flex-row justify-content-center align-items-center"
                     *ngIf="!isEditing && case.partner.phone !== null" class="blue-text"
                     style="position: absolute;right: 0px;top: 151px;cursor: pointer">
                  <mat-icon aria-hidden="false" class="mr-3" (click)="clickToCall('phone', 'partner')">call
                  </mat-icon>
                </div>
              </div>
              <!--Partner Home phone-->
              <div>
                <app-input type="number" formControlName="home_phone" [label]="'USERS.model.home_phone' | translate"
                           [showLabel]="false" [extraLabel]="true" appearance="standard" [fullWidth]="false"
                           flexClass="align-items-center">
                </app-input>
                <div class="d-flex flex-row justify-content-center align-items-center"
                     *ngIf="!isEditing && case.partner.home_phone !== null" class="blue-text"
                     style="position: absolute;right: 0px;top: 186px;cursor: pointer">
                  <mat-icon aria-hidden="false" class="mr-3" (click)="clickToCall('home_phone', 'partner')">call
                  </mat-icon>
                </div>
              </div>
              <!--Partner Work phone-->
              <div>
                <app-input type="number" formControlName="work_phone" [label]="'USERS.model.work_phone' | translate"
                           [showLabel]="false" [extraLabel]="true" appearance="standard" [fullWidth]="false"
                           flexClass="align-items-center">
                </app-input>
                <div class="d-flex flex-row justify-content-center align-items-center"
                     *ngIf="!isEditing && case.partner.work_phone !== null" class="blue-text"
                     style="position: absolute;right: 0px;top: 221px;cursor: pointer">
                  <mat-icon aria-hidden="false" class="mr-3" (click)="clickToCall('work_phone', 'partner')">call
                  </mat-icon>
                </div>
              </div>
              <!-- Partner ID Card -->
              <app-input formControlName="id_card" [label]="'USERS.model.id_card' | translate"
                         [showLabel]="false" [extraLabel]="true" appearance="standard" [fullWidth]="true"
                         flexClass="align-items-center">
              </app-input>
              <!-- Partner Address -->
              <div formGroupName="address">
                <!-- Partner Address 1 -->
                <app-input formControlName="address_1" [label]="'ADDRESS.address_1' | translate"
                           [showLabel]="false" [extraLabel]="true" appearance="standard" [fullWidth]="true"
                           flexClass="align-items-center">
                </app-input>
                <!-- Partner Address 2 -->
                <app-input formControlName="address_2" [label]="'ADDRESS.address_2' | translate"
                           [showLabel]="false" [extraLabel]="true" appearance="standard" [fullWidth]="true"
                           flexClass="align-items-center">
                </app-input>
              </div>
            </div>
            <button mat-button color="primary" mat-raised-button class="mt-2" type="button" 
              [disabled]="!isElementRestricted()" (click)="openUserPasswordModal(case.partner.id)">
              {{ 'USERS.editor.change-user-password' | translate }}
            </button>
          </mat-tab>
          <mat-tab *ngIf="this.case?.linked_by_cases?.length || this.case?.linked_cases?.length"
                   [label]="'CASES.single.general.client_editor.tabs.linked_cases' | translate">
            <div class="linked-cases-container" style="max-height: 400px; overflow: hidden scroll">
              <h3 *ngIf="this.case?.linked_by_cases?.length">
                {{ 'CASES.single.general.client_editor.linked_by_cases' | translate }}
              </h3>
              <div *ngFor="let linkedByCase of this.case.linked_by_cases" class="mt-3">
                <!-- Ref Number -->
                <div class="row mt-2">
                  <div class="col">{{ 'CASES.model.ref_number' | translate }}</div>
                  <div class="col">
                    <a [routerLink]="['/cases', linkedByCase.id, 'general']">{{ linkedByCase.ref_number }}</a>
                  </div>
                </div>
                <!-- First Name -->
                <div class="row mt-2">
                  <div class="col">{{ 'USERS.model.first_name' | translate }}</div>
                  <div class="col">{{ linkedByCase.client.first_name }}</div>
                </div>
                <!--  Last Name  -->
                <div class="row mt-2">
                  <div class="col">{{ 'USERS.model.last_name' | translate }}</div>
                  <div class="col">{{ linkedByCase.client.last_name }}</div>
                </div>
                <!-- Email -->
                <div class="row mt-2">
                  <div class="col">{{ 'USERS.model.email' | translate }}</div>
                  <div class="col">{{ linkedByCase.client.email }}</div>
                </div>
                <!-- Phone  -->
                <div class="row mt-2">
                  <div class="col">{{ 'USERS.model.phone' | translate }}</div>
                  <div class="col">{{ linkedByCase.client.phone }}</div>
                </div>
                <!-- ID Card -->
                <div class="row mt-2">
                  <div class="col">{{ 'USERS.model.id_card' | translate }}</div>
                  <div class="col">{{ linkedByCase.client.id_card }}</div>
                </div>
                <!-- Address 1 -->
                <div class="row mt-2">
                  <div class="col">{{ 'ADDRESS.address_1' | translate }}</div>
                  <div class="col">{{ linkedByCase.client.address?.address_1 }}</div>
                </div>
                <!-- Address 2 -->
                <div class="row mt-2">
                  <div class="col">{{ 'ADDRESS.address_2' | translate }}</div>
                  <div class="col">{{ linkedByCase.client.address?.address_2 }}</div>
                </div>
              </div>
              <h3 *ngIf="this.case?.linked_cases?.length">
                {{ 'CASES.single.general.client_editor.linked_cases' | translate }}
              </h3>
              <div *ngFor="let linkedCase of this.case.linked_cases" class="mt-3">
                <!-- Ref Number -->
                <div class="row mt-2">
                  <div class="col">{{ 'CASES.model.ref_number' | translate }}</div>
                  <div class="col">
                    <a [routerLink]="['/cases', linkedCase.id, 'general']">{{ linkedCase.ref_number }}</a>
                  </div>
                </div>
                <!-- First Name -->
                <div class="row mt-2">
                  <div class="col">{{ 'USERS.model.first_name' | translate }}</div>
                  <div class="col">{{ linkedCase.client.first_name }}</div>
                </div>
                <!--  Last Name  -->
                <div class="row mt-2">
                  <div class="col">{{ 'USERS.model.last_name' | translate }}</div>
                  <div class="col">{{ linkedCase.client.last_name }}</div>
                </div>
                <!-- Email -->
                <div class="row mt-2">
                  <div class="col">{{ 'USERS.model.email' | translate }}</div>
                  <div class="col">{{ linkedCase.client.email }}</div>
                </div>
                <!-- Phone  -->
                <div class="row mt-2">
                  <div class="col">{{ 'USERS.model.phone' | translate }}</div>
                  <div class="col">{{ linkedCase.client.phone }}</div>
                </div>
                <!-- ID Card -->
                <div class="row mt-2">
                  <div class="col">{{ 'USERS.model.id_card' | translate }}</div>
                  <div class="col">{{ linkedCase.client.id_card }}</div>
                </div>
                <!-- Address 1 -->
                <div class="row mt-2">
                  <div class="col">{{ 'ADDRESS.address_1' | translate }}</div>
                  <div class="col">{{ linkedCase.client.address?.address_1 }}</div>
                </div>
                <!-- Address 2 -->
                <div class="row mt-2">
                  <div class="col">{{ 'ADDRESS.address_2' | translate }}</div>
                  <div class="col">{{ linkedCase.client.address?.address_2 }}</div>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
      <div *ngIf="isEditing" class="row pt-3">
        <div class="col-12 text-right">
          <button mat-button color="primary" class="mr-3" (click)="toggleEditMode()">
            {{ 'SHARED.cancel' | translate }}
          </button>
          <app-spinner-btn type="submit" [disabled]="!isElementRestricted()" [loading]="isSubmitting" color="primary">
            {{ 'SHARED.save' | translate }}
          </app-spinner-btn>
        </div>
      </div>
    </form>
  </div>
</div>
