import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { AppSelectOption } from '../../../../../../_base-shared/contracts/common.interface';
import { LaravelResourceResponse } from '../../../../../../_base-shared/contracts/laravel-response.interface';
import { DistributionBatch } from '../../../../../../_base-shared/models/Distribution/DistributionBatch';
import { DistributionProvider } from '../../../../../../_base-shared/models/Distribution/DistributionProvider';
import { User } from '../../../../../../_base-shared/models/User/User';
import {
  DistributionAmountCalculatorService
} from '../distribution-amount-calculator.service';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';
import { CaseService } from '../../case/case.service';
import { DistributionBatchService } from '../distribution-batch.service';
import { DistributionProviderService } from '../distribution-provider.service';
import { DistributionService } from '../distribution.service';
import { CaseListFilter } from '../../../../../../_base-shared/models/Case/CaseListFilter';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import Swal from 'sweetalert2';
import { MainBaseApiService } from '../../../_shared/services/main-base-api.service';
import { CaseDistribution } from 'projects/_base-shared/models/Distribution/CaseDistribution';
import { ModelInfoComponent } from './distribution-model-dialog/distribution-model-info.component';
import { DistributionModelFiltersComponent } from './distribution-model-filters/distribution-model-filters.component'
// change to distributionListFilter?
import { PaymentListFilter } from '../../../../../../_base-shared/models/Payment/PaymentListFilter';

@Component({
  selector:    'app-distribution-batch-model',
  templateUrl: './distribution-batch-model.component.html',
  styleUrls:   ['./distribution-batch-model.component.scss']
  /*styles:      [
    `
      mat-table {
        display: table;
        width: 100%;
      }

      mat-progress-bar {
        height: 16px;
      }
    `,
  ],*/
})
export class DistributionBatchModelComponent extends DistributionAmountCalculatorService implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  // Wrappper
  @ViewChild(DistributionModelFiltersComponent) DistributionModelFiltersComponent: DistributionModelFiltersComponent;

  public authUser: User;
  public componentType: 'distribution' | 'admin';
  public filtersReady = false;
  public requestFilters: any;
  public form: UntypedFormGroup;
  public batches: MatTableDataSource<DistributionBatch> = new MatTableDataSource<DistributionBatch>([]);
  public distributionProviders: Array<DistributionProvider> = [];
  public isLoading = 0;
  public serverResponse: LaravelResourceResponse;
  public displayedColumns: Array<string>;
  public selection = new SelectionModel(true, []);
  public defaultPaginatorConfig: { pageIndex: number, pageSize: number, length: number };
  public paginatorConfig: { pageIndex: number, pageSize: number, length: number };
  public defaultSort: { direction: 'asc' | 'desc', active: 'created_at' };
  public totalResults: number;
  public totalPages: number;
  public pageSelected: boolean;
  public isAssigningBatch: boolean;

  //delet once test are done
  public securedVerifyDisabled: boolean = false;
  public securedResendDisabled: boolean = false;
  public securedRecoveryDisabled: boolean = false;
  public securedProposalAccordDisabled: boolean = false;
  public selectionSecured: boolean = false;
  //public securedResendDisabled: boolean = false;

  // Filters
  public batchStatusOptions: Array<AppSelectOption> = [];
  public caseListFilter: CaseListFilter; ////? maybe replace

  private subscriptions: Array<Subscription> = [];

  //public totalCollectedAmt: number = 0;
  public totalDistributedAmt: number = 0;
  public totalOnHold: number = 0;
  public totalDisbursed: number = 0;
  public activeClients: number = 0;
  //public distributionBillableAmount: number = 0;
  //public distributionAmountBilled: number = 0;

  public paymentListFilter: PaymentListFilter;
  
  constructor(private route: ActivatedRoute,
              private fb: UntypedFormBuilder,
              private dialog: MatDialog,
              private translate: TranslateService,
              private toastr: ToastrService,
              private globalEventService: MainGlobalEventService,
              private caseService: CaseService,
              private distributionService: DistributionService,
              private distributionBatchService: DistributionBatchService,
              private distributionProviderService: DistributionProviderService) {
    super();
  }

  ngOnInit(): void {
    this.defaultPaginatorConfig = { pageIndex: 0, pageSize: 20, length: 1 };
    this.defaultSort = { direction: 'desc', active: 'created_at' };
    this.paginatorConfig = this.defaultPaginatorConfig;
    this.requestFilters = this.getDefaultFilters();
    this.buildFilterOptions();
    this.fetchDistributionProviders();
    //this.buildForm();

    this.globalEventService.authUser$.subscribe(user => {
      if (user) {
        this.authUser = user;
        this.componentType = this.authUser.role.slug === 'distribution-provider' ? 'distribution' : 'admin';
        this.displayedColumns = this.getTableColumns(this.componentType);
        this.fetchBatches();
      }
    });
  }

  ngAfterViewInit(): void {
    this.batches.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  private resetPagination(): void {
    this.paginatorConfig = this.defaultPaginatorConfig;
    this.requestFilters.per_page = this.paginatorConfig.pageSize;
    this.requestFilters.page = this.paginatorConfig.pageIndex;
  }

  private resetSort(): void {
    this.requestFilters.sort_by = this.defaultSort.active;
    this.requestFilters.order = this.defaultSort.direction;
  }

  private clearSelection() {
    this.selection.clear();
    //delete this.requestFilters.cases;
    this.pageSelected = false;
  }

   private fetchBatches(): void {
    const phaseTwoStatusIds = [414, 415];
  
    this.clearSelection();
    this.batches = new MatTableDataSource<any>([]);
    this.isLoading++;
  
    const fetchSubscriptions = this.distributionBatchService
      .index(this.requestFilters, [
        //'distribution_provider',
        //'case_distributions',
        //'case_distributions.case',
        'case_distributions.case.unsecured_creditors',
        'case_distributions.case.creditors',
        //'case_distributions.case.debt_payment_plan',
        //'case_distributions.case.terms',
        //'case_distributions.case.expense',
        //'case_distributions.case.distribution_case_terms'
      ])
      .pipe(finalize(() => this.isLoading--))
      .subscribe(
        result => {
          this.clearSelection();
          const dataWithTerms = result.data.map(batch => this.processBatch(batch));
          this.updateBatchData(dataWithTerms, result.meta);
        },
        err => console.error(err)
      );
  
    this.subscriptions.push(fetchSubscriptions);

    this.distributionBatchService.getTotalModelDistributionAmounts(this.requestFilters)
    .subscribe(
      result => {
        this.totalDistributedAmt = Math.max(0, result.data.total_distributed);
        this.totalOnHold = Math.max(0, result.data.total_on_hold);
        this.totalDisbursed = Math.max(0, result.data.total_disbursed);
        this.activeClients = Math.max(0, result.data.total_cases);
      },
      error => console.log(error)
    );

    /*this.distributionBatchService.getTotalCollectedAmount(this.requestFilters).subscribe(
      result => this.totalCollectedAmt = result.data.total_amount_paid,
      error => console.log(error)
    );*/

    /*this.distributionBatchService.getTotalDistributedAmount(this.requestFilters).subscribe(
      result => this.totalDistributedAmt = result.data.total_distributed_amount,
      error => console.log(error)
    );*/

    /*this.distributionBatchService.getTotalOnHold(this.requestFilters).subscribe(
      result => this.totalOnHold = result.data.total_on_hold,
      error => console.log(error)
    );*/
    
  }
  
  private processBatch(batch: any): any {
    let c_amount_collected = 0;
    let c_default_rate = 0;
    let c_distributed = 0;
    let case_offer_to_creditor = 0;
    let c_on_hold = 0;
    let c_disbursed = 0;
  
    const { terms, uniqueCaseIdCount } = this.calculateTermsAndUniqueCases(batch);
    const processedCaseIds = new Set();
  
    batch.case_distributions.forEach(caseDistribution => {
      if (!processedCaseIds.has(caseDistribution.case.id)) {
        processedCaseIds.add(caseDistribution.case.id);
  
        const caseOfferData = this.calculateCaseOfferData(caseDistribution, batch);
        case_offer_to_creditor += caseOfferData.case_offer_to_creditor;
  
        const termData = this.calculateTermData(caseDistribution, batch);
        c_default_rate = termData.default_rate;
        c_distributed += termData.amount_distributed;
        //c_amount_collected += caseOfferData.case_offer_to_creditor * termData.number_of_payments;
        c_amount_collected += termData.amount_to_distribute;
        c_on_hold += termData.amount_holded;
        c_disbursed += termData.amount_disbursed;
      }
    });
  
    return {
      ...batch,
      terms: terms / uniqueCaseIdCount,
      c_amount_collected,
      c_default_rate,
      uniqueCaseIdCount,
      c_distributed,
      c_on_hold,
      c_disbursed
    };
  }
  
  private calculateTermsAndUniqueCases(batch: any): { terms: number; uniqueCaseIdCount: number } {
    const result = batch.case_distributions.reduce((acc, obj) => {
      const caseId = obj.case.id;
      if (!acc.uniqueCaseIds.has(caseId)) {
        acc.uniqueCaseIds.add(caseId);
        acc.count++;
        const unsecuredCreditor = obj.case.unsecured_creditors.find(item => item.name === batch.name);
        if (unsecuredCreditor) {
          acc.terms += unsecuredCreditor.pivot.terms;
        }
      }
      return acc;
    }, { count: 0, uniqueCaseIds: new Set(), terms: 0 });
  
    return { terms: result.terms, uniqueCaseIdCount: result.count };
  }
  
  private calculateCaseOfferData(caseDistribution: any, batch: any): { case_offer_to_creditor: number } {
    const case_creditors = batch.name === 'UNIFYE'
      ? caseDistribution.case.creditors.filter(item => item.name === batch.name)
      : caseDistribution.case.unsecured_creditors.filter(item => item.name === batch.name);
  
    const totalOfferToCreditor = case_creditors.reduce((sum, creditor) => sum + creditor.pivot.offer_to_creditor, 0);
  
    return {
      case_offer_to_creditor: totalOfferToCreditor || 0
    };
  }
  
  private calculateTermData(caseDistribution: any, batch: any): { number_of_payments: number; amount_distributed: number; amount_to_distribute: number; amount_holded: number; default_rate: number, amount_disbursed: number } {
    let number_of_payments = 0;
    let total_amount_paid = 0;
    let total_amount_requested = 0;
    let amount_distributed = 0;
    let amount_to_distribute = 0;
    let amount_holded = 0;
    let amount_disbursed = 0;
  
    caseDistribution.case.distribution_case_terms.forEach(term => {
      if (this.isValidPhaseTwoTerm(term, batch.id)) {
        number_of_payments++;
        if (this.isTermWithin18Months(term)) {
          total_amount_paid += term.amount_paid;
          total_amount_requested += term.amount;
          if (term.amount_paid == term.amount){
            amount_to_distribute += term.amount_to_distribute;
          }
        }
        if (term.hold_distribution && (term.amount_paid == term.amount)){
          amount_holded += term.amount_to_distribute;
        }
        if(term.disbursed){
          amount_disbursed += term.amount_to_distribute;
        }
      }
  
      if (this.isDistributedPhaseTwoTerm(term, batch.id)) {
        amount_distributed += term.amount_distributed;
      }
      
    });
  
    const default_rate = total_amount_requested > 0
      ? (total_amount_requested - total_amount_paid) / total_amount_requested
      : 0;
  
    return {
      number_of_payments,
      amount_distributed,
      default_rate,
      amount_to_distribute,
      amount_holded,
      amount_disbursed
    };
  }
  
  private isValidPhaseTwoTerm(term: any, batchId: number): boolean {
    return term.type === "phase_two" && term.amount_paid > 0 && term.amount_paid === term.amount && term.distribution_batch_id === batchId;
  }
  
  private isDistributedPhaseTwoTerm(term: any, batchId: number): boolean {
    return term.type === "phase_two" && term.amount_distributed > 0 && term.distribution_batch_id === batchId;
  }
  
  private isTermWithin18Months(term: any): boolean {
    const today = new Date();
    today.setMonth(today.getMonth() + 18);
    return new Date(term.term_date) <= today;
  }
  
  private updateBatchData(dataWithTerms: any[], meta: any): void {
    this.batches = new MatTableDataSource<any>(dataWithTerms);
    //this.batches.sort = this.sort;
    this.paginatorConfig.length = meta.total;
    this.totalResults = meta.total;
    this.totalPages = meta.last_page;
  }

  //end-refactor

  private getTableColumns(type: 'distribution' | 'admin'): Array<string> {
    return [
      'select',
      'name',
      //'cases_count',
      //'status_updated_at',
      //'total_batch_contract_amount', //Original balance
      //'total_monthly_fee_amount', //Monthly Installment
      'amount_collected', //Amount collected, pivot.debt_amount - pivot.current_balance
      //'total_distributed_amount', //Balance outstanding
      'amount_distributed',
      'current_distribution', //Pending distribution
      'on-account',
      'disbursed',
      //'total_cash_hurdle_amount', //Default rate
      //'total_fees_retained', //Fees retained
      //'average_term', //Average term
      //'progress_bar',
      'actions',
    ];
  }

  public getPercent(paid, amount) {
    if (( paid / amount ) * 100) {
      return ( ( paid / amount ) * 100 ).toFixed(2);
    } else {
      return 0;
    }
  }

  private getDefaultFilters() {
    return {
      select_all:                0,
      statuses:                  null,
      distribution_provider_ids: null,
      date_filter: "sched",
      sort_by: "name",
      order: "asc",
    };
  }

  public paginatorChange($event: PageEvent): void {
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize = $event.pageSize;
    this.paginatorConfig.length = $event.length;

    this.requestFilters.page = this.paginatorConfig.pageIndex + 1;
    this.requestFilters.per_page = this.paginatorConfig.pageSize;
    this.fetchBatches();
  }

  public sortData(sort) {
    this.requestFilters.sort_by = sort.active ? sort.active : this.defaultSort.active;
    this.requestFilters.order = sort.direction ? sort.direction : this.defaultSort.direction;

    this.fetchBatches();
  }

  // On filter change
  public handleFilters($event: any): void {
    // this.requestFilters = $event;
    this.resetPagination();
    this.resetSort();
    this.fetchBatches();
  }

  private buildForm() {
    this.form = this.fb.group({
      statuses:                  [null],
      distribution_provider_ids: [null],
    });
    this.subscribeToFilterChanges();
  }

  private buildFilterOptions() {
    this.batchStatusOptions = [
      { value: 'pending', label: 'Pending' },
      { value: 'submitted', label: 'Submitted' },
      { value: 'funded', label: 'Funded' },
      { value: 'rejected', label: 'Rejected' },
    ];
  }

  private fetchDistributionProviders() {
    this.isLoading++;
    this.distributionProviderService.index({ select_all: 1 }).pipe(finalize(() => this.isLoading--))
      .subscribe(result => this.distributionProviders = result.data,
        () => this.toastr.error(this.translate.instant('SHARED.went-wrong')),
      );
  }

  private subscribeToFilterChanges() {
    this.subscriptions.push(
      this.form.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
      ).subscribe(formValue => {
        this.requestFilters = { ...this.requestFilters, ...formValue };
        this.handleFilters(this.requestFilters);
      }),
    );
  }

  public changeComponentType(type: 'distribution' | 'admin') {
    this.componentType = type;
    this.displayedColumns = this.getTableColumns(type);
  }

  // Select All
  public toggleRow(event: MatCheckboxChange, batches: DistributionBatch) {
    this.selection.toggle(batches);
    if (!event.checked) {
      this.pageSelected = false;
      this.patchSelectAllFilter(0);
    }
  }

  public allRowsSelected() {
    return this.selection.selected.length === this.batches.data.length;
  }

  public togglePageSelect($event) {
    this.pageSelected = $event.checked;
    if (!this.pageSelected) {
      this.patchSelectAllFilter(0);
    }
    if (this.allRowsSelected()) {
      this.batches.data.forEach(wallet => this.selection.deselect(wallet));
    } else {
      this.batches.data.forEach(wallet => this.selection.select(wallet));
    }
  }

  public globalSelectAll($event) {
    $event.preventDefault();
    this.patchSelectAllFilter(1);
  }

  public globalClearSelectAll($event) {
    $event.preventDefault();
    this.clearSelection();
  }

  // End Select All

  private patchSelectAllFilter(state: boolean | 0 | 1) {
    if (this.caseListFilter) {
      // this.caseListFiltersComponent.patchFilter('select_all', state, {emitEvent: false, onlySelf: true});
      this.caseListFilter.select_all = state;
    } else {
      console.log('Filter component not ready for form pControl patching');
    }
  }

  public confirmDistributeWallet() {
    const walletFilter = this.selection.selected.map(selectedCase => selectedCase.name);

    let selectedWalletsText;
    if (this.pageSelected) {
      selectedWalletsText = '- all wallets in current page';
    } else if (walletFilter.length === 1) {
      selectedWalletsText = walletFilter[0];
    } else if (walletFilter.length === 2) {
      selectedWalletsText = `${walletFilter[0]} and ${walletFilter[1]}`;
    } else {
      const lastWallet = walletFilter.pop();
      selectedWalletsText = `${walletFilter.join(', ')}, and ${lastWallet}`;
    }

    let termIds: number[] = [];
    const distributionTermsHtml = this.batches.data
    .filter(wallet => walletFilter.includes(wallet.name))
    .map(wallet => {
      const termsHtml = wallet.case_distributions
        .map(caseDist => {
          if (caseDist.case && caseDist.case.distribution_case_terms) {
            const termsList = caseDist.case.distribution_case_terms
              .map(term => {
                if (
                    term.amount_distributed == 0 && 
                    !term.hold_distribution &&
                    term.amount == term.amount_paid &&
                    term.distribution_batch_id == caseDist.distribution_batch_id
                  ){
                  termIds.push(term.id);
                  return `
                    <tr>
                      <td>${caseDist.case.ref_number}</td>
                      <td>${wallet.name}</td>
                      <td>${term.name}</td>
                      <td>${term.amount_to_distribute}</td>
                    </tr>
                  `;
                  }
                  return '';
              })
              .join('');
            
            return termsList;
          }
          return '';
        })
        .join('');
      
      //return `<h3>${wallet.name}</h3>${termsHtml}`;
      return `${termsHtml}`;
    })
    .join('');

    const noTermsMessage = termIds.length === 0 
        ? '<p style="color: red; text-align: center;">No terms to distribute.</p>' 
        : '';

        const fullDistributionTermsHtml = `
        <h2 class="terms-header">Terms that will be distributed:</h2>
        <table class="terms-table">
            <thead>
                <tr>
                    <th>Case Ref</th>
                    <th>Wallet Name</th>
                    <th>Term Name</th>
                    <th>Amount to Distribute</th>
                </tr>
            </thead>
            <tbody>
                ${distributionTermsHtml || noTermsMessage}
            </tbody>
        </table>
      `;

      Swal.fire({
        title: this.translate.instant('SHARED.warning'),
        html: `
              <style>
                  .swal2-popup {
                      width: 800px;
                      max-width: 90%;
                  }
                  .terms-container {
                      max-height: 400px;
                      overflow-y: auto;
                      border: 1px solid #ddd;
                      border-radius: 8px;
                      padding: 15px;
                      background-color: #fff4e1;
                  }
                  .terms-header {
                      color: blue;
                      text-align: center;
                      font-size: 1.2em;
                      margin: 0 0 10px;
                  }
                  .terms-table {
                      width: 100%;
                      font-size: 0.8em;
                      border-collapse: collapse;
                      text-align: left;
                  }
                  .terms-table th, .terms-table td {
                      padding: 8px;
                      border: 1px solid #ddd;
                  }
                  .terms-table th {
                      background-color: #f2f2f2;
                  }
                  @media (max-width: 768px) {
                      .terms-container {
                          max-height: 300px;
                      }
                  }
              </style>
              <p style="font-size: 0.9em;">Are you sure that you want to distribute the wallets: ${selectedWalletsText}?</p>
              <div class="terms-container">
                  ${fullDistributionTermsHtml}
              </div>
          `,
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: this.translate.instant('SHARED.no'),
          confirmButtonText: this.translate.instant('SHARED.yes'),
          confirmButtonColor: '#886ab5',
          preConfirm: () => {
              return termIds.length === 0 ? Swal.showValidationMessage('No terms to distribute.') : null;
          }
      }).then(res => {
          if (res.isConfirmed && termIds.length > 0) {
              this.distributeWallet(termIds);
          } else {
              this.clearSelection();
          }
      });
  }

  public distributeWallet(termIds: number[]) { //caseListFilter: CaseListFilter
  
    // wallet based, not term based
    //let wallet_filter = [];
    //this.selection.selected.forEach(selectedCase => wallet_filter.push(selectedCase.id));

    //if (this.pageSelected) {
    //  wallet_filter = [0];
    //}
    
    this.isAssigningBatch = true;
    this.subscriptions.push(
      this.distributionService.distributeWallet({
          filters: termIds 
          //filters: {
          //    wallets: wallet_filter,
          //    termIds: termIds
          //}
        })
        .pipe(finalize(() => this.isAssigningBatch = false))
        .subscribe(
          result => {

            if (result.message === 'non-existant-wallet'){
              this.toastr.error(
                'Wallet: ' + result.data + ' not available'
              );
            }else{
            
              this.toastr.success(
                this.translate.instant('DISTRIBUTION.cases.list.actions.assign_batch.result.success'));
              this.fetchBatches();
              //if (caseListFilter.select_all) {
                //this.resetTable();
                //this.fetchCases();
              //  return;
              //}
              /*const localCases = this.batches.data;
              result.data.forEach(clientCase => {
                const localCaseIndex = localCases.findIndex(sCase => sCase.id === clientCase.id);
                if (localCaseIndex >= 0) {
                  localCases[localCaseIndex].distribution = clientCase.distribution;
                } else {
                  localCases.unshift(clientCase);
                }
              });
              this.cases = new MatTableDataSource<Case>(localCases);*/
              this.clearSelection();
            }
          },
          err => {
            this.toastr.error(
              this.translate.instant('DISTRIBUTION.cases.list.actions.assign_batch.result.error'));
            this.serverResponse = err.error;
          },
        ),
    );
  }

  public confirmDisburseWallet() {
    const walletFilter = this.selection.selected.map(selectedCase => selectedCase.name);

    let selectedWalletsText;
    if (this.pageSelected) {
      selectedWalletsText = '- all wallets in current page';
    } else if (walletFilter.length === 1) {
      selectedWalletsText = walletFilter[0];
    } else if (walletFilter.length === 2) {
      selectedWalletsText = `${walletFilter[0]} and ${walletFilter[1]}`;
    } else {
      const lastWallet = walletFilter.pop();
      selectedWalletsText = `${walletFilter.join(', ')}, and ${lastWallet}`;
    }

    let termIds: number[] = [];
    const termsTableRows = this.batches.data
        .filter(wallet => walletFilter.includes(wallet.name))
        .map(wallet => {
            return wallet.case_distributions
                .map(caseDist => {
                    if (caseDist.case && caseDist.case.distribution_case_terms) {
                        return caseDist.case.distribution_case_terms
                            .map(term => {
                                if (
                                    term.amount_distributed == term.amount_to_distribute &&
                                    !term.hold_distribution &&
                                    term.amount == term.amount_paid &&
                                    term.distribution_batch_id == caseDist.distribution_batch_id &&
                                    !term.disbursed
                                ) {
                                    termIds.push(term.id);
                                    return `
                                        <tr>
                                            <td>${caseDist.case.ref_number}</td>
                                            <td>${wallet.name}</td>
                                            <td>${term.name}</td>
                                            <td>${term.amount_to_distribute}</td>
                                        </tr>`;
                                }
                                return '';
                            })
                            .join('');
                    }
                    return '';
                })
                .join('');
        })
        .join('');

    // If termIds is empty, display a "no terms" message; otherwise, display the terms table
    const termsContentHtml = termIds.length > 0
        ? `
            <table class="terms-table">
                <thead>
                    <tr>
                        <th>Ref Number</th>
                        <th>Wallet Name</th>
                        <th>Term Name</th>
                        <th>Amount to Distribute</th>
                    </tr>
                </thead>
                <tbody>
                    ${termsTableRows}
                </tbody>
            </table>`
        : `<p class="no-terms-message">No terms available to disburse.</p>`;

    const defaultPaymentReference = "{batch_name}{term_date}";

    Swal.fire({
        title: this.translate.instant('SHARED.warning'),
        html: `
            <style>
                .swal2-popup {
                    width: 800px;
                    max-width: 90%;
                    background-color: #fff8c4;
                }
                .terms-container {
                    max-height: 400px;
                    overflow-y: auto;
                    border: 1px solid #ddd;
                    border-radius: 8px;
                    padding: 15px;
                    background-color: #cce5ff;
                }
                .terms-header {
                    color: blue;
                    text-align: center;
                    font-size: 1.2em;
                    margin: 0 0 10px;
                }
                .terms-table {
                    width: 100%;
                    border-collapse: collapse;
                    font-size: 0.8em;
                }
                .terms-table th,
                .terms-table td {
                    padding: 8px;
                    text-align: left;
                    border: 1px solid #ddd;
                    background-color: #fff;
                }
                .terms-table th {
                    background-color: #f2f2f2;
                    font-weight: bold;
                }
                .no-terms-message {
                    color: red;
                    font-size: 0.9em;
                    text-align: center;
                }
            </style>
            <p style="font-size: 0.9em;">Are you sure that you want to disburse the wallets: ${selectedWalletsText}?</p>
            <div class="terms-container">
                <h3 class="terms-header">Terms that will be disbursed:</h3><br>
                ${termsContentHtml}
            </div>
        `,
        icon: 'warning',
        input: 'text',
        inputLabel: 'Payment Reference: *(leave it blank for default)',
        inputPlaceholder: 'Enter payment reference:',
        inputValue: defaultPaymentReference,
        showCancelButton: true,
        cancelButtonText: this.translate.instant('SHARED.no'),
        confirmButtonText: this.translate.instant('SHARED.yes'),
        confirmButtonColor: '#886ab5',
        preConfirm: (paymentReference) => {
            // Disable confirm button if no terms
            if (termIds.length === 0) {
                Swal.showValidationMessage('No terms to disburse.');
                return false;
            }
            if (!paymentReference) {
                Swal.showValidationMessage('Please enter a payment reference');
                return false;
            }
            return paymentReference;
        },
    }).then(res => {
        if (res.isConfirmed) {
            let paymentReference = res.value;
            if (paymentReference === defaultPaymentReference) {
                paymentReference = '';
            }
            this.disburseWallet(termIds, paymentReference);
        } else {
            this.clearSelection();
        }
    });

  }

  public disburseWallet(termIds: number[], paymentReference:string) { //caseListFilter: CaseListFilter
    
    //wallet based instead of term based
    //let wallet_filter = [];
    //this.selection.selected.forEach(selectedCase => wallet_filter.push(selectedCase.id));

    //if (this.pageSelected) {
    //  wallet_filter = [0];
    //}
    
    
    this.isAssigningBatch = true;
    this.subscriptions.push(
      this.distributionService.disburseWallet({ filters: termIds, payment_reference: paymentReference })
        .pipe(finalize(() => this.isAssigningBatch = false))
        .subscribe(
          result => {

            if (result.message === 'non-existant-wallet'){
              this.toastr.error(
                'Wallet: ' + result.data + ' not available'
              );
            }else{
            
              this.toastr.success(
              this.translate.instant('DISTRIBUTION.cases.list.actions.assign_batch.result.success'));
              this.fetchBatches();
              this.clearSelection();
            }
          },
          err => {
            this.toastr.error(
              this.translate.instant('DISTRIBUTION.cases.list.actions.assign_batch.result.error'));
            this.serverResponse = err.error;
          },
        ),
    );
  }

  public handleFiltersState($event: boolean): void {
    this.filtersReady = $event;
  }

  public handleSubmitFilters($event: any): void {
    // this.requestFilters = $event;
    
    this.requestFilters.statuses = $event.statuses;
    //this.requestFilters.end_date = $event.end_date;
    //this.requestFilters.start_date = $event.start_date;
    
    this.requestFilters.start_date = $event.start_date ? new Date($event.start_date).toLocaleDateString('en-CA') : null;
    this.requestFilters.end_date = $event.end_date ? new Date($event.end_date).toLocaleDateString('en-CA') : null;
    
    this.requestFilters.search = $event.search;

    this.requestFilters.wallets = $event.wallets;

    this.requestFilters.displayPending = $event.displayPending ? $event.displayPending : 0;

    this.requestFilters.displayOnHold = $event.displayOnHold ? $event.displayOnHold : 0;

    this.requestFilters.cases = $event.cases;
    
    this.resetPagination();
    this.resetSort();
    //this.fetchBatches();
  }

  modelDetails(element,opt): void {
    console.log(element);
    this.dialog.open(ModelInfoComponent, {
      width: 'fit-content',
      data: { element, opt }
    })
  }

  public handleDistributionVisibilityState($event: boolean): void {
    console.log("FILTERS, handleDistributionVisibilityState");
    //this.showDistribution = $event;
    //this.fetchPayments();
  }

  /*public handleFilters($event: PaymentListFilter): void {
    const oldPaymentMethod = this.paymentListFilter?.payment_method;
    this.paymentListFilter = $event;
    if (oldPaymentMethod !== this.paymentListFilter.payment_method) {
      this.paymentMethodChanged(this.paymentListFilter.payment_method);
    }
    if (!this.initialFetchComplete) {
      this.applyFilters();
      this.initialFetchComplete = true;
    }
  }*/

  public handleWireTypeOfPayment(isPaymentMethodWire: boolean): void {
    // this.disableApplyBtn = isPaymentMethodWire ? true : false;
  }

  public applyFilters() {
    this.resetPagination();
    this.resetSort();
    this.fetchBatches();
  }

}
