import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreditorBookValueComponent } from './creditor-book-value/creditor-book-value.component';
import { FinancialReportListComponent } from './financial-report-list/financial-report-list.component';
import { HeadlineReportsComponent } from './headline-reports/headline-reports.component';
import { MissingDocumentsComponent } from './missing-documents/missing-documents.component';
import { CreditorImportComponent } from './creditor-import/creditor-import.component';
import { DataInsightsReportListComponent } from './data-insights-report-list/data-insights-report-list.component';

const routes: Routes = [
  {
    path:     'reports',
    children: [
      {path: '', redirectTo: 'creditor-book-value', pathMatch: 'full'},
      {path: 'creditor-book-value', component: CreditorBookValueComponent},
      {path: 'headline-reports', component: HeadlineReportsComponent},
      {path: 'financial-reports', component: FinancialReportListComponent},
      {path: 'missing-documents', component: MissingDocumentsComponent},
      {path: 'creditor-import', component: CreditorImportComponent},
      {path: 'data-insights', component: DataInsightsReportListComponent},
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class ReportsRoutingModule {
}
