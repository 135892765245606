import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../../../_base-shared/contracts/laravel-response.interface';
import { CaseDistribution } from '../../../../../../../_base-shared/models/Distribution/CaseDistribution';
import {
  DistributionBatch,
  DistributionBatchTotalAmounts,
} from '../../../../../../../_base-shared/models/Distribution/DistributionBatch';
import { PaymentStatus } from '../../../../../../../_base-shared/models/Status/PaymentStatus';
import { StatusCategory } from '../../../../../../../_base-shared/models/Status/StatusCategory';
import { User } from '../../../../../../../_base-shared/models/User/User';
import { environment } from '../../../../../environments/environment';
import { MainGlobalEventService } from '../../../../_shared/services/main-global-event.service';
import { DistributionService } from '../../../../distribution/distribution.service';
import { StatusService } from '../../../status/status.service';
import { DistributionAmountCalculatorService } from '../../distribution-amount-calculator.service';
import { DistributionBatchService } from '../../distribution-batch.service';
import { Case } from '../../../../../../../_base-shared/models/Case/Case';
import { CaseService } from '../../../case/case.service';

@Component({
  selector:    'app-distribution-batch-detail',
  templateUrl: './distribution-batch-detail.component.html',
  styleUrls:   ['./distribution-batch-detail-component.scss']
  /*styles:      [
    `
      mat-table {
        display: table;
        width: 100%;
      }

      mat-progress-bar {
        height: 16px;
      }
    `,
  ],*/
})
export class DistributionBatchDetailComponent extends DistributionAmountCalculatorService implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  public authUser: User;
  public componentType: 'distribution' | 'admin';
  public filterList: any;
  public isLoading                                               = 0;
  public serverResponse: LaravelResourceResponse;
  public paymentStatuses: Array<PaymentStatus>                   = [];
  public distributionBatch: DistributionBatch;
  public batchTotalAmounts: DistributionBatchTotalAmounts;
  public caseDistributions: MatTableDataSource<CaseDistribution> = new MatTableDataSource<CaseDistribution>([]);
  public displayedColumns: Array<string>;
  public selection                                               = new SelectionModel(true, []);
  public defaultPaginatorConfig: { pageIndex: number, pageSize: number, length: number };
  public paginatorConfig: { pageIndex: number, pageSize: number, length: number };
  public defaultSort: { direction: 'asc' | 'desc', active: string };
  public totalResults: number;
  public totalPages: number;
  public pageSelected: boolean;
  public storageUrl                                              = environment.STORAGE_URL + '/';
  public statusCategories: Array<StatusCategory>                 = [];
  public cases: Array<Case> = [];
  
  // Filters
  public paymentStatusFilter: UntypedFormControl = new UntypedFormControl(null);
  public caseStatusFilter: UntypedFormControl    = new UntypedFormControl(null);
  public searchFilter: UntypedFormControl           = new UntypedFormControl(null);
  public casesFilter: UntypedFormControl             = new UntypedFormControl(null);

  private subscriptions: Array<Subscription> = [];

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService,
    private toastr: ToastrService,
    private globalEventService: MainGlobalEventService,
    private distributionService: DistributionService,
    private distributionBatchService: DistributionBatchService,
    private statusService: StatusService,
    private caseService: CaseService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.defaultPaginatorConfig = {pageIndex: 0, pageSize: 20, length: 1};
    this.defaultSort            = {direction: 'desc', active: 'created_at'};
    this.paginatorConfig        = this.defaultPaginatorConfig;
    this.filterList             = {...this.paginatorConfig};
    this.resetSort();
    this.fetchPaymentStatuses();
    this.subscribeToFilterChanges();
    this.fetchStatuses();
    this.fetchCases();

    this.globalEventService.authUser$.subscribe(user => {
      this.authUser         = user;
      this.componentType    = this.authUser.role.slug === 'distribution-provider' ? 'distribution' : 'admin';
      this.displayedColumns = this.getTableColumns(this.componentType);
      this.route.paramMap.subscribe(params => {
        const batchId = +params.get('id');
        this.fetchDistributionBatch(batchId);
      });
    });
  }

  ngAfterViewInit(): void {
    this.caseDistributions.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  private fetchCases() {
    this.caseService.index({has_distribution: 1}).pipe()
      .subscribe(next => {
        this.cases = next.data;
      });
  }

  public paginatorChange($event: PageEvent): void {
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize  = $event.pageSize;
    this.paginatorConfig.length    = $event.length;

    this.filterList.page     = this.paginatorConfig.pageIndex + 1;
    this.filterList.per_page = this.paginatorConfig.pageSize;
    this.fetchBatchCaseDistributions(this.distributionBatch);
  }

  public sortData(sort): void {
    this.filterList.sort_by = sort.active ? sort.active : this.defaultSort.active;
    this.filterList.order   = sort.direction ? sort.direction : this.defaultSort.direction;

    this.fetchBatchCaseDistributions(this.distributionBatch);
  }

  private subscribeToFilterChanges(): void {
    this.subscriptions.push(
      this.paymentStatusFilter.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
      ).subscribe(paymentStatusIds => {
        this.paymentStatusChanged(paymentStatusIds);
      }),
    );
    this.subscriptions.push(
      this.caseStatusFilter.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
      ).subscribe(caseStatusIds => {
        this.caseStatusChanged(caseStatusIds);
      }),
    );
    this.subscriptions.push(
      this.searchFilter.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
      ).subscribe((caseStatusIds) => {
        this.caseSearchChanged(caseStatusIds);
      }),
    );
    this.subscriptions.push(
      this.casesFilter.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
      ).subscribe((casesIds: Array<number>) => {
        this.selectedFormCasesChanged(casesIds);
      }),
    );
  }

  private resetPagination(): void {
    this.paginatorConfig     = this.defaultPaginatorConfig;
    this.filterList.per_page = this.paginatorConfig.pageSize;
    this.filterList.page     = this.paginatorConfig.pageIndex;
  }

  private resetSort(): void {
    this.filterList.sort_by = this.defaultSort.active;
    this.filterList.order   = this.defaultSort.direction;
  }

  private clearSelection() {
    this.selection.clear();
    this.pageSelected = false;  //  Hide global select
    // this.patchSelectAllFilter(0);
    //delete this.filterList.case_ids;
  }

  private fetchPaymentStatuses() {
    this.isLoading++;
    this.distributionService.indexPaymentStatuses().pipe(finalize(() => this.isLoading--)).subscribe(result => {
      this.paymentStatuses = result.data;
    });
  }

  private fetchDistributionBatch(batchId: number) {
    this.isLoading++;
    this.distributionService.showBatch(batchId).pipe(finalize(() => this.isLoading--)).subscribe(
      result => {
        this.distributionBatch = result.data;
        this.fetchBatchTotalAmounts(this.distributionBatch);
        this.fetchBatchCaseDistributions(this.distributionBatch);
      },
      () => this.toastr.error(this.translate.instant('SHARED.went-wrong')),
    );
  }

  private fetchBatchTotalAmounts(distributionBatch: DistributionBatch) {
    this.isLoading++;
    this.distributionBatchService.getTotalAmounts(distributionBatch.id).pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
        this.batchTotalAmounts                          = result.data;
        this.batchTotalAmounts.total_distributed_amount = +(this.batchTotalAmounts.total_distributed_amount) +
          +(this.batchTotalAmounts.total_fees_retained);
      });
  }

  public addMonths(date: Date, months: number): Date {
    const result = new Date(date);
    result.setMonth(result.getMonth() + months);
    return result;
  }

  private fetchBatchCaseDistributions(distributionBatch: DistributionBatch) {
    this.clearSelection();

    const withRelations = [
        'batch.distribution_provider',
        'case.status',
        'case.latest_case_status_log',
        'case.payment_status',
        'case.latest_contract',
        'case.debt_payment_plan',
        'case.unsecured_creditors',
        'case.creditors',
        'case.expense', 
        'case.terms',
        'case.distribution_case_terms',
    ];

    const phaseTwoStatusIds = [414, 415];

    this.caseDistributions = new MatTableDataSource<CaseDistribution>([]);
    this.isLoading++;

    this.distributionService.indexBatchDistributions(distributionBatch.id, this.filterList, withRelations)
        .pipe(finalize(() => this.isLoading--))
        .subscribe(
            result => this.handleDistributionResult(result),
            () => this.toastr.error(this.translate.instant('SHARED.went-wrong')),
        );
  }

  private handleDistributionResult(result) {

      const resultData = result.data.map(this.processDistributionData.bind(this));

      this.caseDistributions = new MatTableDataSource<CaseDistribution>(resultData);
      this.caseDistributions.sort = this.sort;
      this.paginatorConfig.length = result.meta.total;
      this.totalResults = result.meta.total;
      this.totalPages = result.meta.last_page;
  }

  private processDistributionData(cDistribution) {
      const totalDistributed = +(cDistribution.distributed_amount) + +(cDistribution.fees_retained);
      const caseDetails = this.extractCaseDetails(cDistribution);
      const { terms, c_debt_amount, monthly_installment, proposal_location } = caseDetails;
      const case_offer_to_creditors = cDistribution.case.expense.offer_to_creditors_total;
      const case_total_installment = cDistribution.case.expense.offer_to_creditors_total + cDistribution.case.expense.distribution_fee;
      
      const paymentDetails = this.calculatePaymentDetails(cDistribution, case_offer_to_creditors);
      const distributionDetails = this.calculateDistributionDetails(cDistribution, case_offer_to_creditors);

      const c_collected_amt = monthly_installment * paymentDetails.number_of_payments;
      const c_distributed = monthly_installment * distributionDetails.number_of_ditributions;
      const payment_end_date = cDistribution.case.terms[cDistribution.case.terms.length - 1].term_date;

      if (cDistribution.batch.name === 'UNIFYE') {
          return this.processUnifyeCase(cDistribution, paymentDetails, distributionDetails);
      }

      return {
          ...cDistribution, 
          distributed_amount: totalDistributed,
          id_card: cDistribution.case.client.id_card,
          ref_number: cDistribution.case.ref_number,
          term: terms,
          installment_amt: monthly_installment,
          collected_amt: c_collected_amt,
          current_balance: 0,
          distributed_amt: c_distributed,
          pending_dist: 0, // collected amount - distributed amount
          orig_balance: c_debt_amount,
          c_debt_plan_total: cDistribution.case?.sum_total_debt_amount || 0,
          c_payment_end_date: payment_end_date,
          c_proposal_location: proposal_location,
          c_on_hold: distributionDetails.on_hold_amt,
      };
  }

  private extractCaseDetails(cDistribution) {
      const creditorName = cDistribution.batch.name;
      const case_creditor = creditorName === 'UNIFYE'
          ? cDistribution.case.creditors.find(item => item.name === creditorName && item.pivot.id === cDistribution.case_creditor_id)
          : cDistribution.case.unsecured_creditors.find(item => item.name === creditorName && item.pivot.id === cDistribution.case_creditor_id);

      if (!case_creditor) return this.extractCaseDetailsFromDistribution(cDistribution);
      
      const phaseTwoCount = cDistribution.case.terms.filter(term => term.type === 'phase_two').length;
      return {
          terms: phaseTwoCount,
          c_debt_amount: case_creditor.pivot.debt_amount,
          monthly_installment: case_creditor.pivot.offer_to_creditor,
          proposal_location: case_creditor.pivot.proposal_location
      };
  }

  private extractCaseDetailsFromDistribution(cDistribution) {
    let terms = 0;
    let monthly_installment = 0;

    cDistribution.case.distribution_case_terms.forEach(dterm => {
        if (dterm.amount == dterm.amount_paid && dterm.amount_paid>0) {
            terms += 1;
        }
        monthly_installment = dterm.amount_to_distribute;
    });

    return { terms: terms, c_debt_amount: 0, monthly_installment: monthly_installment, proposal_location: '' };
}

  private calculatePaymentDetails(cDistribution, case_offer_to_creditors) {
      let number_of_payments = 0;

      cDistribution.case.distribution_case_terms.forEach(term => {
          if (this.isValidPhaseTwoTerm(term, case_offer_to_creditors, cDistribution.distribution_batch_id)) {
              number_of_payments += 1;
          }
      });

      return { number_of_payments };
  }

  private calculateDistributionDetails(cDistribution, case_offer_to_creditors) {
      let number_of_ditributions = 0;
      let on_hold_amt = 0;

      cDistribution.case.distribution_case_terms.forEach(dterm => {
          if (this.isValidDistributionTerm(dterm, case_offer_to_creditors, cDistribution)) {
              number_of_ditributions += 1;
          }
          if (this.isValidOnHoldTerm(dterm, case_offer_to_creditors, cDistribution)) {
            on_hold_amt += dterm.amount_to_distribute;
          }
      });

      return { number_of_ditributions, on_hold_amt };
  }

  private isValidPhaseTwoTerm(term, case_offer_to_creditors, distribution_batch_id) {
      return term.type === "phase_two" && term.amount_paid > 0 && 
            case_offer_to_creditors <= term.amount_paid && term.distribution_batch_id === distribution_batch_id
            && term.amount_paid === term.amount;
  }

  private isValidOnHoldTerm(dterm, case_offer_to_creditors, cDistribution) {
    return dterm.type === "phase_two" && dterm.amount_paid > 0 && dterm.amount_paid === dterm.amount &&
            dterm.distribution_batch_id === cDistribution.distribution_batch_id &&
            dterm.case_id === cDistribution.case.id && dterm.hold_distribution === 1;
  }

  private isValidDistributionTerm(dterm, case_offer_to_creditors, cDistribution) {
      return dterm.type === "phase_two" && dterm.amount_paid > 0 && dterm.amount_paid === dterm.amount &&
            dterm.distribution_batch_id === cDistribution.distribution_batch_id &&
            dterm.case_id === cDistribution.case.id && dterm.amount_distributed > 0 &&
            dterm.amount_distributed === dterm.amount_to_distribute && 
            case_offer_to_creditors <= dterm.amount_paid && dterm.hold_distribution === 0;
  }

  private processUnifyeCase(cDistribution, paymentDetails, distributionDetails) {
      const terms = cDistribution.case.debt_payment_plan.phase_two_duration; //cDistribution.case.debt_payment_plan.phase_one_duration +
      const c_debt_amount = cDistribution.case.expense.distribution_fee * cDistribution.case.debt_payment_plan.phase_two_duration; 
                            //cDistribution.case?.sum_total_debt_amount - cDistribution.case?.debt_payment_plan.phase_one_value;
      const monthly_installment = cDistribution.case.expense.distribution_fee;
      const c_collected_amt = monthly_installment * paymentDetails.number_of_payments;
      const c_distributed = monthly_installment * distributionDetails.number_of_ditributions;
      const payment_end_date = cDistribution.case.terms[cDistribution.case.terms.length - 1].term_date;

      return {
          ...cDistribution, 
          distributed_amount: cDistribution.distributed_amount + cDistribution.fees_retained,
          id_card: cDistribution.case.client.id_card,
          ref_number: cDistribution.case.ref_number,
          term: terms,
          installment_amt: monthly_installment,
          collected_amt: c_collected_amt,
          current_balance: 0,
          distributed_amt: c_distributed,
          pending_dist: 0, // collected amount - distributed amount
          orig_balance: c_debt_amount,
          c_debt_plan_total: cDistribution.case?.sum_total_debt_amount || 0,
          c_payment_end_date: payment_end_date,
          c_proposal_location: '',
          c_on_hold: distributionDetails.on_hold_amt,
      };
  }

  //end-refactor

  private getTableColumns(type: 'distribution' | 'admin'): Array<string> {
    const columns = [
      //'batch_index',
      'ref_number',
      'id_card',
      //'case_ref_number',
      'orig_balance',
      'term',
      'case_payment_status',
      'monthly_fee_amount',
      'plan_start_date',
      'plan_end_date',  
      //'case_status', 
      //'case_status_updated_at',
      //'batch_contract_amount',
      'debt_plan_total',
      'collected_amt',
      'current_balance',
      'distributed_amt',
      'pending_dist',
      'on-account',
      // 'funded_amount',
      // 'cash_hurdle_amount',
      // 'distributed_amount',
      // 'fees_retained',
      // 'progress_bar'
    ];

    if (type === 'admin' || type === 'distribution') {
      columns.push('contract_download');
    }

    return columns;
  }

  public downloadContracts(distributionBatch: DistributionBatch): void {
    console.log('TODO: download contracts');
  }

  public changeComponentType(type: 'distribution' | 'admin'): void {
    this.componentType    = type;
    this.displayedColumns = this.getTableColumns(type);
  }

  private paymentStatusChanged(paymentStatusIds: Array<number>): void {
    this.filterList.payment_statuses = paymentStatusIds ? paymentStatusIds : [];
    this.applyFilters();
  }

  private caseStatusChanged(caseStatusIds: Array<number>): void {
    this.filterList.case_statuses = caseStatusIds ? caseStatusIds : [];
    this.applyFilters();
  }

  private caseSearchChanged(search: String): void {
    this.filterList.search = search ? search : '';
    this.applyFilters();
  }

  public selectedFormCasesChanged(casesIds: Array<number>): void {
    //console.log(casesIds);
    this.filterList.cases = casesIds;
    this.applyFilters();
  }

  private applyFilters(): void {
    this.resetPagination();
    this.resetSort();
    this.fetchBatchCaseDistributions(this.distributionBatch);
  }

  private fetchStatuses(): void {
    this.isLoading++;
    this.subscriptions.push(
      this.statusService.indexCategoriesWithStatuses().pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.statusCategories = result.data),
    );
  }
}
