<!--<h1 class="pb-5">{{ 'DISTRIBUTION.report.heading' | translate }}</h1>
<div class="row">
  <div class="col-md-12 mb-4">
  <h2 class="text-center">{{ "DISTRIBUTION.report.subheadings.performance_reports" | translate }}</h2>
  </div>
  <div *ngFor="let report of performanceReports" class="col-4">
    <div class="card mb-4 h-100">
      <div class="card-header text-center">
        <h2 class="cart-title text-primary mb-0">{{ report.title | translate }}</h2>
      </div>
      <div class="card-body">
        <p *ngIf="report.description">
          {{ report.description | translate }}
        </p>
      </div>
      <div class="card-footer">
        <div class="text-right">
          <button type="button" mat-raised-button color="primary"
                  [disabled]="!report.enabled" (click)="openDistributionModal($event, report.reportType, report.reportGroup)">
            {{ 'DISTRIBUTION.report.submit_label' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12 mt-5 mb-4">
    <h2 class="text-center mb-0">{{ "DISTRIBUTION.report.subheadings.funding_reports" | translate }}</h2>
  </div>
  <div *ngFor="let report of fundingReports" class="col-4">
    <div class="card mb-4 h-100">
      <div class="card-header text-center">
        <h2 class="cart-title text-primary mb-0">{{ report.title | translate }}</h2>
      </div>
      <div class="card-body">
        <p *ngIf="report.description">
          {{ report.description | translate }}
        </p>
      </div>
      <div class="card-footer">
        <div class="text-right">
          <button type="button" mat-raised-button color="primary"
                  [disabled]="!report.enabled" (click)="openDistributionModal($event, report.reportType, report.reportGroup)">
            {{ 'DISTRIBUTION.report.submit_label' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>-->

<div class="container">
  <div class="row">
    <div class="col-12">
      <h1 class="pb-5">{{ 'DISTRIBUTION.report.heading' | translate }}</h1>
      
      <div class="col-md-12 mb-4">
        <h2 class="text-center">{{ "DISTRIBUTION.report.subheadings.business_reports" | translate }}</h2>
      </div>
      <table mat-table matSort [dataSource]="businessReports" width="100%">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Report Name</th>
          <td mat-cell *matCellDef="let element">{{ element.title | translate }}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="actions-column">Actions</th>
          <td mat-cell *matCellDef="let element" class="actions-column">
            <button type="button" mat-raised-button color="primary" [disabled]="!element.enabled" (click)="element.action($event)"> <!-- (click)="openDistributionModal($event, element.reportType, element.reportGroup)" -->
              {{ 'DISTRIBUTION.report.submit_label' | translate }}
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
      </table>

      
      <!--<div class="col-md-12 mb-4">
        <h2 class="text-center">{{ "DISTRIBUTION.report.subheadings.performance_reports" | translate }}</h2>
      </div>
      <table mat-table matSort [dataSource]="performanceReports" width="100%">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Report Name</th>
          <td mat-cell *matCellDef="let element">{{ element.title | translate }}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="actions-column">Actions</th>
          <td mat-cell *matCellDef="let element" class="actions-column">
            <button type="button" mat-raised-button color="primary" [disabled]="!element.enabled" (click)="openDistributionModal($event, element.reportType, element.reportGroup)">
              {{ 'DISTRIBUTION.report.submit_label' | translate }}
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
      </table>

      <div class="col-md-12 mb-4" style="margin-top: 30px;">
        <h2 class="text-center">{{ "DISTRIBUTION.report.subheadings.funding_reports" | translate }}</h2>
      </div>
      <table mat-table matSort [dataSource]="fundingReports" width="100%">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Report Name</th>
          <td mat-cell *matCellDef="let element">{{ element.title | translate }}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="actions-column">Actions</th>
          <td mat-cell *matCellDef="let element" class="actions-column">
            <button type="button" mat-raised-button color="primary" [disabled]="!element.enabled" (click)="openDistributionModal($event, element.reportType, element.reportGroup)">
              {{ 'DISTRIBUTION.report.submit_label' | translate }}
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
      </table>-->


    </div>
  </div>
</div>

<style>
  .actions-column {
    width: 200px;
    text-align: left;
  }
</style>