import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { environment } from '../../../../../../environments/environment';
import { CaseDocumentService } from '../../../case-document.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DocumentTypeService } from '../../../../../../../../_base-shared/services/document-type.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ImgEditModalComponent } from '../../../../../_shared/components/img-edit-modal/img-edit-modal.component';
import { saveAs } from 'file-saver';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector   : 'app-all-legal-files',
  templateUrl: './all-legal-files.component.html',
  styleUrls  : ['./all-legal-files.component.scss']
})
export class AllLegalFilesComponent implements OnInit {
  @Input() case;
  @Input() type;
  @Input() filesByLegal;
  @Input() filesByPublicDebt;
  @Input() partnerFilesByLegal;
  @Input() partnerFilesByPublicDebt;
  @Input() updateFiles;
  @Input() allCreditors;
  @Input() disabled: boolean = false;
  @Output() selectedFileEvent = new EventEmitter<object>();

  public allDocTypes;
  public newType                = null;
  public storageUrl             = environment.STORAGE_URL + '/';
  public legalFiles          = [];
  public legalFilesPartner   = [];
  public legalFilesFiltered  = [];
  public isAccordionTabOpen     = {};
  // public newName                = '';
  public toEdit;
  public docInfo                = this.fb.group({
    name: '',
    type: '',
    uploaded_by: '',
  });
  public mergingFiles = false;
  public mergedDoc = this.fb.group({
    fileName: '',
    type: ''
  });

  constructor(
    private fb: UntypedFormBuilder,
    private documentService: CaseDocumentService,
    private documentTypeService: DocumentTypeService,
    public toastr: ToastrService,
    public dialog: MatDialog,
    public translate: TranslateService) {
  }

  ngOnInit(): void {
    this.documentTypeService.getLegalDocumentTypes()
      .subscribe(res => {
        this.allDocTypes = res;

        //  Add custom file type to addDocTypes
        for (const prop in this.filesByLegal) {
          if ( this.filesByLegal.hasOwnProperty(prop)) {
            const exists = this.allDocTypes.find(type => type.name === prop);
            if (!exists) {
              this.allDocTypes.push({id: '', name: prop, type: 'custom', label: prop});
            }
          }
        }
      });

    this.updateFiles.subscribe(next => {
      if (next.data) {
        //  Add custom file type to addDocTypes when files are updated
        const obj = this.type === 'client' ? next.data.legal_documents_client : next.data.legal_documents_partner;
        for (const prop in obj) {
          if (obj.hasOwnProperty(prop)) {
            const exists = this.allDocTypes.find(type => type.name === obj.document_type);
            if (!exists) {
              this.allDocTypes.push({id: '', name: prop, type: 'custom', label: prop});
            }
          }
        }
        this.setUploadedDocuments(next.data);
      }
    });
    this.setUploadedDocuments();

    //  Generate object to use to keep accordion open after user accept or decline file
    for (const property in this.legalFilesFiltered) {
      if (this.legalFilesFiltered.hasOwnProperty(property)) {
        this.isAccordionTabOpen[property] = false;
      }
    }

    if (this.disabled) {
      this.mergedDoc.disable();
    } 
  }

  setUploadedDocuments(data = null) {
    if (!data) {
      this.legalFilesFiltered = {...this.filesByLegal};
      if (Object.keys(this.legalFilesFiltered).length === 0) {
        this.legalFilesFiltered = [];
      }
    }
    else {
      //  Get all files from client and partner
      this.legalFiles          = data?.legal_documents_client;
      this.legalFilesPartner   = data?.legal_documents_partner;

      //  Merge creditor files and public debt files
      if (this.type === 'client') {
        this.legalFilesFiltered = {...this.legalFiles};
        if (Object.keys(this.legalFilesFiltered).length === 0) {
          this.legalFilesFiltered = [];
        }
      }
      else {
        this.legalFilesFiltered = {...this.legalFilesPartner};
        if (Object.keys(this.legalFilesFiltered).length === 0) {
          this.legalFilesFiltered = [];
        }
      }
    }
  }

  openAccordionTab(name, value) {
    //  Update object to keep accordion open
    this.isAccordionTabOpen[name] = value;
  }

  getDocumentStatus(documents) {
    return 'accepted-document';
  }

  editDocument(document) {
    this.docInfo.setValue({
      name: document.name,
      type: document.document_type,
      uploaded_by: document.uploaded_by
    });

    // this.newName = document.name;
    this.toEdit  = document.id;
  }

  public sendDocument(document): void {
    const data = {
      client_role: document.uploaded_by,
      type: 'legal_document'
    };
    this.documentService.sendDocument(data, document.case_id, document.id)
      .subscribe(
        res => {
          this.toastr.success(this.translate.instant('SHARED.success'));
        },
        err => {
          this.toastr.error(this.translate.instant('SHARED.error'));
        },
      );
  }

  cancelEdit() {
    this.docInfo.setValue({
      name: '',
      type: '',
      uploaded_by: '',
    });
    this.toEdit  = null;
    this.newType = null;
  }

  deleteFile($event, id) {
    $event.preventDefault();

    this.documentService.removeUploadedFileLegal(this.case.id, id)
      .subscribe(
        res => {
          this.setUploadedDocuments(res.data);
          this.toastr.success(this.translate.instant('DOCUMENTS.file-deleted'));
        },
        err => {
          this.toastr.error(this.translate.instant('DOCUMENTS.file-deleted-error'));
        }
      );
  }

  saveDoc(document, oldType) {
    if (this.docInfo.value.name === '') {
      return;
    }
    if (this.docInfo.value.type === oldType &&
        this.docInfo.value.name === document.name &&
        this.docInfo.value.uploaded_by === document.uploaded_by
    ) {
      this.toEdit = null;
      this.docInfo.setValue({
        name: '',
        type: '',
        uploaded_by: '',
      });
      return;
    }
    const newTypeDoc = this.allDocTypes.find(type => type.name === this.docInfo.value.type);

    const data = {
      document_type: newTypeDoc?.name || null,
      name: this.docInfo.value.name,
      uploaded_by: this.docInfo.value.uploaded_by
    };
    this.documentService.changeStatusLegal(this.case.id, document.id, data)
      .subscribe(
        res => {
          this.setUploadedDocuments(res.data); //  Update document list
          this.toastr.success(this.translate.instant('DOCUMENTS.file-status-changed-success'));
        },
        err => {
          this.toastr.error(this.translate.instant('DOCUMENTS.file-details-changed-error'));
        }
      );
    this.toEdit  = null;
    this.docInfo.setValue({
      name: '',
      type: '',
      uploaded_by: '',
    });
  }

  public openEditModal(event, document) {
    event.preventDefault();
    //  Open dialog modal
    const dialogRef  = this.dialog.open(ImgEditModalComponent, {
      width: '50%',
      data:  {
        document,
        case: this.case,
        type: 'legal'
      },
    });
    //  On close fire handle response
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.setUploadedDocuments(result.data); //  Update document list

        this.toEdit  = null;
        this.docInfo.setValue({
          name: '',
          type: '',
          uploaded_by: '',
        });
      }
    });
  }

  downloadFile($event: MouseEvent, document: any) {
    saveAs(this.storageUrl + document.location, document.name + '.' + document.extension);
  }

  downloadPdfFile($event: MouseEvent, document: any) {
    saveAs(this.storageUrl + document.pdf_location, document.name + '.pdf');
  }

  drop(event: CdkDragDrop<string[]>, instance) {
    moveItemInArray(instance.value, event.previousIndex, event.currentIndex);
  }

  saveMergedFiles($event: MouseEvent, key: string) {
    this.mergingFiles = true;
    const documentsIds = this.filesByLegal[key].map((document) => {
      return document.id;
    });

    const data = {
      document_ids: documentsIds,
      name: this.mergedDoc.value.fileName,
      document_type: key,
      uploaded_by: this.type,
    };

    this.documentService.mergeLegalDocuments(this.case.id, data).subscribe(res => {
      this.mergingFiles = false;
      this.setUploadedDocuments(res.data);
      this.toastr.success(this.translate.instant('DOCUMENTS.merged-toastr-success'));
    }, error => {
      this.mergingFiles = false;
      this.toastr.error(this.translate.instant('DOCUMENTS.merged-toastr-error'));
    });
  }

  selectFile(e: Event, url: string, type: string) {
    e.preventDefault();
    this.selectedFileEvent.emit({
      url,
      type: type?.includes('image') ? 'image' : 'object'
    });
  }


}
