<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h4>{{ 'DASHBOARD.negotiator-leaderboard' | translate}}</h4>
                </div>
                <div class="card-body">
                    <form *ngIf="filterDataForm" [formGroup]="filterDataForm" #ngForm>
                        <app-input type="select" formControlName="month_select" [extraLabel]="true"
                                    [label]="'DASHBOARD.select-other-period' | translate" [selectOptions]="months" [showClear]="false"
                                    [selectLabel]="'label'" [selectValue]="'value'" [fullWidth]="true">
                        </app-input>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <table mat-table matSort [dataSource]="dataSource" width="100%">
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
                        <!-- Agent Name -->
                        <ng-container matColumnDef="agent_name">
                            <th mat-header-cell *matHeaderCellDef>{{ 'DASHBOARD.agent-name' | translate }}</th>
                            <td mat-cell *matCellDef="let element" [ngClass]="checkIfNullNegotiated(element)">
                                {{ element.name }}
                            </td>
                            <td mat-footer-cell *matFooterCellDef><b>Total</b></td>
                        </ng-container>
                        <!-- Requested -->
                        <ng-container matColumnDef="solicitud">
                            <th mat-header-cell *matHeaderCellDef>Solicitud Prestamo</th>
                            <td mat-cell *matCellDef="let element" [ngClass]="checkIfNullNegotiated(element)">
                                {{ element.case_count_solicitud }}
                            </td>
                            <td mat-footer-cell *matFooterCellDef><b>{{ totalSolicitud }}</b></td>
                        </ng-container>
                        <!-- Refinanced -->
                        <ng-container matColumnDef="refinanced">
                            <th mat-header-cell *matHeaderCellDef>{{'CASE_CREDITOR.model.response_received.options.refinanced' | translate}}</th>
                            <td mat-cell *matCellDef="let element" [ngClass]="checkIfNullNegotiated(element)">
                                {{ element.case_count_refinanced ?? 0 }}
                            </td>
                            <td mat-footer-cell *matFooterCellDef><b>{{ totalRefinanced }}</b></td>
                        </ng-container>
                        <!-- Accepted -->
                        <ng-container matColumnDef="aceptado">
                            <th mat-header-cell *matHeaderCellDef>{{'CASE_CREDITOR.model.response_received.options.proposal_accepted' | translate}}</th>
                            <td mat-cell *matCellDef="let element" [ngClass]="checkIfNullNegotiated(element)">
                                {{ element.case_count_aceptada ?? 0 }}
                            </td>
                            <td mat-footer-cell *matFooterCellDef><b>{{ totalAceptado }}</b></td>
                        </ng-container>
                        <!-- Percentage -->
                        <ng-container matColumnDef="porcentaje">
                          <th mat-header-cell *matHeaderCellDef>{{ 'DISTRIBUTION.batch.detail.cases_table.progress' | translate}}</th>
                          <td mat-cell *matCellDef="let element" [ngClass]="checkIfNullNegotiated(element)">
                            <div *ngIf="calculatePercentage(element)">
                            <small class="font-weight-lighter"> {{ (element.case_count_aceptada + element.case_count_refinanced) }} of {{ negotiatorGoal }}</small>
                            <span class="font-weight-bold"> - {{ calculatePercentage(element).toFixed(2) }}%</span>
                            <div class="progress" style="max-width: 120px">
                              <div class="progress-bar progress-bar-striped" role="progressbar"
                                   [style]="{width: calculatePercentage(element)  + '%'}"
                                   [attr.aria-valuenow]="calculatePercentage(element)"
                                   [attr.aria-valuemin]="0" [attr.aria-valuemax]="100">{{ calculatePercentage(element).toFixed(2) }}%
                              </div>
                            </div></div>
                          </td>
                          <td mat-footer-cell *matFooterCellDef><b></b></td>
                        </ng-container>
                        <!-- Bonus -->
                        <ng-container matColumnDef="bonus">
                            <th mat-header-cell *matHeaderCellDef>Bonus</th>
                            <td mat-cell *matCellDef="let element" [ngClass]="checkIfNullNegotiated(element)">
                                {{ element.bonus ?? '' | currency }}
                            </td>
                            <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>