import { NgModule } from '@angular/core';
import { SharedModule } from '../../_shared/shared.module';
import { CaseModule } from '../case/case.module';
import { BatchSelectorComponent } from './batch-selector/batch-selector.component';
import { DistributionBatchListComponent } from './distribution-batch-list/distribution-batch-list.component';
import { DistributionBatchModelComponent } from './distribution-batch-model/distribution-batch-model.component';
import {
  DistributionBatchEditorComponent,
} from './distribution-batch/distribution-batch-editor/distribution-batch-editor.component';
import { DistributionListComponent } from './distribution-list/distribution-list.component';
import {
  DistributionProviderEditorComponent,
} from './distribution-provider/distribution-provider-editor/distribution-provider-editor.component';
import {
  DistributionProviderListComponent,
} from './distribution-provider/distribution-provider-list/distribution-provider-list.component';
import { DistributionReportListComponent } from './distribution-report-list/distribution-report-list.component';
import { DistributionReportModalComponent } from './distribution-report-modal/distribution-report-modal.component';
import { DistributionRoutingModule } from './distribution-routing.module';
import { DistributionComponent } from './distribution.component';
import { DistributionCaseDetailComponent } from './distribution-case-detail/distribution-case-detail.component';
import { DistributionCasePaymentComponent } from './distribution-case-detail/distribution-case-payment/distribution-case-payment.component';
import { ModelInfoComponent } from './distribution-batch-model/distribution-model-dialog/distribution-model-info.component';
import { DistributionModelFiltersComponent } from './distribution-batch-model/distribution-model-filters/distribution-model-filters.component';
import { DistributionBatchListFiltersComponent } from './distribution-batch-list/distribution-batch-list-filters/distribution-batch-list-filters.component';
import { DistributionReconciliationComponent } from './distribution-reconciliation/distribution-reconciliation.component';
import { DistributionReconciliationFiltersComponent } from './distribution-reconciliation/distribution-reconciliation-filters/distribution-reconciliation-filters.component';
import { ReconciliationReportModalComponent } from './distribution-report-list/reconciliation-report-modal/reconciliation-report-modal.component';
import { RemittanceReportModalComponent } from './distribution-report-list/remittance-report-modal/remittance-report-modal.component';


@NgModule({
  imports: [
    SharedModule,
    DistributionRoutingModule,
    CaseModule,
  ],
  declarations: [
    DistributionComponent,
    DistributionListComponent,
    DistributionBatchListComponent,
    BatchSelectorComponent,
    DistributionBatchEditorComponent,
    DistributionReportListComponent,
    DistributionReportModalComponent,
    DistributionProviderEditorComponent,
    DistributionProviderListComponent,
    DistributionCaseDetailComponent,
    DistributionCasePaymentComponent,
    DistributionBatchModelComponent,
    ModelInfoComponent,
    DistributionReconciliationComponent,
    DistributionModelFiltersComponent,
    DistributionBatchListFiltersComponent,
    DistributionReconciliationFiltersComponent,
    ReconciliationReportModalComponent,
    RemittanceReportModalComponent
  ],
})
export class DistributionModule {
}
